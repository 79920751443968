// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Mem$Showside from "../utils/Mem.bs.js";

function get(category) {
  var match = category.categoryType;
  if (typeof match === "number" || match.TAG !== /* Standard */1) {
    return ;
  } else {
    return match._0.taggedMedia;
  }
}

function getList(category) {
  var match = get(category);
  if (match !== undefined) {
    return match.source._0;
  }
  
}

function getDisplayType(category) {
  var match = get(category);
  if (match !== undefined) {
    return match.displayType;
  }
  
}

function getCardSize(category) {
  var match = get(category);
  if (match !== undefined) {
    return [
            match.cardWidth,
            match.cardHeight
          ];
  } else {
    return [
            undefined,
            undefined
          ];
  }
}

function getStartRowOffset(category) {
  var match = get(category);
  if (match !== undefined) {
    return match.startRowOffset;
  }
  
}

function getSlidesPerView(category) {
  var match = get(category);
  if (match !== undefined) {
    return match.slidesPerView;
  }
  
}

var TaggedMedia = {
  get: get,
  getList: getList,
  getDisplayType: getDisplayType,
  getCardSize: getCardSize,
  getStartRowOffset: getStartRowOffset,
  getSlidesPerView: getSlidesPerView
};

function swapCategoryTypeWithQuickView(category) {
  var categoryData = category.categoryType;
  if (typeof categoryData === "number") {
    return category;
  }
  if (categoryData.TAG !== /* Standard */1) {
    return category;
  }
  var categoryData$1 = categoryData._0;
  var newrecord = Caml_obj.obj_dup(category);
  newrecord.categoryType = {
    TAG: /* QuickView */2,
    _0: {
      isStories: false,
      isPinned: false,
      firstCard: categoryData$1.firstCard,
      externalConnection: categoryData$1.externalConnection,
      children: categoryData$1.children,
      loadMoreChildren: categoryData$1.loadMoreChildren,
      totalChildren: categoryData$1.totalChildren,
      link: undefined,
      products: categoryData$1.products,
      imageHotspots: categoryData$1.imageHotspots,
      imageWidth: categoryData$1.imageWidth,
      imageHeight: categoryData$1.imageHeight
    }
  };
  return newrecord;
}

var index = Mem$Showside.mem(function (categories) {
      var generate = function (categories, path) {
        return Belt_Array.reduce(categories, [], (function (result, category) {
                      var key = category.id;
                      var subpath = Belt_Array.concat(path, [category]);
                      var match = category.categoryType;
                      var children;
                      if (typeof match === "number") {
                        children = [];
                      } else {
                        switch (match.TAG | 0) {
                          case /* Standard */1 :
                              children = Belt_Array.concat(match._0.children, Belt_Array.map(Belt_Option.getWithDefault(getList(category), []), swapCategoryTypeWithQuickView));
                              break;
                          case /* QuickView */2 :
                              children = match._0.children;
                              break;
                          default:
                            children = [];
                        }
                      }
                      return Belt_Array.concat(Belt_Array.concat(result, [[
                                        key,
                                        subpath
                                      ]]), generate(children, subpath));
                    }));
      };
      return Js_dict.fromArray(generate(categories, []));
    });

function findCategoriesBy(categories, fn) {
  return Belt_Array.keepMap(Js_dict.values(Curry._1(index, categories)), (function (path) {
                var firstInPath = Belt_Array.get(Belt_Array.reverse(path), 0);
                if (firstInPath !== undefined && Curry._1(fn, firstInPath)) {
                  return firstInPath;
                }
                
              }));
}

function findCategoryBy(categories, fn) {
  return Belt_Array.get(findCategoriesBy(categories, fn), 0);
}

function keepMapCategories(categories, fn) {
  return Belt_Array.keepMap(Js_dict.values(Curry._1(index, categories)), (function (path) {
                var category = Belt_Array.get(Belt_Array.reverse(path), 0);
                if (category !== undefined) {
                  return Curry._1(fn, category);
                }
                
              }));
}

function getCategoryPathById(categories, id) {
  return Belt_Option.getWithDefault(Js_dict.get(Curry._1(index, categories), id), []);
}

function getCategoryById(categories, id) {
  return Belt_Array.get(Belt_Array.reverse(getCategoryPathById(categories, id)), 0);
}

function getCategoryParentById(categories, id) {
  return Belt_Array.get(Belt_Array.reverse(getCategoryPathById(categories, id)), 1);
}

function getChildren(category) {
  var match = category.categoryType;
  if (typeof match === "number") {
    return [];
  }
  switch (match.TAG | 0) {
    case /* QuickView */2 :
        return match._0.children;
    case /* Standard */1 :
    case /* ConfigEmbed */4 :
        return match._0.children;
    default:
      return [];
  }
}

function getCategorySiblings(categories, id) {
  return Belt_Option.map(getCategoryParentById(categories, id), getChildren);
}

export {
  TaggedMedia ,
  swapCategoryTypeWithQuickView ,
  index ,
  findCategoriesBy ,
  findCategoryBy ,
  keepMapCategories ,
  getCategoryPathById ,
  getCategoryById ,
  getCategoryParentById ,
  getChildren ,
  getCategorySiblings ,
}
/* index Not a pure module */
