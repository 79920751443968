// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SWR from "../../../../node_modules/rescript-swr/lib/es6_global/src/SWR.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as StoreId$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/Store/StoreId.bs.js";
import * as Category$Showside from "../types/Category.bs.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as SWRKeyGen$Showside from "./SWRKeyGen.bs.js";

function getCategoryById(id) {
  var containsCategoriesSubstr = "api/get_config_nodes?";
  return Belt_Array.get(Belt_Array.keepMap(Belt_Array.map(Belt_Array.keep(Array.from(SWR.Cache.cache.keys()), (function (key) {
                            if (key.indexOf("api/get_config?") >= 0) {
                              return true;
                            } else {
                              return key.indexOf(containsCategoriesSubstr) >= 0;
                            }
                          })), (function (key) {
                        try {
                          var containsCategoriesRe = key.indexOf(containsCategoriesSubstr) >= 0;
                          if (containsCategoriesRe) {
                            var page = SWR.Cache.cache.get(key);
                            return Category$Showside.getCategoryById(page.categories, id);
                          }
                          var config = SWR.Cache.cache.get(key);
                          return Category$Showside.getCategoryById(config.categories, id);
                        }
                        catch (raw_e){
                          var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                          console.error("Error occured while scrapping cache:", e);
                          return ;
                        }
                      })), (function (a) {
                    return a;
                  })), 0);
}

function getProductsById(currency, country) {
  var containsArraySubstr = [
    "?type=loadProducts&",
    "?type=loadProducts2&",
    "?type=loadProductGroupByProductId&"
  ];
  var containsPageSubstr = [
    "?type=loadRelatedProducts&",
    "?type=loadCategoryProducts&"
  ];
  return Belt_Array.concatMany(Belt_Array.map(Belt_Array.keep(Belt_Array.keep(Array.from(SWR.Cache.cache.keys()), (function (key) {
                            if (Belt_Array.some(containsArraySubstr, (function (str) {
                                      return key.indexOf(str) === 0;
                                    }))) {
                              return true;
                            } else {
                              return Belt_Array.some(containsPageSubstr, (function (str) {
                                            return key.indexOf(str) === 0;
                                          }));
                            }
                          })), (function (key) {
                        var searchParams = Curry._1(SWRKeyGen$Showside.toURLSearchParams, key);
                        if (Caml_obj.equal(Caml_option.nullable_to_opt(searchParams.get("currency")), currency) && Caml_obj.equal(Caml_option.nullable_to_opt(searchParams.get("country")), country)) {
                          return true;
                        } else if (currency === undefined) {
                          return country === undefined;
                        } else {
                          return false;
                        }
                      })), (function (key) {
                    var containsArrays = Belt_Array.some(containsArraySubstr, (function (str) {
                            return key.indexOf(str) === 0;
                          }));
                    if (containsArrays) {
                      var products = SWR.Cache.cache.get(key);
                      return Belt_Option.getWithDefault(products, []);
                    }
                    var containsPage = Belt_Array.some(containsPageSubstr, (function (str) {
                            return key.indexOf(str) === 0;
                          }));
                    if (!containsPage) {
                      return [];
                    }
                    var page = SWR.Cache.cache.get(key);
                    return Belt_Option.mapWithDefault(page, [], (function (page) {
                                  return page.products;
                                }));
                  })));
}

function getProductById(currency, country, id) {
  return Belt_Array.getBy(getProductsById(currency, country), (function (product) {
                return Curry._1(StoreId$ApiTypes.ProductId.toString, product.id) === id;
              }));
}

export {
  getCategoryById ,
  getProductsById ,
  getProductById ,
}
/* SWR Not a pure module */
