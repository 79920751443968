// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../rescript/lib/es6/curry.js";

var fromString = (function(str) {
    return new Error(str)
  });

var fromStringWithCause = (function(str, cause) {
    return new Error(str, {cause: cause})
  });

var fromRescript = (function(err) {

      if (err instanceof Error) {
        return err
      }

      console.log("typeof error", typeof err)
      console.log("err.Error", err.Error)
      console.log("err.Error", err.RE_EXN_ID)
      console.log("err", err)

      if (err !== null && typeof err === "object" && err.Error instanceof Error) {
        let newError = new Error("Rescript Exception " + err.RE_EXN_ID, {cause: err.Error})
        return newError
      }

      if (err !== null && typeof err === "object" && err._1 instanceof Error) {
        return err._1
      }

      let newError = new Error("Rescript (uninterpretable) " + JSON.stringify(err))
      return newError
    });

function fromRescriptWithNewMessage(err, newMessage) {
  return fromStringWithCause(newMessage, fromRescript(err));
}

var $$Error = {
  fromString: fromString,
  fromStringWithCause: fromStringWithCause,
  fromRescript: fromRescript,
  fromRescriptWithNewMessage: fromRescriptWithNewMessage
};

function error1(param, param$1) {
  var fn1 = function (prim0, prim1, prim2) {
    prim0.error(prim1, prim2);
  };
  return new Promise((function (resolve, reject) {
                Curry._3(fn1, param, param$1, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function error2(client, e, a) {
  return new Promise((function (resolve, reject) {
                client.error(e, a, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function error3(client, e, a, b) {
  return new Promise((function (resolve, reject) {
                client.error(e, a, b, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function info1(client, msg) {
  return new Promise((function (resolve, reject) {
                client.info(msg, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function info2(client, msg, extraInfo) {
  return new Promise((function (resolve, reject) {
                client.info(msg, extraInfo, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function warning1(client, msg) {
  return new Promise((function (resolve, reject) {
                client.warning(msg, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function warning2(client, msg, extraInfo) {
  return new Promise((function (resolve, reject) {
                client.warning(msg, extraInfo, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

export {
  $$Error ,
  error1 ,
  error2 ,
  error3 ,
  info1 ,
  info2 ,
  warning1 ,
  warning2 ,
}
/* No side effect */
