// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Env$Showside from "../utils/Env.bs.js";
import * as RouterProvider$Showside from "../context/RouterProvider.bs.js";

var init = {
  smartnavApiHost: Env$Showside.apiHostnameProduction
};

var context = React.createContext(init);

var provider = context.Provider;

function UseEnv(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

function use(param) {
  var match = RouterProvider$Showside.useRouter(undefined);
  var devMode = match.devMode;
  return React.useContext(devMode !== undefined ? React.createContext({
                    smartnavApiHost: Env$Showside.getApiHostname(devMode)
                  }) : context);
}

var make = UseEnv;

export {
  init ,
  context ,
  provider ,
  make ,
  use ,
}
/* context Not a pure module */
