// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Category$Showside from "../types/Category.bs.js";
import * as URLUtils$Showside from "../utils/URLUtils.bs.js";
import * as LocationConfig$Showside from "../utils/LocationConfig.bs.js";
import * as SWRScrapeCache$Showside from "../utils/SWRScrapeCache.bs.js";
import * as ConfigurationProvider$Showside from "../context/ConfigurationProvider.bs.js";

function useDynamicSEOLinks(param) {
  var match = ConfigurationProvider$Showside.use(undefined);
  var match$1 = LocationConfig$Showside.use(undefined);
  var setUrlTransformer = match$1.setUrlTransformer;
  var urlTransformer = function (loc, originalUrl) {
    var categoryId = Belt_Option.flatMap(loc.quickNav, (function (nav) {
            return nav.categoryId;
          }));
    var productId = Belt_Option.flatMap(loc.quickNav, (function (nav) {
            return nav.productId;
          }));
    var variantId = Belt_Option.flatMap(loc.quickNav, (function (nav) {
            return nav.variantId;
          }));
    var category = Belt_Option.flatMap(categoryId, SWRScrapeCache$Showside.getCategoryById);
    var product = Belt_Option.flatMap(productId, (function (pi) {
            return SWRScrapeCache$Showside.getProductById(undefined, undefined, pi);
          }));
    var variant = Belt_Option.flatMap(variantId, (function (vi) {
            return Belt_Option.flatMap(product, (function (p) {
                          return Belt_Array.getBy(p.variants, (function (v) {
                                        return v.id === vi;
                                      }));
                        }));
          }));
    var newUrl = variant !== undefined ? Belt_Option.flatMap(variant.link, URLUtils$Showside.maybeMakeUrl) : (
        product !== undefined ? Belt_Option.flatMap(product.link, URLUtils$Showside.maybeMakeUrl) : (
            category !== undefined ? Belt_Option.flatMap(Category$Showside.getCategoryCardLinkUrl(true, category), URLUtils$Showside.maybeMakeUrl) : undefined
          )
      );
    if (Belt_Option.mapWithDefault(newUrl, false, (function (url) {
              return url.hostname === originalUrl.hostname;
            }))) {
      return newUrl;
    }
    
  };
  var isEnabled = match.config.settings.seoDynamicLinks;
  React.useEffect((function () {
          Curry._1(setUrlTransformer, (function (param) {
                  if (isEnabled) {
                    return urlTransformer;
                  }
                  
                }));
        }), [isEnabled]);
}

export {
  useDynamicSEOLinks ,
}
/* react Not a pure module */
