// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Js_null_undefined from "../../../../node_modules/rescript/lib/es6/js_null_undefined.js";
import $$IntersectionObserver from "@react-hook/intersection-observer";

function use(ref, viewport, initialIsIntersecting, threshold, thresholdMultiple, rootMargin, pollIntervalOpt, useMutationObserverOpt, param) {
  var pollInterval = pollIntervalOpt !== undefined ? pollIntervalOpt : 100;
  var useMutationObserver = useMutationObserverOpt !== undefined ? useMutationObserverOpt : true;
  var js = $$IntersectionObserver(ref, {
        root: Js_null_undefined.fromOption(Belt_Option.flatMap(viewport, (function (viewport) {
                    return Caml_option.nullable_to_opt(viewport.current);
                  }))),
        rootMargin: rootMargin,
        threshold: threshold !== undefined ? [threshold] : (
            thresholdMultiple !== undefined ? thresholdMultiple : undefined
          ),
        pollInterval: pollInterval,
        useMutationObserver: useMutationObserver,
        initialIsIntersecting: initialIsIntersecting
      });
  return {
          isIntersecting: js.isIntersecting,
          intersectionRect: Caml_option.nullable_to_opt(js.intersectionRect),
          boundingClientRect: Caml_option.nullable_to_opt(js.boundingClientRect),
          rootBounds: Caml_option.nullable_to_opt(js.rootBounds)
        };
}

export {
  use ,
}
/* @react-hook/intersection-observer Not a pure module */
