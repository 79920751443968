// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function getStoreTypeByStoreId(storeId) {
  if (storeId !== undefined) {
    if (storeId.startsWith("maker-legacy-graphql")) {
      return {
              TAG: /* Maker */0,
              _0: storeId
            };
    } else {
      return {
              TAG: /* Shopify */1,
              _0: storeId
            };
    }
  } else {
    return /* Unknown */0;
  }
}

var context = React.createContext(/* Unknown */0);

var provider = context.Provider;

function StoreProvider(Props) {
  var config = Props.config;
  var children = Props.children;
  var value = getStoreTypeByStoreId(config.transitiveStoreId);
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

function use(param) {
  return React.useContext(context);
}

var initialValue = /* Unknown */0;

var make = StoreProvider;

export {
  getStoreTypeByStoreId ,
  initialValue ,
  context ,
  provider ,
  make ,
  use ,
}
/* context Not a pure module */
