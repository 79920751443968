// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Js_string from "../../../../node_modules/rescript/lib/es6/js_string.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json__Decode from "../../../../node_modules/rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Window$Showside from "./Window.bs.js";
import * as URLUtils$Showside from "./URLUtils.bs.js";
import * as Namespace$Showside from "../types/Namespace.bs.js";
import * as HistoryUtils$Showside from "./HistoryUtils.bs.js";
import * as Nav_Location$Showside from "../Nav_Location.bs.js";
import * as LocationConfig$Showside from "./LocationConfig.bs.js";
import * as QuickView_Location$Showside from "../QuickView_Location.bs.js";
function make(quickViewOpt, quickNav, param) {
  var quickView = quickViewOpt !== undefined ? quickViewOpt : QuickView_Location$Showside.empty;
  return {
    quickView: quickView,
    quickNav: quickNav
  };
}
var quickView = QuickView_Location$Showside.empty;
var empty = {
  quickView: quickView,
  quickNav: undefined
};
var emptyStates_callbacks = [];
var emptyStates = {
  current: empty,
  previous: undefined,
  callbacks: emptyStates_callbacks,
  historyBackExecuted: false,
  initialUrl: undefined,
  urlTransformer: undefined
};
var currentGlobalState = Belt_Option.getWithDefault(window.makerLocationGlobalState, {});
window.makerLocationGlobalState = Caml_option.some(currentGlobalState);
function getStatesByNamespace(namespace) {
  return Belt_Option.getWithDefault(Namespace$Showside.map(namespace, function (ns) {
    return Js_dict.get(currentGlobalState, ns);
  }), emptyStates);
}
function setStatesByNamespace(namespace, updatedState) {
  Namespace$Showside.forEach(namespace, function (ns) {
    currentGlobalState[ns] = updatedState;
  });
}
function setCurrentByNamespace(namespace, updatedCurrent) {
  var init = getStatesByNamespace(namespace);
  setStatesByNamespace(namespace, {
    current: updatedCurrent,
    previous: init.previous,
    callbacks: init.callbacks,
    historyBackExecuted: init.historyBackExecuted,
    initialUrl: init.initialUrl,
    urlTransformer: init.urlTransformer
  });
}
function setPreviousByNamespace(namespace, updatedPrevious) {
  var init = getStatesByNamespace(namespace);
  setStatesByNamespace(namespace, {
    current: init.current,
    previous: updatedPrevious,
    callbacks: init.callbacks,
    historyBackExecuted: init.historyBackExecuted,
    initialUrl: init.initialUrl,
    urlTransformer: init.urlTransformer
  });
}
function setHistoryBackExecutedByNamespace(namespace, updatedHistoryBackExecuted) {
  var init = getStatesByNamespace(namespace);
  setStatesByNamespace(namespace, {
    current: init.current,
    previous: init.previous,
    callbacks: init.callbacks,
    historyBackExecuted: updatedHistoryBackExecuted,
    initialUrl: init.initialUrl,
    urlTransformer: init.urlTransformer
  });
}
function setCallbacksByNamespace(namespace, updatedCallbacks) {
  var init = getStatesByNamespace(namespace);
  setStatesByNamespace(namespace, {
    current: init.current,
    previous: init.previous,
    callbacks: updatedCallbacks,
    historyBackExecuted: init.historyBackExecuted,
    initialUrl: init.initialUrl,
    urlTransformer: init.urlTransformer
  });
}
function setInitialUrlByNamespace(namespace, updatedInitialUrl) {
  var init = getStatesByNamespace(namespace);
  setStatesByNamespace(namespace, {
    current: init.current,
    previous: init.previous,
    callbacks: init.callbacks,
    historyBackExecuted: init.historyBackExecuted,
    initialUrl: updatedInitialUrl,
    urlTransformer: init.urlTransformer
  });
}
function setUrlTransformerByNamespace(namespace, updatedUrlTransformer) {
  var init = getStatesByNamespace(namespace);
  setStatesByNamespace(namespace, {
    current: init.current,
    previous: init.previous,
    callbacks: init.callbacks,
    historyBackExecuted: init.historyBackExecuted,
    initialUrl: init.initialUrl,
    urlTransformer: updatedUrlTransformer
  });
}
function toDict($$location) {
  var merge = [Js_dict.entries(QuickView_Location$Showside.toDict($$location.quickView)), Belt_Option.mapWithDefault(Belt_Option.map($$location.quickNav, Nav_Location$Showside.toDict), [], Js_dict.entries)];
  return Js_dict.fromArray(Belt_Array.concatMany(merge));
}
function fromDict(dict) {
  return {
    quickView: QuickView_Location$Showside.fromDict(dict),
    quickNav: Nav_Location$Showside.fromDict(dict)
  };
}
function fromUrl(namespace, url) {
  return fromDict(Namespace$Showside.getDictByNamespace(Js_dict.fromArray(Array.from(new URL(url).searchParams.entries())), namespace));
}
function appendToUrl(namespace, $$location) {
  var originalBaseUrl = Belt_Option.getWithDefault(getStatesByNamespace(namespace).initialUrl, new URL(Window$Showside.getUrl(undefined)));
  var urlTransformer = getStatesByNamespace(namespace).urlTransformer;
  var url = Belt_Option.getWithDefault(Belt_Option.flatMap(urlTransformer, function (fn) {
    return Curry._2(fn, $$location, originalBaseUrl);
  }), originalBaseUrl);
  var params = url.searchParams;
  Belt_Array.forEach(Js_dict.entries(toDict($$location)), function (param) {
    var value = param[1];
    var key = Namespace$Showside.toNamespacedKey(param[0], namespace);
    if (value === "") {
      params.delete(key);
    } else {
      params.set(key, value);
    }
  });
  var str = params.toString();
  url.search = str;
  return url.toString();
}
var getPermalink = appendToUrl;
function getCurrentPermalink(namespace, param) {
  return appendToUrl(namespace, getStatesByNamespace(namespace).current);
}
function getBasePageUrl(namespace, param) {
  var str = Belt_Array.map(Object.keys(toDict(make(QuickView_Location$Showside.empty, Nav_Location$Showside.empty, undefined))), function (key) {
    return Belt_Option.mapWithDefault(namespace, key, function (namespace) {
      return Namespace$Showside.toNamespacedKey(key, Caml_option.some(namespace));
    });
  }).join("|");
  var paramsToDelete = new RegExp("^(" + str + ")");
  return URLUtils$Showside.deleteParamWithRegex(Belt_Option.getWithDefault(Belt_Option.map(getStatesByNamespace(namespace).initialUrl, function (prim) {
    return prim.href;
  }), window.location.href), paramsToDelete);
}
var eventData = function eventData(event) {
  const data = event.data;
  if (typeof data === 'string') {
    return data;
  }
  return undefined;
};
var encodePostMessageData = function encode(input) {
  return btoa(unescape(encodeURIComponent(input)));
};
var decodePostMessageData = function decode(input) {
  return decodeURIComponent(escape(atob(input)));
};
function toQueryString(namespace, $$location) {
  return Js_string.substr(1, Belt_Array.reduce(Js_dict.entries(toDict($$location)), "", function (acc, param) {
    return acc + "&" + Namespace$Showside.toNamespacedKey(param[0], namespace) + "=" + param[1];
  }));
}
function sanitizeLocation($$location) {
  return {
    quickView: QuickView_Location$Showside.sanitize($$location.quickView),
    quickNav: Belt_Option.mapWithDefault($$location.quickNav, Nav_Location$Showside.empty, Nav_Location$Showside.sanitize)
  };
}
function driveLocationPop(historyEnabledOpt, namespace, param) {
  var historyEnabled = historyEnabledOpt !== undefined ? historyEnabledOpt : true;
  var match = Window$Showside.isEmbed(undefined);
  if (match) {
    var data = JSON.stringify(Js_dict.fromArray([["historyBack", true]]));
    var encoded = encodePostMessageData(data);
    console.log(["Location: driveLocationPop", "data=", data, "encoded=", encoded]);
    return Promise.resolve(Window$Showside.postMessage("pushState", encoded));
  }
  if (historyEnabled) {
    return HistoryUtils$Showside.back(HistoryUtils$Showside.history);
  }
  var state = HistoryUtils$Showside.state(HistoryUtils$Showside.history);
  var url = getBasePageUrl(namespace, undefined);
  return HistoryUtils$Showside.replaceState(HistoryUtils$Showside.history, state, "", url);
}
function driveLocationChange(historyEnabledOpt, namespace, $$location, replaceState) {
  var historyEnabled = historyEnabledOpt !== undefined ? historyEnabledOpt : true;
  var match = Window$Showside.isEmbed(undefined);
  if (match) {
    var url = toQueryString(namespace, $$location);
    var data = encodePostMessageData(JSON.stringify(Js_dict.fromArray([["url", url], ["replaceState", replaceState]])));
    console.log("Location: driveLocationChange");
    return Promise.resolve(Window$Showside.postMessage("pushState", data));
  }
  if (historyEnabled) {
    var url$1 = appendToUrl(namespace, $$location);
    var state = HistoryUtils$Showside.state(HistoryUtils$Showside.history);
    if (replaceState) {
      return HistoryUtils$Showside.replaceState(HistoryUtils$Showside.history, state, "", url$1);
    } else {
      return HistoryUtils$Showside.pushState(HistoryUtils$Showside.history, state, "", url$1);
    }
  }
  var state$1 = HistoryUtils$Showside.state(HistoryUtils$Showside.history);
  var url$2 = getBasePageUrl(namespace, undefined);
  return HistoryUtils$Showside.replaceState(HistoryUtils$Showside.history, state$1, "", url$2);
}
function triggerCallbacks(namespace, param) {
  Belt_Array.forEach(getStatesByNamespace(namespace).callbacks, function (fn) {
    Curry._1(fn, undefined);
  });
}
function reset(namespace, configId) {
  var next = fromUrl(namespace, Window$Showside.getUrl(undefined));
  var isToCur = Caml_obj.equal(getStatesByNamespace(namespace).current, next);
  var nextConfigId = Belt_Option.map(next.quickNav, function (q) {
    return q.configId;
  });
  var isSameConfigId = Caml_obj.equal(configId, nextConfigId);
  if (isToCur) {
    return;
  } else if (isSameConfigId) {
    if (nextConfigId !== undefined) {
      setCurrentByNamespace(namespace, next);
      return triggerCallbacks(namespace, undefined);
    } else {
      return;
    }
  } else if (nextConfigId !== undefined) {
    return;
  } else {
    setCurrentByNamespace(namespace, next);
    return triggerCallbacks(namespace, undefined);
  }
}
function goToRaw(namespace, historyEnabledOpt, replaceStateOpt, isPopStateOpt, preserveScroll, next) {
  var historyEnabled = historyEnabledOpt !== undefined ? historyEnabledOpt : true;
  var replaceState = replaceStateOpt !== undefined ? replaceStateOpt : false;
  var isPopState = isPopStateOpt !== undefined ? isPopStateOpt : false;
  var isToCur = Caml_obj.equal(getStatesByNamespace(namespace).current, next);
  var isToPrev = Caml_obj.equal(getStatesByNamespace(namespace).previous, next);
  var replaceState$1 = getStatesByNamespace(namespace).historyBackExecuted ? false : replaceState;
  if (isPopState && !isToCur) {
    setPreviousByNamespace(namespace, undefined);
  } else if (isToCur) {} else if (isToPrev && Window$Showside.supportsHistoryBack(undefined)) {
    driveLocationPop(historyEnabled, namespace, undefined);
    setHistoryBackExecutedByNamespace(namespace, true);
  } else {
    driveLocationChange(historyEnabled, namespace, next, replaceState$1);
    setHistoryBackExecutedByNamespace(namespace, false);
    if (!replaceState$1) {
      setPreviousByNamespace(namespace, getStatesByNamespace(namespace).current);
    }
  }
  if (!isToCur) {
    setCurrentByNamespace(namespace, next);
    return triggerCallbacks(namespace, undefined);
  }
}
function goTo(namespace, historyEnabledOpt, quickView, quickNav, replaceStateOpt, preserveScroll, param) {
  var historyEnabled = historyEnabledOpt !== undefined ? historyEnabledOpt : true;
  var replaceState = replaceStateOpt !== undefined ? replaceStateOpt : false;
  var quickNav$1 = quickNav !== undefined || quickView === undefined ? quickNav : fromUrl(namespace, window.location.href).quickNav;
  var quickView$1 = Belt_Option.getWithDefault(quickView, QuickView_Location$Showside.empty);
  var next = {
    quickView: quickView$1,
    quickNav: quickNav$1
  };
  goToRaw(namespace, historyEnabled, replaceState, undefined, undefined, next);
}
function handlePopstate(namespace, param) {
  var $$location = fromUrl(namespace, Window$Showside.getUrl(undefined));
  goToRaw(namespace, undefined, undefined, true, undefined, $$location);
}
function handlePopstateMessage(namespace, data) {
  var decoded = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.map(Belt_Option.map(data, decodePostMessageData), function (__x) {
    return Json__Decode.decodeString(__x, Json__Decode.dict(Json__Decode.string));
  }), function (result) {
    if (result.TAG === /* Ok */0) {
      return Caml_option.some(result._0);
    }
  }), {});
  var url = Js_dict.get(decoded, "url");
  var $$location = Belt_Option.getWithDefault(Belt_Option.map(url, function (param) {
    return fromUrl(namespace, param);
  }), empty);
  goToRaw(namespace, undefined, undefined, true, undefined, $$location);
}
function handleMessage(namespace, $$event) {
  var parts = Belt_Option.map(eventData($$event), function (param) {
    return Js_string.split("-", param);
  });
  var action = Belt_Option.flatMap(parts, function (parts) {
    return Belt_Array.get(parts, 0);
  });
  var data = Belt_Option.flatMap(parts, function (parts) {
    return Belt_Array.get(parts, 1);
  });
  var iframeid = Belt_Option.flatMap(Belt_Option.map(parts, Belt_Array.reverse), function (parts) {
    return Belt_Array.get(parts, 0);
  });
  if (action === undefined) {
    return;
  }
  switch (action) {
    case "popstate":
      if (iframeid !== undefined && iframeid === "iframeid=" + window.iframeid) {
        return handlePopstateMessage(namespace, data);
      } else {
        return;
      }
    case "pushState":
      if (!Window$Showside.isEmbed(undefined)) {
        setTimeout(function (param) {
          return handlePopstate(namespace, param);
        }, 42);
        return;
      } else {
        return;
      }
    default:
      return;
  }
}
function addEvents(namespace) {
  window.removeEventListener("message", function (param) {
    return handleMessage(namespace, param);
  });
  window.addEventListener("message", function (param) {
    return handleMessage(namespace, param);
  });
  if (!Window$Showside.isEmbed(undefined)) {
    window.removeEventListener("popstate", function (param) {
      return handlePopstate(namespace, param);
    });
    window.addEventListener("popstate", function (param) {
      return handlePopstate(namespace, param);
    });
    return;
  }
}
var popstateHandled = {
  contents: false
};
function use(param) {
  var match = LocationConfig$Showside.use(undefined);
  var urlTransformer = match.urlTransformer;
  var namespace = match.namespace;
  var match$1 = React.useState(function () {
    return getStatesByNamespace(namespace).current;
  });
  var setLocation = match$1[1];
  React.useEffect(function () {
    if (getStatesByNamespace(namespace).initialUrl === undefined) {
      setInitialUrlByNamespace(namespace, URLUtils$Showside.maybeMakeUrl(Window$Showside.getUrl(undefined)));
    }
  }, [namespace]);
  React.useEffect(function () {
    setUrlTransformerByNamespace(namespace, urlTransformer);
  }, [namespace, urlTransformer]);
  React.useEffect(function () {
    var update = function (param) {
      Curry._1(setLocation, function (param) {
        return getStatesByNamespace(namespace).current;
      });
    };
    setCallbacksByNamespace(namespace, Belt_Array.concat(getStatesByNamespace(namespace).callbacks, [update]));
    return function (param) {
      setCallbacksByNamespace(namespace, Belt_Array.keep(getStatesByNamespace(namespace).callbacks, function (fn) {
        return fn !== update;
      }));
    };
  }, [namespace]);
  React.useEffect(function () {
    addEvents(namespace);
    if (popstateHandled.contents === false) {
      handlePopstate(namespace, undefined);
      popstateHandled.contents = true;
    }
  }, [namespace]);
  return match$1[0];
}
handlePopstate(undefined, undefined);
export { make, empty, emptyStates, currentGlobalState, getStatesByNamespace, setStatesByNamespace, setCurrentByNamespace, setPreviousByNamespace, setHistoryBackExecutedByNamespace, setCallbacksByNamespace, setInitialUrlByNamespace, setUrlTransformerByNamespace, toDict, fromDict, fromUrl, appendToUrl, getPermalink, getCurrentPermalink, getBasePageUrl, eventData, encodePostMessageData, decodePostMessageData, toQueryString, sanitizeLocation, driveLocationPop, driveLocationChange, triggerCallbacks, reset, goToRaw, goTo, handlePopstate, handlePopstateMessage, handleMessage, addEvents, popstateHandled, use };
/* currentGlobalState Not a pure module */