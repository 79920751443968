// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Facets$Showside from "./Facets.bs.js";

var Item = {};

function getOptions(section) {
  switch (section.TAG | 0) {
    case /* ColorCheckbox */0 :
        return Belt_Array.map(section._0.options, (function (opt) {
                      return {
                              id: opt.id,
                              label: opt.label,
                              facet: opt.facet,
                              value: opt.colorValue
                            };
                    }));
    case /* ImageCheckbox */1 :
        return Belt_Array.map(section._0.options, (function (opt) {
                      return {
                              id: opt.id,
                              label: opt.label,
                              facet: opt.facet,
                              value: opt.imageUrl
                            };
                    }));
    case /* Checkbox */2 :
        return Belt_Array.map(section._0.options, (function (opt) {
                      return {
                              id: opt.id,
                              label: opt.label,
                              facet: opt.facet,
                              value: undefined
                            };
                    }));
    
  }
}

function getLabelByFacet(section, facet) {
  return Belt_Option.map(Belt_Array.getBy(getOptions(section), (function (item) {
                    return item.facet === facet;
                  })), (function (item) {
                return item.label;
              }));
}

function getLabel(section) {
  return section._0.label;
}

function getId(section) {
  return section._0.id;
}

function isVisible(section) {
  return section._0.visible;
}

var Section = {
  getOptions: getOptions,
  getLabelByFacet: getLabelByFacet,
  getLabel: getLabel,
  getId: getId,
  isVisible: isVisible
};

function getLabelByFacet$1(config, facet) {
  return Belt_Array.get(Belt_Array.keepMap(config, (function (section) {
                    return getLabelByFacet(section, facet);
                  })), 0);
}

function getFacetsByKVPair(config, kvPair) {
  return Facets$Showside.make(undefined, undefined, Belt_Array.keep(Belt_Array.mapWithIndex(Belt_Array.concatMany(Belt_Array.map(config, (function (c) {
                                switch (c.TAG | 0) {
                                  case /* ColorCheckbox */0 :
                                      return Belt_Array.map(c._0.options, (function (param) {
                                                    return param.facet;
                                                  }));
                                  case /* ImageCheckbox */1 :
                                      return Belt_Array.map(c._0.options, (function (param) {
                                                    return param.facet;
                                                  }));
                                  case /* Checkbox */2 :
                                      return Belt_Array.map(c._0.options, (function (param) {
                                                    return param.facet;
                                                  }));
                                  
                                }
                              }))), (function (i, f) {
                        return {
                                facet: f,
                                index: i
                              };
                      })), (function (param) {
                    var facet = param.facet;
                    return Belt_Array.some(kvPair, (function (kv) {
                                  return kv === facet;
                                }));
                  })));
}

export {
  Item ,
  Section ,
  getLabelByFacet$1 as getLabelByFacet,
  getFacetsByKVPair ,
}
/* Facets-Showside Not a pure module */
