// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SWR from "../../../../node_modules/rescript-swr/lib/es6_global/src/SWR.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Fetch from "../../../../node_modules/bs-fetch/lib/es6_global/src/Fetch.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as $$Promise from "../../../../node_modules/@ryyppy/rescript-promise/lib/es6_global/src/Promise.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as URLUtils$Showside from "./URLUtils.bs.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as RollbarInstance$Showside from "./RollbarInstance.bs.js";
function ignoreDecode(json) {
  return {
    TAG: /* Ok */0,
    _0: json
  };
}
function truncateTo30Characters(str) {
  if (str.length > 30) {
    return str.slice(0, 30) + "...";
  } else {
    return str;
  }
}
function tryExtractUsefulErrorMessageFromResponse(res) {
  return Fetch.$$Response.json(res).then(function (json) {
    var rawJson = JSON.stringify(json);
    var obj = Js_json.decodeObject(json);
    if (obj === undefined) {
      return Promise.resolve({
        displayError: truncateTo30Characters(rawJson),
        rawError: rawJson
      });
    }
    var displayError = Belt_Option.flatMap(Js_dict.get(Caml_option.valFromOption(obj), "error"), Js_json.decodeString);
    if (displayError !== undefined) {
      return Promise.resolve({
        displayError: displayError,
        rawError: rawJson
      });
    } else {
      return Promise.resolve({
        displayError: truncateTo30Characters(rawJson),
        rawError: rawJson
      });
    }
  });
}
function $$fetch$1(asyncDecoder, method_Opt, body, headers, errorEncoder, decoder, url) {
  var method_ = method_Opt !== undefined ? method_Opt : /* Get */0;
  var pathname = Belt_Option.getWithDefault(URLUtils$Showside.getPathname(URLUtils$Showside.resolveRelativePath(url)), url);
  return $$Promise.$$catch(fetch(url, Fetch.RequestInit.make(method_, headers, body, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)), function (err) {
    return Promise.reject({
      RE_EXN_ID: SWR.NetworkError,
      _1: Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.as_js_exn(err), function (prim) {
        return prim.message;
      }), "Network error")
    });
  }).then(function (res) {
    var status = res.status;
    if (status >= 200 && status < 300) {
      return Promise.resolve(res);
    }
    var willReport = status !== 404;
    return $$Promise.$$catch(tryExtractUsefulErrorMessageFromResponse(res), function (e) {
      return Promise.resolve({
        displayError: "Unknown server error",
        rawError: Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.as_js_exn(e), function (prim) {
          return prim.message;
        }), "")
      });
    }).then(function (param) {
      var errorMessage = "Error " + status.toString() + " in API " + pathname + ": " + param.displayError + "";
      var error = {
        RE_EXN_ID: SWR.ServerError,
        _1: errorMessage
      };
      if (willReport) {
        return RollbarInstance$Showside.error3(error, errorMessage, {
          requestUrl: url,
          rawError: param.rawError
        }).then(function (param) {
          return Promise.reject(error);
        });
      } else {
        return Promise.reject(error);
      }
    });
  }).then(function ($$fetch) {
    return Fetch.$$Response.json($$fetch).then(function (json) {
      var decoded = asyncDecoder !== undefined ? Curry._1(asyncDecoder, json) : Promise.resolve(Curry._1(decoder, json));
      var errorEncoder$1 = Belt_Option.getWithDefault(errorEncoder, function (e) {
        return Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.as_js_exn(Caml_js_exceptions.internalToOCamlException(e)), function (prim) {
          return prim.message;
        }), "Unknown error");
      });
      return decoded.then(function (decoded) {
        if (decoded.TAG === /* Ok */0) {
          return Promise.resolve(decoded._0);
        }
        var e = decoded._0;
        var errorMessage = "Failed to decode response of API " + pathname + ": " + Curry._1(errorEncoder$1, e) + "";
        var error = {
          RE_EXN_ID: SWR.DecodeError,
          _1: errorMessage
        };
        return RollbarInstance$Showside.error3(error, errorMessage, {
          requestUrl: url,
          rawError: e
        }).then(function (param) {
          return Promise.reject(error);
        });
      });
    });
  });
}
export { ignoreDecode, truncateTo30Characters, tryExtractUsefulErrorMessageFromResponse, $$fetch$1 as $$fetch };
/* SWR Not a pure module */