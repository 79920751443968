// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Shopify$Showside from "./Shopify.bs.js";

var blacklist = /^\/cart|^\/store\/jump\/static|^\/orderconfirmation|^\/pages\/mattressquiz|^\/collections\/limited-edition/i;

function isSmartBarEnabled(embedType) {
  var $$location = window.location;
  var pathname = $$location.pathname;
  var isSmartNavOpen = Belt_Option.isSome(Caml_option.null_to_opt($$location.search.match(/QNConfigId|QNTabId/)));
  var match = Shopify$Showside.isProductPage(undefined);
  var match$1 = Belt_Option.isSome(Caml_option.null_to_opt(pathname.match(blacklist)));
  if (match) {
    if (isSmartNavOpen && !match$1) {
      return true;
    } else {
      return embedType !== 0;
    }
  } else if (match$1) {
    return embedType !== 0;
  } else {
    return true;
  }
}

var useSmartBarEnabled = isSmartBarEnabled;

export {
  blacklist ,
  isSmartBarEnabled ,
  useSmartBarEnabled ,
}
/* Shopify-Showside Not a pure module */
