// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";

function getUnique(array, getKey) {
  var chosen = {};
  var idsInOrder = [];
  Belt_Array.forEach(array, (function (p) {
          var curChosen = Js_dict.get(chosen, Curry._1(getKey, p));
          if (curChosen !== undefined) {
            return ;
          } else {
            idsInOrder.push(Curry._1(getKey, p));
            chosen[Curry._1(getKey, p)] = p;
            return ;
          }
        }));
  return Belt_Array.keepMap(idsInOrder, (function (id) {
                return Js_dict.get(chosen, id);
              }));
}

export {
  getUnique ,
}
/* No side effect */
