// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";

function safeJsonParse(str) {
  try {
    return Caml_option.some(JSON.parse(str));
  }
  catch (e){
    return ;
  }
}

function fromJson(json) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(json, Js_json.decodeString), safeJsonParse), Js_json.decodeObject), (function (object) {
                var skuToOriginalSourceMapping = Belt_Option.map(Belt_Option.flatMap(Js_dict.get(object, "SKUToOriginalSourceMapping"), Js_json.decodeObject), (function (dict) {
                        return Js_dict.fromArray(Belt_Array.keepMap(Belt_Array.map(Js_dict.entries(dict), (function (param) {
                                              var value = Js_json.decodeString(param[1]);
                                              if (value !== undefined) {
                                                return [
                                                        param[0],
                                                        value
                                                      ];
                                              }
                                              
                                            })), (function (a) {
                                          return a;
                                        })));
                      }));
                if (skuToOriginalSourceMapping !== undefined) {
                  return {
                          skuToOriginalSourceMapping: Caml_option.valFromOption(skuToOriginalSourceMapping)
                        };
                }
                
              }));
}

var SalsifyImageSources = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(json, Js_json.decodeString), safeJsonParse), Js_json.decodeArray), (function (array) {
                return Belt_Array.keepMap(array, Js_json.decodeString);
              }));
}

var SwatchesJson = {
  fromJson: fromJson$1
};

function getDisplaySwatchImage(metadata, sku) {
  var mapping = fromJson(Belt_Option.flatMap(Js_dict.get(metadata, "cql::salsify_image_sources"), (function (metadata) {
              if (typeof metadata === "number" || metadata.TAG !== /* Json */9) {
                return ;
              } else {
                return Caml_option.some(metadata._0);
              }
            })));
  var swatches = fromJson$1(Belt_Option.flatMap(Js_dict.get(metadata, "cql::swatches_json"), (function (metadata) {
              if (typeof metadata === "number" || metadata.TAG !== /* Json */9) {
                return ;
              } else {
                return Caml_option.some(metadata._0);
              }
            })));
  if (mapping === undefined) {
    return ;
  }
  if (swatches === undefined) {
    return ;
  }
  if (sku === undefined) {
    return ;
  }
  var imageSrc = Js_dict.get(mapping.skuToOriginalSourceMapping, sku);
  var isUrl = Belt_Option.mapWithDefault(imageSrc, false, (function (src) {
          return src.startsWith("http");
        }));
  var color = Belt_Option.flatMap(imageSrc, (function (src) {
          return Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.null_to_opt(src.match(/__([a-z-]+)_0\.([a-z]+)$/)), (function (__x) {
                            return Belt_Array.get(__x, 1);
                          })), (function (a) {
                        return a;
                      }));
        }));
  if (color !== undefined && isUrl) {
    return Caml_option.undefined_to_opt(swatches.find(function (swatch) {
                    return swatch.includes("/" + color + ".");
                  }));
  }
  
}

function parseMetadata(field, value) {
  var rowSplitter = ",,,,,,,,,,";
  var columnSplitter = "::::::::::";
  var isWeirdData = function (value) {
    if (value.includes(rowSplitter)) {
      return value.includes(columnSplitter);
    } else {
      return false;
    }
  };
  if (typeof value === "number") {
    return ;
  }
  if (value.TAG !== /* SingleLineTextField */1) {
    return ;
  }
  var value$1 = value._0;
  if (field !== "cql::detail_specs") {
    return ;
  }
  if (!isWeirdData(value$1)) {
    return ;
  }
  var tableContent = Belt_Array.map(Belt_Array.keep(Belt_Array.map(value$1.split(rowSplitter), (function (row) {
                    return row.split(columnSplitter);
                  })), (function (row) {
                return Caml_obj.notequal(Belt_Array.get(row, 1), "");
              })), (function (row) {
            var columns = Belt_Array.mapWithIndex(row, (function (i, column) {
                      return "<td style=\"font-weight: " + (
                              i === 0 ? "bold" : "normal"
                            ) + "\">" + column + "</td>";
                    })).join("");
            return "<tr>" + columns + "</tr>";
          })).join("");
  return {
          TAG: /* MultiLineTextField */0,
          _0: "<table><tbody style=\"width: 100%\">" + tableContent + "</tbody></table>"
        };
}

export {
  safeJsonParse ,
  SalsifyImageSources ,
  SwatchesJson ,
  getDisplaySwatchImage ,
  parseMetadata ,
}
/* No side effect */
