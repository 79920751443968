/* eslint-disable no-return-assign */

/* eslint-disable no-underscore-dangle */
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import useLayoutEffect from '@react-hook/passive-layout-effect';
import useLatest from '@react-hook/latest';
import rafSchd from 'raf-schd';
const ResizeObserver = typeof window !== 'undefined' && 'ResizeObserver' in window ? // @ts-ignore
window.ResizeObserver : Polyfill;
/**
 * A React hook that fires a callback whenever ResizeObserver detects a change to its size
 *
 * @param target A React ref created by `useRef()` or an HTML element
 * @param callback Invoked with a single `ResizeObserverEntry` any time
 *   the `target` resizes
 */

function _ref() {}

function useResizeObserver(target, callback) {
  const resizeObserver = getResizeObserver();
  const storedCallback = useLatest(callback);
  useLayoutEffect(() => {
    let didUnsubscribe = false;
    const targetEl = target && 'current' in target ? target.current : target;
    if (!targetEl) return _ref;

    function cb(entry, observer) {
      if (didUnsubscribe) return;
      storedCallback.current(entry, observer);
    }

    resizeObserver.subscribe(targetEl, cb);
    return () => {
      didUnsubscribe = true;
      resizeObserver.unsubscribe(targetEl, cb);
    };
  }, [target, resizeObserver, storedCallback]);
  return resizeObserver.observer;
}

function createResizeObserver() {
  const callbacks = new Map();
  const observer = new ResizeObserver(rafSchd((entries, obs) => {
    for (let i = 0; i < entries.length; i++) {
      const cbs = callbacks.get(entries[i].target);
      cbs === null || cbs === void 0 ? void 0 : cbs.forEach(cb => cb(entries[i], obs));
    }
  }));
  return {
    observer,

    subscribe(target, callback) {
      var _callbacks$get;

      observer.observe(target);
      const cbs = (_callbacks$get = callbacks.get(target)) !== null && _callbacks$get !== void 0 ? _callbacks$get : [];
      cbs.push(callback);
      callbacks.set(target, cbs);
    },

    unsubscribe(target, callback) {
      var _callbacks$get2;

      const cbs = (_callbacks$get2 = callbacks.get(target)) !== null && _callbacks$get2 !== void 0 ? _callbacks$get2 : [];

      if (cbs.length === 1) {
        observer.unobserve(target);
        callbacks.delete(target);
        return;
      }

      const cbIndex = cbs.indexOf(callback);
      if (cbIndex !== -1) cbs.splice(cbIndex, 1);
      callbacks.set(target, cbs);
    }

  };
}

let _resizeObserver;

const getResizeObserver = () => !_resizeObserver ? _resizeObserver = createResizeObserver() : _resizeObserver;

export default useResizeObserver;