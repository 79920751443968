// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Debounce$Showside from "./Debounce.bs.js";
import * as Previous$Showside from "./Previous.bs.js";
import * as UseWindowSize$Showside from "../hook/UseWindowSize.bs.js";
import $$ResizeObserver from "@react-hook/resize-observer";

function useResizeObserver(renderOnlyOpt, ref) {
  var renderOnly = renderOnlyOpt !== undefined ? renderOnlyOpt : false;
  var getSize = function (param) {
    return Belt_Option.map(Caml_option.nullable_to_opt(ref.current), (function (el) {
                  var rect = el.getBoundingClientRect();
                  return {
                          width: rect.width | 0,
                          height: rect.height | 0,
                          top: rect.top | 0,
                          left: rect.left | 0
                        };
                }));
  };
  var match = Debounce$Showside.useDebounce(true, undefined, (function (param) {
          return getSize(undefined);
        }));
  var setSizeImmediate = match[2];
  var setSize = match[1];
  var size = match[0];
  var previousSize = Previous$Showside.use(size, size);
  var updateSize = function (immediate) {
    Belt_Option.map(getSize(undefined), (function (size) {
            var previousWidth = Belt_Option.map(previousSize, (function (ps) {
                    return ps.width;
                  }));
            var previousHeight = Belt_Option.map(previousSize, (function (ps) {
                    return ps.height;
                  }));
            var previousTop = Belt_Option.map(previousSize, (function (ps) {
                    return ps.top;
                  }));
            if (previousWidth !== size.width || previousHeight !== size.height || previousTop !== size.top) {
              if (immediate) {
                return Curry._1(setSizeImmediate, (function (param) {
                              return size;
                            }));
              } else {
                return Curry._1(setSize, (function (param) {
                              return size;
                            }));
              }
            }
            
          }));
  };
  React.useLayoutEffect(function () {
        updateSize(true);
      });
  if (!renderOnly) {
    $$ResizeObserver(ref, (function (param) {
            updateSize(false);
          }));
  }
  return size;
}

function useWithWindowFallback(ref) {
  var match = UseWindowSize$Showside.useWindowSize(undefined, undefined, undefined);
  return Belt_Option.getWithDefault(useResizeObserver(undefined, Belt_Option.getWithDefault(ref, React.createRef())), {
              width: match[0],
              height: match[1],
              top: 0,
              left: 0
            });
}

var empty = {
  width: 0,
  height: 0,
  top: 0,
  left: 0
};

export {
  empty ,
  useResizeObserver ,
  useWithWindowFallback ,
}
/* react Not a pure module */
