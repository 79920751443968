// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function fromString(panelSize) {
  switch (panelSize) {
    case "large" :
        return /* Large */0;
    case "medium" :
        return /* Medium */1;
    case "small" :
        return /* Small */2;
    default:
      return /* Large */0;
  }
}

function toString(panelSize) {
  if (panelSize === 0) {
    return "large";
  }
  switch (panelSize) {
    case /* Medium */1 :
        return "medium";
    case /* Small */2 :
        return "small";
    case /* FullWidth */3 :
        return "large";
    
  }
}

var PanelSize = {
  fromString: fromString,
  toString: toString
};

function fromString$1(panelPosition) {
  if (panelPosition === "left") {
    return /* Left */0;
  } else {
    return /* Right */1;
  }
}

var PanelPosition = {
  fromString: fromString$1
};

function getSiteHeaderCSSSelector(settings, account) {
  return Belt_Option.orElse(settings.siteHeaderCSSSelector, Belt_Option.flatMap(account, (function (account) {
                    return account.siteHeaderCSSSelector;
                  })));
}

export {
  PanelSize ,
  PanelPosition ,
  getSiteHeaderCSSSelector ,
}
/* No side effect */
