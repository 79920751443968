// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_obj from "../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as ReactDom from "react-dom";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Shopify$Showside from "./utils/Shopify.bs.js";
import * as Location$Showside from "./utils/Location.bs.js";
import * as EmbedTypes$Showside from "./types/EmbedTypes.bs.js";
import * as LCPContext$Showside from "./context/LCPContext.bs.js";
import * as Slider_Lazy$Showside from "./utils/Slider_Lazy.bs.js";
import * as Webapi__Dom__Element from "../../../node_modules/rescript-webapi/lib/es6_global/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as AdditionalCSS$Showside from "./components/AdditionalCSS.bs.js";
import * as BaseWithTheme$Showside from "./BaseWithTheme.bs.js";
import * as EditorMethods$Showside from "./components/EditorMethods.bs.js";
import * as StoreProvider$Showside from "./context/StoreProvider.bs.js";
import * as ConfigIdGetter$Showside from "./ConfigIdGetter.bs.js";
import * as LocationConfig$Showside from "./utils/LocationConfig.bs.js";
import * as RouterProvider$Showside from "./context/RouterProvider.bs.js";
import * as SectionApp_Lazy$Showside from "./apps/SectionApp_Lazy.bs.js";
import * as ViewportContext$Showside from "./context/ViewportContext.bs.js";
import * as LauncherApp_Lazy$Showside from "./apps/LauncherApp_Lazy.bs.js";
import * as BottombarApp_Lazy$Showside from "./apps/BottombarApp_Lazy.bs.js";
import * as ConditionalRender$Showside from "./utils/ConditionalRender.bs.js";
import * as NavEnabledTracker$Showside from "./NavEnabledTracker.bs.js";
import * as UseDynamicSEOLinks$Showside from "./hook/UseDynamicSEOLinks.bs.js";
import * as CartContextProvider$Showside from "./context/CartContextProvider.bs.js";
import * as CriticalCSSProvider$Showside from "./context/CriticalCSSProvider.bs.js";
import * as EscapeKeyController$Showside from "./components/EscapeKeyController.bs.js";
import * as ConfigurationProvider$Showside from "./context/ConfigurationProvider.bs.js";
import * as UserSavedProductsProvider$Showside from "./context/UserSavedProductsProvider.bs.js";
import * as EntrypointConfigIdProvider$Showside from "./EntrypointConfigIdProvider.bs.js";
import * as StickyHeaderOffsetProvider$Showside from "./context/StickyHeaderOffsetProvider.bs.js";
import * as ExternalIntegrationProvider$Showside from "./context/ExternalIntegrationProvider.bs.js";
import * as PictureInPicture2SessionStorage$Showside from "./utils/PictureInPicture2SessionStorage.bs.js";

function querySelectorAll(selector) {
  return Belt_Array.reduce(Belt_Array.map(Array.prototype.slice.call(document.querySelectorAll(selector)), Webapi__Dom__Element.ofNode), [], (function (acc, curr) {
                if (curr !== undefined) {
                  return Belt_Array.concat(acc, [Caml_option.valFromOption(curr)]);
                } else {
                  return acc;
                }
              }));
}

function getElements(param) {
  var elements = querySelectorAll("#maker-smartnav, #maker-smartnav-launcher, #maker-smartnav-section");
  var makerPagesModal = document.querySelector("#maker-modal");
  var shop = Shopify$Showside.getShop(undefined);
  var match = elements.length !== 0;
  if (match) {
    return elements;
  }
  if (shop === undefined) {
    return [];
  }
  if (!(makerPagesModal == null)) {
    return [];
  }
  var element = document.createElement("div");
  element.id = "maker-smartnav";
  Belt_Option.forEach(Caml_option.nullable_to_opt(document.querySelector("body")), (function (child) {
          element.appendChild(child);
        }));
  return [element];
}

function Entrypoint$Content(Props) {
  var embedType = Props.embedType;
  var configId = Props.configId;
  var onOpenLink = Props.onOpenLink;
  var attributes = Props.attributes;
  var isStyleLoaded = CriticalCSSProvider$Showside.useIsStyleLoaded(undefined);
  var match = React.useState(function () {
        return true;
      });
  var setIsSmarbarEnabled = match[1];
  var isSmartBarEnabled = match[0];
  var match$1 = RouterProvider$Showside.useRouter(undefined);
  var previewMode = match$1.previewMode;
  var match$2 = ConfigurationProvider$Showside.use(undefined);
  var config = match$2.config;
  UseDynamicSEOLinks$Showside.useDynamicSEOLinks(undefined);
  React.useEffect((function () {
          Curry._1(Slider_Lazy$Showside.preload, undefined);
        }), []);
  var tmp;
  switch (embedType) {
    case /* Bottombar */0 :
        if (isSmartBarEnabled) {
          var tmp$1 = {
            configId: configId,
            embedType: embedType
          };
          if (onOpenLink !== undefined) {
            tmp$1.onOpenLink = Caml_option.valFromOption(onOpenLink);
          }
          if (attributes !== undefined) {
            tmp$1.attributes = Caml_option.valFromOption(attributes);
          }
          tmp = React.createElement(BottombarApp_Lazy$Showside.make, tmp$1);
        } else {
          tmp = null;
        }
        break;
    case /* Launcher */2 :
        if (isSmartBarEnabled) {
          var tmp$2 = {
            configId: configId,
            embedType: embedType
          };
          if (onOpenLink !== undefined) {
            tmp$2.onOpenLink = Caml_option.valFromOption(onOpenLink);
          }
          if (attributes !== undefined) {
            tmp$2.attributes = Caml_option.valFromOption(attributes);
          }
          tmp = React.createElement(LauncherApp_Lazy$Showside.make, tmp$2);
        } else {
          tmp = null;
        }
        break;
    case /* Section */3 :
        if (isSmartBarEnabled) {
          var tmp$3 = {
            configId: configId,
            embedType: embedType
          };
          if (onOpenLink !== undefined) {
            tmp$3.onOpenLink = Caml_option.valFromOption(onOpenLink);
          }
          if (attributes !== undefined) {
            tmp$3.attributes = Caml_option.valFromOption(attributes);
          }
          tmp = React.createElement(SectionApp_Lazy$Showside.make, tmp$3);
        } else {
          tmp = null;
        }
        break;
    case /* Inline */1 :
    case /* Menu */4 :
        tmp = null;
        break;
    
  }
  return React.createElement(BaseWithTheme$Showside.make, {
              theme: config.settings.styling,
              children: null
            }, React.createElement(AdditionalCSS$Showside.make, {}), React.createElement(ConditionalRender$Showside.When.make, {
                  condition: Caml_obj.notequal(previewMode, /* PreviewLive */1) && previewMode !== undefined,
                  children: React.createElement(EditorMethods$Showside.make, {})
                }), React.createElement(EscapeKeyController$Showside.make, {
                  embedType: embedType
                }), React.createElement(UserSavedProductsProvider$Showside.make, {
                  children: React.createElement(CartContextProvider$Showside.make, {
                        children: React.createElement(StickyHeaderOffsetProvider$Showside.make, {
                              attributes: attributes,
                              children: React.createElement(ConditionalRender$Showside.When.make, {
                                    condition: isStyleLoaded,
                                    children: null
                                  }, React.createElement(NavEnabledTracker$Showside.make, {
                                        embedType: embedType,
                                        onChange: (function (enabled) {
                                            Curry._1(setIsSmarbarEnabled, (function (param) {
                                                    return enabled;
                                                  }));
                                          })
                                      }), React.createElement(StoreProvider$Showside.make, {
                                        config: config,
                                        children: React.createElement(LCPContext$Showside.Provider.make, {
                                              children: tmp
                                            })
                                      }))
                            })
                      })
                }));
}

var make = React.memo(Entrypoint$Content);

var Content = {
  make: make
};

function Entrypoint$Entrypoint(Props) {
  var configId = Props.configId;
  var embedTypeOpt = Props.embedType;
  var attributes = Props.attributes;
  var trackers = Props.trackers;
  var onAddToCart = Props.onAddToCart;
  var onOpenLink = Props.onOpenLink;
  var embedType = embedTypeOpt !== undefined ? embedTypeOpt : /* Bottombar */0;
  var accountId = Belt_Option.flatMap(attributes, (function (attr) {
          return attr.account;
        }));
  var namespace = EmbedTypes$Showside.getNamespace(attributes);
  var isInlineElementOnThePage = function (param) {
    return querySelectorAll("#maker-smartnav-inline, [data-maker-smartnav-inline='true']").length !== 0;
  };
  var match = React.useState(function () {
        return isInlineElementOnThePage(undefined);
      });
  var setInlineExists = match[1];
  var allowMultipleEmbeds = (window.makerAllowMultipleEmbeds || false);
  React.useEffect((function () {
          var handleRender = function (param) {
            Curry._1(setInlineExists, (function (param) {
                    return isInlineElementOnThePage(undefined);
                  }));
          };
          document.addEventListener("maker-smartnav-inline-rendered", handleRender);
          return (function (param) {
                    document.removeEventListener("maker-smartnav-inline-rendered", handleRender);
                  });
        }), []);
  React.useEffect((function () {
          if (!Shopify$Showside.isProductPage(undefined)) {
            PictureInPicture2SessionStorage$Showside.clearLastCategory(undefined);
          }
          
        }), []);
  var defaultInitialRoute = embedType === /* Launcher */2 || embedType === /* Section */3 ? ({
        TAG: /* Home */0,
        _0: {
          homeType: /* Index */0,
          filters: undefined,
          sort: undefined
        }
      }) : undefined;
  if (match[0] && !allowMultipleEmbeds) {
    return null;
  }
  var tmp = {
    embedType: embedType,
    configId: configId
  };
  if (onOpenLink !== undefined) {
    tmp.onOpenLink = Caml_option.valFromOption(onOpenLink);
  }
  if (attributes !== undefined) {
    tmp.attributes = Caml_option.valFromOption(attributes);
  }
  var tmp$1 = {
    configId: configId,
    embedType: embedType,
    children: React.createElement(ConfigurationProvider$Showside.make, {
          configId: configId,
          children: React.createElement(make, tmp),
          enableTrimmingTreeByReferrer: false
        })
  };
  if (defaultInitialRoute !== undefined) {
    tmp$1.defaultInitialRoute = Caml_option.valFromOption(defaultInitialRoute);
  }
  var tmp$2 = {
    children: React.createElement(CriticalCSSProvider$Showside.make, CriticalCSSProvider$Showside.makeProps(React.createElement(ExternalIntegrationProvider$Showside.make, {
                  value: {
                    isInline: false,
                    accountId: accountId,
                    trackers: trackers,
                    onAddToCart: onAddToCart,
                    onOpenLink: onOpenLink
                  },
                  children: React.createElement(ViewportContext$Showside.Provider.make, {
                        children: React.createElement(RouterProvider$Showside.make, tmp$1)
                      })
                }), undefined, undefined))
  };
  if (namespace !== undefined) {
    tmp$2.namespace = Caml_option.valFromOption(namespace);
  }
  return React.createElement(React.StrictMode, {
              children: React.createElement(LocationConfig$Showside.Provider.make, tmp$2)
            });
}

var Entrypoint = {
  make: Entrypoint$Entrypoint
};

function render(configId, onOpenLink, onAddToCart, trackers, isLauncher, element, attributes) {
  var elementId = element.id;
  var newDictEntry = [[
      elementId,
      element
    ]];
  var embedType = Belt_Option.map(attributes, (function (attr) {
          return attr.embedType;
        }));
  var attributes$1 = Belt_Option.map(attributes, (function (attr) {
          return attr.attributes;
        }));
  var embedType$1 = Belt_Option.getWithDefault(embedType, isLauncher ? /* Launcher */2 : /* Bottombar */0);
  var elements;
  if (embedType$1 >= 2) {
    switch (embedType$1) {
      case /* Launcher */2 :
          elements = window.smartNavElementsLauncher;
          break;
      case /* Section */3 :
          elements = window.smartNavElementsSection;
          break;
      case /* Menu */4 :
          elements = window.smartNavElements;
          break;
      
    }
  } else {
    elements = window.smartNavElements;
  }
  var setElement;
  var exit = 0;
  if (embedType$1 >= 2) {
    switch (embedType$1) {
      case /* Launcher */2 :
          setElement = (function (prim0, prim1) {
              prim0.smartNavElementsLauncher = prim1;
            });
          break;
      case /* Section */3 :
          setElement = (function (prim0, prim1) {
              prim0.smartNavElementsSection = prim1;
            });
          break;
      case /* Menu */4 :
          exit = 1;
          break;
      
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    setElement = (function (prim0, prim1) {
        prim0.smartNavElements = prim1;
      });
  }
  try {
    Belt_Option.forEach(Belt_Option.keep(elements, (function (param) {
                return embedType$1 !== /* Section */3;
              })), (function (smartNavElements) {
            Belt_Option.forEach(Js_dict.get(smartNavElements, elementId), (function (prim) {
                    ReactDom.unmountComponentAtNode(prim);
                  }));
          }));
  }
  catch (exn){
    
  }
  Curry._2(setElement, window, Belt_Option.mapWithDefault(elements, Js_dict.fromArray(newDictEntry), (function (elements) {
              return Js_dict.fromArray(Belt_Array.concat(Js_dict.entries(elements), newDictEntry));
            })));
  var tmp = {
    configId: configId,
    embedType: embedType$1,
    element: element,
    key: configId
  };
  if (attributes$1 !== undefined) {
    tmp.attributes = Caml_option.valFromOption(attributes$1);
  }
  if (trackers !== undefined) {
    tmp.trackers = Caml_option.valFromOption(trackers);
  }
  if (onAddToCart !== undefined) {
    tmp.onAddToCart = Caml_option.valFromOption(onAddToCart);
  }
  if (onOpenLink !== undefined) {
    tmp.onOpenLink = Caml_option.valFromOption(onOpenLink);
  }
  ReactDom.render(React.createElement(Entrypoint$Entrypoint, tmp), element);
}

function goTo(quickView, quickNav, replaceState, preserveScroll, namespace) {
  Location$Showside.goTo(namespace, undefined, quickView, quickNav, replaceState, preserveScroll, undefined);
}

async function initSmartnav(index, element) {
  var attributes = EmbedTypes$Showside.Attributes.fromElement(index, element);
  var match = element.id;
  var embedType;
  switch (match) {
    case "maker-smartnav-launcher" :
        embedType = /* Launcher */2;
        break;
    case "maker-smartnav-section" :
        embedType = /* Section */3;
        break;
    default:
      embedType = /* Bottombar */0;
  }
  var configId = await ConfigIdGetter$Showside.getConfigId(undefined, undefined, attributes, embedType);
  var match$1 = Shopify$Showside.getShop(undefined);
  if (configId !== undefined) {
    return render(configId, undefined, undefined, undefined, embedType === /* Launcher */2, element, {
                embedType: embedType,
                attributes: attributes
              });
  } else if (match$1 !== undefined) {
    ReactDom.render(React.createElement(EntrypointConfigIdProvider$Showside.make, {
              render: (function (configId, accountId) {
                  return React.createElement(Entrypoint$Entrypoint, {
                              configId: configId,
                              embedType: embedType,
                              attributes: attributes,
                              element: element
                            });
                })
            }), element);
    return ;
  } else {
    return ;
  }
}

function init(param) {
  var elements = getElements(undefined);
  if (elements.length !== 0) {
    return Belt_Array.forEachWithIndex(elements, (function (index, value) {
                  initSmartnav(index, value);
                }));
  } else {
    console.error("Could not initialize SmartNav: no element found");
    return ;
  }
}

export {
  querySelectorAll ,
  getElements ,
  Content ,
  Entrypoint ,
  render ,
  goTo ,
  initSmartnav ,
  init ,
}
/* make Not a pure module */
