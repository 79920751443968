// Generated by ReScript, PLEASE EDIT WITH CARE


function make(arr) {
  var result = "";
  for(var i = 0 ,i_finish = arr.length; i < i_finish; ++i){
    var name = arr[i];
    if (name !== "") {
      result = result === "" ? name : result + " " + name;
    }
    
  }
  return result;
}

export {
  make ,
}
/* No side effect */
