// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as UseSafeTimeout from "../../../../node_modules/@makerinc/use-safe-timeout/lib/es6_global/src/UseSafeTimeout.bs.js";
import * as RouterProvider$Showside from "./RouterProvider.bs.js";

function initialValue_onMainImageRendered(param) {
  
}

var initialValue = {
  isMainImageRendered: false,
  onMainImageRendered: initialValue_onMainImageRendered
};

var context = React.createContext(initialValue);

var provider = context.Provider;

function LCPContext$Provider(Props) {
  var children = Props.children;
  RouterProvider$Showside.useRouter(undefined);
  var match = UseSafeTimeout.useSafeTimeout(undefined, undefined);
  var setMainImageRenderedTimeout = match.setTimeout;
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsMainImageRendered = match$1[1];
  var isMainImageRendered = match$1[0];
  var handleMainImageRendered = React.useCallback((function (param) {
          if (!isMainImageRendered) {
            ((performance.mark && performance.mark('main image rendered')));
          }
          setMainImageRenderedTimeout((function (param) {
                  Curry._1(setIsMainImageRendered, (function (param) {
                          return true;
                        }));
                }), 150);
        }), []);
  var value = React.useMemo((function () {
          return {
                  isMainImageRendered: isMainImageRendered,
                  onMainImageRendered: handleMainImageRendered
                };
        }), [
        isMainImageRendered,
        handleMainImageRendered
      ]);
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Provider = {
  provider: provider,
  make: LCPContext$Provider
};

function use(param) {
  return React.useContext(context);
}

export {
  initialValue ,
  context ,
  Provider ,
  use ,
}
/* context Not a pure module */
