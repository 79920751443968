// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Parsecurrency from "parsecurrency";

function parserlib(a) {
  try {
    return Parsecurrency.default(a);
  }
  catch (exn){
    return Parsecurrency(a);
  }
}

function parse(str) {
  var res = parserlib(str);
  var res$1 = (res == null) ? undefined : Caml_option.some(res);
  if (res == null) {
    return res$1;
  } else if (res.currency === "" && res.symbol === "$") {
    return {
            value: res.value,
            currency: "USD",
            symbol: res.symbol
          };
  } else if (res.currency === "" && res.symbol === "₹") {
    return {
            value: res.value,
            currency: "INR",
            symbol: res.symbol
          };
  } else if (res.currency === "") {
    return ;
  } else {
    return res$1;
  }
}

function format(parseResult) {
  var match = parseResult.currency;
  var tmp = match === "DKK" ? "de-DK" : "en-US";
  return parseResult.value.toLocaleString(tmp, {
              style: "currency",
              currency: parseResult.currency,
              minimumFractionDigits: parseResult.value === Math.round(parseResult.value) ? 0 : 2
            });
}

export {
  parserlib ,
  parse ,
  format ,
}
/* parsecurrency Not a pure module */
