// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as MutationObserver$Showside from "./MutationObserver.bs.js";
import * as RefByQuerySelector$Showside from "./RefByQuerySelector.bs.js";

function useInnerTextExtractor(querySelector, param) {
  var el = RefByQuerySelector$Showside.use(undefined, querySelector);
  var getInnerText = function (param) {
    var el$1 = el.current;
    if (!(el$1 == null)) {
      return el$1.innerText;
    }
    
  };
  var match = React.useState(function () {
        return getInnerText(undefined);
      });
  var setInnerText = match[1];
  var updateInnerText = function (param) {
    Curry._1(setInnerText, (function (param) {
            return getInnerText(undefined);
          }));
  };
  MutationObserver$Showside.use(undefined, el, updateInnerText);
  React.useEffect((function () {
          Curry._1(setInnerText, (function (param) {
                  return getInnerText(undefined);
                }));
        }), []);
  return match[0];
}

export {
  useInnerTextExtractor ,
}
/* react Not a pure module */
