// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mem from "mem";
import * as ManyKeysMap from "many-keys-map";

function exec(a, b) {
  try {
    return Mem.default(a, b);
  }
  catch (exn){
    return Mem(a, b);
  }
}

var newManyKeysMap = (function newManyKeysMap(manyKeysMap) {
    if (manyKeysMap && manyKeysMap.default) {
      return new manyKeysMap.default();
    }

    return new manyKeysMap();
  });

var defaultCacheKey = (function cacheKey(...args) {
    return args;
  });

function mem(input) {
  return exec(input, {
              cacheKey: defaultCacheKey,
              cache: newManyKeysMap(ManyKeysMap)
            });
}

function memWithCacheKey(input, cacheKey) {
  return exec(input, {
              cacheKey: cacheKey,
              cache: newManyKeysMap(ManyKeysMap)
            });
}

export {
  exec ,
  newManyKeysMap ,
  defaultCacheKey ,
  mem ,
  memWithCacheKey ,
}
/* mem Not a pure module */
