// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Generic$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/utils/Generic.bs.js";

function encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "name",
                Curry._1(Generic$ApiTypes.Encode.string, param.name)
              ],
              [
                "value",
                Curry._1(Generic$ApiTypes.Encode.string, param.value)
              ]
            ]);
}

var decode = Curry._3(Generic$ApiTypes.Decode.map2, Generic$ApiTypes.Decode.fieldWithDefault("name", Generic$ApiTypes.Decode.string, ""), Generic$ApiTypes.Decode.fieldWithDefault("value", Generic$ApiTypes.Decode.string, ""), (function (name, value) {
        return {
                name: name,
                value: value
              };
      }));

var OptionsWithValues = {
  encode: encode,
  decode: decode
};

function encode$1(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "product_id",
                Curry._1(Generic$ApiTypes.Encode.$$float, Number(param.productId))
              ],
              [
                "variant_id",
                Curry._1(Generic$ApiTypes.Encode.$$float, Number(param.variantId))
              ],
              [
                "quantity",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.quantity)
              ],
              [
                "title",
                Curry._1(Generic$ApiTypes.Encode.string, param.title)
              ],
              [
                "image",
                Generic$ApiTypes.Encode.nullable(param.image, Generic$ApiTypes.Encode.string)
              ],
              [
                "url",
                Generic$ApiTypes.Encode.nullable(param.url, Generic$ApiTypes.Encode.string)
              ],
              [
                "options_with_values",
                Curry._2(Generic$ApiTypes.Encode.array, param.optionsWithValues, encode)
              ],
              [
                "maker_category_id",
                Generic$ApiTypes.Encode.nullable(param.makerCategoryId, Generic$ApiTypes.Encode.string)
              ]
            ]);
}

var decode$1 = Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._2(Generic$ApiTypes.Decode.andMap, Curry._1(Generic$ApiTypes.Decode.succeed, (function (productId, variantId, quantity, title, image, url, optionsWithValues, makerCategoryId) {
                                        return {
                                                productId: productId.toString(),
                                                variantId: variantId.toString(),
                                                quantity: quantity,
                                                title: title,
                                                image: image,
                                                url: url,
                                                optionsWithValues: optionsWithValues,
                                                makerCategoryId: makerCategoryId
                                              };
                                      })), Generic$ApiTypes.Decode.fieldWithDefault("product_id", Generic$ApiTypes.Decode.$$float, 0.0)), Generic$ApiTypes.Decode.fieldWithDefault("variant_id", Generic$ApiTypes.Decode.$$float, 0.0)), Generic$ApiTypes.Decode.fieldWithDefault("quantity", Generic$ApiTypes.Decode.$$int, 0)), Generic$ApiTypes.Decode.fieldWithDefault("title", Generic$ApiTypes.Decode.string, "")), Curry._2(Generic$ApiTypes.Decode.field, "image", Curry._1(Generic$ApiTypes.Decode.nullable, Generic$ApiTypes.Decode.string))), Curry._2(Generic$ApiTypes.Decode.field, "url", Curry._1(Generic$ApiTypes.Decode.nullable, Generic$ApiTypes.Decode.string))), Generic$ApiTypes.Decode.fieldWithDefault("options_with_values", Curry._1(Generic$ApiTypes.Decode.array, decode), [])), Generic$ApiTypes.Decode.fieldWithDefault("maker_category_id", Curry._1(Generic$ApiTypes.Decode.nullable, Generic$ApiTypes.Decode.string), undefined));

var Item = {
  encode: encode$1,
  decode: decode$1
};

var t_decode = Curry._4(Generic$ApiTypes.Decode.map3, Generic$ApiTypes.Decode.fieldWithDefault("item_count", Generic$ApiTypes.Decode.$$int, 0), Generic$ApiTypes.Decode.fieldWithDefault("items", Curry._1(Generic$ApiTypes.Decode.array, decode$1), []), Generic$ApiTypes.Decode.fieldWithDefault("currency", Generic$ApiTypes.Decode.string, ""), (function (itemCount, items, currency) {
        return {
                itemCount: itemCount,
                items: items,
                currency: currency
              };
      }));

function t_encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [
              [
                "item_count",
                Curry._1(Generic$ApiTypes.Encode.$$int, param.itemCount)
              ],
              [
                "items",
                Curry._2(Generic$ApiTypes.Encode.array, param.items, encode$1)
              ],
              [
                "currency",
                Curry._1(Generic$ApiTypes.Encode.string, param.currency)
              ]
            ]);
}

var empty_items = [];

var empty = {
  itemCount: 0,
  items: empty_items,
  currency: "USD"
};

export {
  OptionsWithValues ,
  Item ,
  t_decode ,
  t_encode ,
  empty ,
}
/* decode Not a pure module */
