// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_SortArray from "../../../../node_modules/rescript/lib/es6/belt_SortArray.js";

function toInt(columns) {
  switch (columns) {
    case /* OneColumnGrid */0 :
        return 1;
    case /* TwoColumnGrid */1 :
        return 2;
    case /* ThreeColumnGrid */2 :
        return 3;
    case /* FourColumnGrid */3 :
        return 4;
    case /* FiveColumnGrid */4 :
        return 5;
    case /* SixColumnGrid */5 :
        return 6;
    case /* SevenColumnGrid */6 :
        return 7;
    case /* EightColumnGrid */7 :
        return 8;
    case /* TwelveColumnGrid */8 :
        return 12;
    
  }
}

function fromInt(columns) {
  switch (columns) {
    case 2 :
        return /* TwoColumnGrid */1;
    case 3 :
        return /* ThreeColumnGrid */2;
    case 4 :
        return /* FourColumnGrid */3;
    case 5 :
        return /* FiveColumnGrid */4;
    case 6 :
        return /* SixColumnGrid */5;
    case 7 :
        return /* SevenColumnGrid */6;
    case 8 :
        return /* EightColumnGrid */7;
    case 1 :
    case 9 :
    case 10 :
    case 11 :
        return /* OneColumnGrid */0;
    case 12 :
        return /* TwelveColumnGrid */8;
    default:
      return /* OneColumnGrid */0;
  }
}

function minColumns(columns, $$default) {
  if (columns >= $$default) {
    return columns;
  } else {
    return $$default;
  }
}

function maxColumns(columns, $$default) {
  if (columns < $$default) {
    return columns;
  } else {
    return $$default;
  }
}

var GridColumns = {
  toInt: toInt,
  fromInt: fromInt,
  minColumns: minColumns,
  maxColumns: maxColumns
};

function layoutToString(layout) {
  if (layout.TAG === /* SliderLayout */0) {
    return "slider";
  } else {
    return "grid";
  }
}

function getCardShapeAspectRatio(cardShape) {
  if (typeof cardShape !== "number") {
    return [
            cardShape._0,
            cardShape._1
          ];
  }
  switch (cardShape) {
    case /* Circle */0 :
    case /* Square */1 :
        return [
                1,
                1
              ];
    case /* Landscape */2 :
        return [
                4,
                3
              ];
    case /* Portrait */3 :
        return [
                2,
                3
              ];
    case /* Panorama */4 :
        return [
                21,
                9
              ];
    case /* Wide */5 :
        return [
                16,
                9
              ];
    case /* Tall */6 :
        return [
                9,
                16
              ];
    
  }
}

function getClosestCardShapeByAspectRatio(width, height) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.getBy(Belt_SortArray.stableSortBy(Belt_Array.map([
                              /* Square */1,
                              /* Landscape */2,
                              /* Portrait */3,
                              /* Panorama */4,
                              /* Wide */5,
                              /* Tall */6
                            ], (function (shape) {
                                var match = getCardShapeAspectRatio(shape);
                                return [
                                        shape,
                                        match[0] / match[1]
                                      ];
                              })), (function (param, param$1) {
                            var ratioB = param$1[1];
                            var ratioA = param[1];
                            if (ratioA === ratioB) {
                              return 0;
                            } else if (ratioA < ratioB) {
                              return -1;
                            } else {
                              return 1;
                            }
                          })), (function (param) {
                        var lookupRatio = width / height;
                        return param[1] >= lookupRatio;
                      })), (function (param) {
                    return param[0];
                  })), /* Panorama */4);
}

function getClosestCardShape(shape) {
  var param = getCardShapeAspectRatio(shape);
  return getClosestCardShapeByAspectRatio(param[0], param[1]);
}

function toCardShape(_shape, _fallback) {
  while(true) {
    var fallback = _fallback;
    var shape = _shape;
    if (typeof shape === "number") {
      switch (shape) {
        case /* Default */0 :
            _fallback = /* Square */6;
            _shape = fallback;
            continue ;
        case /* Landscape */1 :
            return /* Landscape */2;
        case /* Portrait */2 :
            return /* Portrait */3;
        case /* Panorama */3 :
            return /* Panorama */4;
        case /* Wide */4 :
            return /* Wide */5;
        case /* Tall */5 :
            return /* Tall */6;
        case /* Square */6 :
            return /* Square */1;
        case /* Circle */7 :
            return /* Circle */0;
        
      }
    } else {
      var custom = shape._0;
      return /* Custom */{
              _0: custom.width,
              _1: custom.height
            };
    }
  };
}

function toCardShapeWithResolvedCustom(shape, fallback) {
  if (typeof shape === "number") {
    switch (shape) {
      case /* Default */0 :
          return toCardShape(fallback, /* Square */6);
      case /* Landscape */1 :
          return /* Landscape */2;
      case /* Portrait */2 :
          return /* Portrait */3;
      case /* Panorama */3 :
          return /* Panorama */4;
      case /* Wide */4 :
          return /* Wide */5;
      case /* Tall */5 :
          return /* Tall */6;
      case /* Square */6 :
          return /* Square */1;
      case /* Circle */7 :
          return /* Circle */0;
      
    }
  } else {
    var custom = shape._0;
    return getClosestCardShapeByAspectRatio(custom.width, custom.height);
  }
}

var CardShape = {
  getCardShapeAspectRatio: getCardShapeAspectRatio,
  getClosestCardShapeByAspectRatio: getClosestCardShapeByAspectRatio,
  getClosestCardShape: getClosestCardShape,
  toCardShape: toCardShape,
  toCardShapeWithResolvedCustom: toCardShapeWithResolvedCustom
};

var emptyGridPayload = {
  columns: /* FourColumnGrid */3,
  gap: /* Equal */0,
  maxRows: undefined,
  maxWidth: undefined,
  padding: undefined,
  pattern: undefined
};

var emptySliderPayload = {
  slidesPerView: 6.2,
  gap: /* Equal */0,
  maxWidth: undefined,
  padding: undefined,
  rows: 1,
  effect: /* EffectSlide */0,
  nav: /* NavAuto */0,
  transition: /* TransitionEase */0,
  transitionSpeed: 300,
  autoplay: false,
  autoplayDelay: 3000,
  loop: false
};

export {
  GridColumns ,
  emptyGridPayload ,
  emptySliderPayload ,
  layoutToString ,
  CardShape ,
}
/* No side effect */
