


function int_compare(x, y) {
  if (x < y) {
    return -1;
  } else if (x === y) {
    return 0;
  } else {
    return 1;
  }
}

function bool_compare(x, y) {
  if (x) {
    if (y) {
      return 0;
    } else {
      return 1;
    }
  } else if (y) {
    return -1;
  } else {
    return 0;
  }
}

function float_compare(x, y) {
  if (x === y) {
    return 0;
  } else if (x < y) {
    return -1;
  } else if (x > y || x === x) {
    return 1;
  } else if (y === y) {
    return -1;
  } else {
    return 0;
  }
}

function string_compare(s1, s2) {
  if (s1 === s2) {
    return 0;
  } else if (s1 < s2) {
    return -1;
  } else {
    return 1;
  }
}

function bool_min(x, y) {
  if (x) {
    return y;
  } else {
    return x;
  }
}

function int_min(x, y) {
  if (x < y) {
    return x;
  } else {
    return y;
  }
}

function float_min(x, y) {
  if (x < y) {
    return x;
  } else {
    return y;
  }
}

function string_min(x, y) {
  if (x < y) {
    return x;
  } else {
    return y;
  }
}

function bool_max(x, y) {
  if (x) {
    return x;
  } else {
    return y;
  }
}

function int_max(x, y) {
  if (x > y) {
    return x;
  } else {
    return y;
  }
}

function float_max(x, y) {
  if (x > y) {
    return x;
  } else {
    return y;
  }
}

function string_max(x, y) {
  if (x > y) {
    return x;
  } else {
    return y;
  }
}

function i64_eq(x, y) {
  if (x[1] === y[1]) {
    return x[0] === y[0];
  } else {
    return false;
  }
}

function i64_ge(param, param$1) {
  var other_hi = param$1[0];
  var hi = param[0];
  if (hi > other_hi) {
    return true;
  } else if (hi < other_hi) {
    return false;
  } else {
    return param[1] >= param$1[1];
  }
}

function i64_neq(x, y) {
  return !i64_eq(x, y);
}

function i64_lt(x, y) {
  return !i64_ge(x, y);
}

function i64_gt(x, y) {
  if (x[0] > y[0]) {
    return true;
  } else if (x[0] < y[0]) {
    return false;
  } else {
    return x[1] > y[1];
  }
}

function i64_le(x, y) {
  return !i64_gt(x, y);
}

function i64_min(x, y) {
  if (i64_ge(x, y)) {
    return y;
  } else {
    return x;
  }
}

function i64_max(x, y) {
  if (i64_gt(x, y)) {
    return x;
  } else {
    return y;
  }
}

export {
  int_compare ,
  bool_compare ,
  float_compare ,
  string_compare ,
  bool_min ,
  int_min ,
  float_min ,
  string_min ,
  bool_max ,
  int_max ,
  float_max ,
  string_max ,
  i64_eq ,
  i64_neq ,
  i64_lt ,
  i64_gt ,
  i64_le ,
  i64_ge ,
  i64_min ,
  i64_max ,
}
/* No side effect */
