// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";

function use(value, def) {
  var ref = React.useRef(def);
  React.useEffect((function () {
          ref.current = value;
        }), [value]);
  return ref.current;
}

function usePreviousDistinct(value) {
  var a = React.useRef(value);
  var b = React.useRef(value);
  if (Caml_obj.notequal(b.current, value)) {
    a.current = b.current;
    b.current = value;
  }
  return a.current;
}

function usePreviousSome(value) {
  var lastSome = React.useRef(value);
  React.useEffect((function () {
          lastSome.current = value !== undefined ? Caml_option.some(Caml_option.valFromOption(value)) : lastSome.current;
        }), [value]);
  return Belt_Option.orElse(value, lastSome.current);
}

function useStateWithPrevious(initialValue) {
  var previous = React.useRef(Curry._1(initialValue, undefined));
  var match = React.useState(function () {
        return Curry._1(initialValue, undefined);
      });
  var setState = match[1];
  var setState$1 = function (setterFunction) {
    Curry._1(setState, (function (prev) {
            previous.current = prev;
            return Curry._1(setterFunction, prev);
          }));
  };
  return [
          match[0],
          setState$1,
          previous.current
        ];
}

export {
  use ,
  usePreviousDistinct ,
  usePreviousSome ,
  useStateWithPrevious ,
}
/* react Not a pure module */
