// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "../../../../node_modules/rescript/lib/es6/js_string.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Product$Showside from "../types/Product.bs.js";
import * as Shopify$Showside from "./Shopify.bs.js";
import * as EventTypes$Showside from "../types/EventTypes.bs.js";
import * as Fingerprint$Showside from "./Fingerprint.bs.js";
import * as FirebaseConfig$Showside from "./FirebaseConfig.bs.js";
import * as GoogleAnalytics$Showside from "./GoogleAnalytics.bs.js";

function makeItemViewed(product, variant, affiliation, configId, link, isInline, isNavMenu, $$event) {
  var match = Product$Showside.Variant.getVariantPrices(variant);
  var price = match[0];
  return {
          isInline: isInline,
          isNavMenu: isNavMenu,
          navVersion: "v2",
          affiliation: affiliation,
          parentUrl: link,
          sheetId: configId,
          currency: product.currency,
          value: price,
          non_interaction: EventTypes$Showside.isNonInteractive($$event),
          items: [{
              affiliation: affiliation,
              id: product.id,
              name: product.name,
              price: price,
              quantity: 1,
              variant: product.id
            }]
        };
}

var initializeApp = (function init(options) {
    return import('firebase/app')
    .then(({ initializeApp }) =>
      initializeApp(options)
    ).then(app =>
      import('firebase/analytics')
      .then(functions =>
        [app, functions]
      )
    );
  });

function getAnalyticsOptions(param) {
  var cookie = Shopify$Showside.initialDocumentCookie(undefined);
  var partial_arg = /_shopify_sa_p=(.*)ref%3Dmkr%257C([a-z0-9.]+)%257C([a-z0-9.]+)/;
  var matches = Belt_Option.getWithDefault(Belt_Option.flatMap(cookie, (function (param) {
              return Js_string.match_(partial_arg, param);
            })), []);
  var clientId = Belt_Array.get(matches, 2);
  var sessionId = Belt_Array.get(matches, 3);
  if (clientId === undefined) {
    return ;
  }
  var clientId$1 = Caml_option.valFromOption(clientId);
  if (clientId$1 === undefined) {
    return ;
  }
  if (sessionId === undefined) {
    return ;
  }
  var sessionId$1 = Caml_option.valFromOption(sessionId);
  if (sessionId$1 !== undefined) {
    return {
            config: {
              client_id: clientId$1,
              session_id: sessionId$1
            }
          };
  }
  
}

function init(param) {
  var match = window.gtag;
  if (match !== undefined) {
    
  } else {
    GoogleAnalytics$Showside.Gtag.insertScript(FirebaseConfig$Showside.config.measurementId);
  }
  window.smartnavOriginalGtag = window.gtag;
  return Promise.all([
                initializeApp(FirebaseConfig$Showside.config),
                Fingerprint$Showside.getVisitorId(undefined)
              ]).then(function (param) {
              var match = param[0];
              var functions = match[1];
              var instance = functions.initializeAnalytics(match[0], getAnalyticsOptions(undefined));
              functions.setUserId(instance, param[1]);
              return [
                      functions,
                      instance
                    ];
            });
}

function logEvent(param, $$event, options) {
  param[0].logEvent(param[1], $$event, options);
}

function maybeLogEvent(firebase, $$event, options) {
  if (firebase !== undefined) {
    return logEvent(firebase, $$event, options);
  } else {
    console.log("log event", $$event, options);
    return ;
  }
}

function logValueEvent(param, $$event, options, value) {
  param[0].logEvent(param[1], $$event, options, value);
}

function maybeLogValueEvent(firebase, $$event, options, value) {
  if (firebase !== undefined) {
    return logValueEvent(firebase, $$event, options, value);
  } else {
    console.log("log event", $$event, options, value);
    return ;
  }
}

export {
  makeItemViewed ,
  initializeApp ,
  getAnalyticsOptions ,
  init ,
  logEvent ,
  maybeLogEvent ,
  logValueEvent ,
  maybeLogValueEvent ,
}
/* Product-Showside Not a pure module */
