// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Js_string from "../../../../node_modules/rescript/lib/es6/js_string.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as URLUtils$Showside from "./URLUtils.bs.js";
import * as EventTypes$Showside from "../types/EventTypes.bs.js";
import * as Fingerprint$Showside from "./Fingerprint.bs.js";
import * as FirebaseConfig$Showside from "./FirebaseConfig.bs.js";

var Tracker = {};

var isGaFunction = (function isGaFunction() {
        return typeof window.ga === 'function';
      });

function getTrackers(param) {
  return Belt_Array.reduce(Belt_Option.mapWithDefault(window.ga, [], (function (ga) {
                    var match = ga.getAll;
                    if (match !== undefined) {
                      return ga.getAll();
                    } else {
                      return [];
                    }
                  })), [], (function (acc, tracker) {
                var trackingId = Belt_Option.flatMap(Belt_Option.map((tracker == null) ? undefined : Caml_option.some(tracker), (function (__x) {
                            return __x.get("trackingId");
                          })), (function (prim) {
                        if (prim == null) {
                          return ;
                        } else {
                          return Caml_option.some(prim);
                        }
                      }));
                var found = Belt_Array.some(acc, (function (t) {
                        return Caml_obj.equal(Caml_option.nullable_to_opt(t.get("trackingId")), trackingId);
                      }));
                if (found || (tracker == null)) {
                  return acc;
                } else {
                  return Belt_Array.concat(acc, [tracker]);
                }
              }));
}

var initScript = (function() {
      window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
    });

function insertScript(param) {
  Curry._1(initScript, undefined);
  var script = document.createElement("script");
  script.setAttribute("src", "https://www.google-analytics.com/analytics.js");
  script.setAttribute("async", "true");
  Belt_Option.forEach(Caml_option.nullable_to_opt(document.querySelector("head")), (function (param) {
          param.appendChild(script);
        }));
}

function track(account, $$event, category, action, label, value, param) {
  if (Belt_Option.mapWithDefault(account, false, (function (a) {
            return a.eventsPostmessage;
          }))) {
    return ;
  }
  Belt_Array.forEach(getTrackers(undefined), (function (tracker) {
          tracker.send("event", category, action, label, value, {
                nonInteraction: EventTypes$Showside.isNonInteractive($$event)
              });
        }));
  var match = getTrackers(undefined).length === 0;
  var match$1 = Curry._1(isGaFunction, undefined);
  var match$2 = window.ga;
  if (match && match$1 && match$2 !== undefined) {
    return match$2("send", "event", category, action, label, value, {
                nonInteraction: EventTypes$Showside.isNonInteractive($$event)
              });
  }
  
}

function setUserId(account) {
  return Fingerprint$Showside.getVisitorId(undefined).then(function (visitorId) {
              var match = window.ga;
              var match$1 = account.useCustomDimensionForFingerprinting;
              if (match !== undefined) {
                if (match$1) {
                  return match("set", "maker_user_id", visitorId);
                } else {
                  return match("set", "userId", visitorId);
                }
              }
              
            });
}

function init(gaTrackingId, sendPageViewOpt, account) {
  var sendPageView = sendPageViewOpt !== undefined ? sendPageViewOpt : false;
  var match = window.ga;
  if (match !== undefined) {
    
  } else {
    insertScript(undefined);
  }
  var match$1 = window.ga;
  var match$2 = window.ga;
  if (match$1 !== undefined && match$2 !== undefined) {
    match$1("create", gaTrackingId, "auto", {
          cookieFlags: "max-age=7200;secure;samesite=none"
        });
    if (sendPageView) {
      match$2("send", "pageview");
    }
    return setUserId(account);
  } else {
    return Promise.resolve(undefined);
  }
}

var clientIdRef = {
  contents: undefined
};

function getClientId(param) {
  var match = clientIdRef.contents;
  var match$1 = window.ga;
  if (match !== undefined) {
    return Promise.resolve(match);
  }
  if (match$1 === undefined) {
    return Promise.resolve(undefined);
  }
  var clientId = Belt_Array.reduce(Caml_option.valFromOption(match$1).getAll(), undefined, (function (acc, tracker) {
          if (acc !== undefined) {
            return acc;
          }
          if (tracker == null) {
            return acc;
          }
          var linkerParam = tracker.get("linkerParam");
          var clientId = tracker.get("clientId");
          var clientId$1 = (linkerParam == null) ? (
              !(clientId == null) && clientId !== "" && !(clientId == null) ? Caml_option.some(clientId) : undefined
            ) : (
              linkerParam !== "" && !(linkerParam == null) ? Caml_option.some(linkerParam) : undefined
            );
          return Belt_Option.map(clientId$1, (function (param) {
                        return Js_string.replace("_ga=", "", param);
                      }));
        }));
  clientIdRef.contents = clientId;
  return Promise.resolve(clientId);
}

function getLinkerParam(param) {
  return getClientId(undefined).then(function (value) {
              if (value !== undefined) {
                return [
                        "_ga",
                        value
                      ];
              }
              
            });
}

var Linker = {
  clientIdRef: clientIdRef,
  getClientId: getClientId,
  getLinkerParam: getLinkerParam
};

var Ga = {
  Tracker: Tracker,
  isGaFunction: isGaFunction,
  getTrackers: getTrackers,
  initScript: initScript,
  insertScript: insertScript,
  track: track,
  setUserId: setUserId,
  init: init,
  Linker: Linker
};

var jsonStringifyExn = (function jsonStringify(input) {
      return JSON.stringify(input);
    });

var initScript$1 = (function() {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function (){dataLayer.push(arguments)};
    });

function sendEvent(account, callback, $$event, properties, param) {
  var match = window.gtag;
  if (match !== undefined) {
    
  } else {
    Curry._1(initScript$1, undefined);
  }
  var match$1 = Belt_Option.mapWithDefault(account, false, (function (a) {
          return a.eventsPostmessage;
        }));
  var match$2 = window.gtag;
  var match$3 = window.gtag;
  if (!match$1) {
    if (callback !== undefined) {
      if (match$2 !== undefined) {
        return match$2("event", $$event, properties, Caml_option.valFromOption(callback));
      } else {
        return ;
      }
    } else if (match$3 !== undefined) {
      return match$3("event", $$event, properties);
    } else {
      return ;
    }
  }
  try {
    var data = jsonStringifyExn({
          smartnav: true,
          event: $$event,
          ecommerce: properties
        });
    window.postMessage(data, "*");
    return ;
  }
  catch (exn){
    return ;
  }
}

function track$1(account, gaTrackingId, $$event, category, action, label, value, param) {
  sendEvent(account, undefined, action, {
        event_category: category,
        event_label: label,
        value: value,
        non_interaction: EventTypes$Showside.isNonInteractive($$event),
        send_to: gaTrackingId
      }, undefined);
}

function insertScript$1(id) {
  Curry._1(initScript$1, undefined);
  var script = document.createElement("script");
  script.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + id);
  script.setAttribute("async", "true");
  Belt_Option.forEach(Caml_option.nullable_to_opt(document.querySelector("head")), (function (param) {
          param.appendChild(script);
        }));
}

function setUserId$1(account) {
  var originalGtag = Belt_Option.map(window.smartnavOriginalGtag, (function (prim) {
          return prim;
        }));
  return Fingerprint$Showside.getVisitorId(undefined).then(function (visitorId) {
              var match = window.gtag;
              var match$1 = account.useCustomDimensionForFingerprinting;
              var exit = 0;
              var gtag2;
              var gtag2$1;
              if (originalGtag !== undefined) {
                if (match$1) {
                  gtag2$1 = originalGtag;
                  exit = 2;
                } else {
                  gtag2 = originalGtag;
                  exit = 1;
                }
              } else {
                if (match === undefined) {
                  return ;
                }
                if (match$1) {
                  gtag2$1 = match;
                  exit = 2;
                } else {
                  gtag2 = match;
                  exit = 1;
                }
              }
              switch (exit) {
                case 1 :
                    return gtag2("set", {
                                user_id: visitorId,
                                maker_user_id: undefined
                              });
                case 2 :
                    return gtag2$1("set", {
                                maker_user_id: visitorId,
                                user_id: undefined
                              });
                
              }
            });
}

function init$1(gaTrackingId, checkoutLink, sendPageViewOpt, account) {
  var sendPageView = sendPageViewOpt !== undefined ? sendPageViewOpt : false;
  var domain = Belt_Option.mapWithDefault(Belt_Option.map(Belt_Option.flatMap(checkoutLink, URLUtils$Showside.maybeMakeUrl), (function (prim) {
              return prim.hostname;
            })), [], (function (domain) {
          return [domain];
        }));
  var match = window.gtag;
  if (match !== undefined || gaTrackingId === undefined) {
    
  } else {
    insertScript$1(gaTrackingId);
  }
  var match$1 = window.gtag;
  if (match$1 !== undefined) {
    if (gaTrackingId !== undefined) {
      match$1("config", gaTrackingId, {
            send_page_view: sendPageView,
            linker: checkoutLink !== undefined ? ({
                  domains: Belt_Array.concat(["mkr.bio"], domain)
                }) : undefined
          });
      return setUserId$1(account);
    } else {
      return setUserId$1(account);
    }
  } else {
    return Promise.resolve(undefined);
  }
}

var clientIdRef$1 = {
  contents: undefined
};

function getGtagProperty(gaTrackingId, name) {
  return new Promise((function (resolve, reject) {
                var match = window.smartnavOriginalGtag;
                var match$1 = window.gtag;
                var gtag;
                if (match !== undefined) {
                  gtag = match;
                } else {
                  if (match$1 === undefined) {
                    return reject(null);
                  }
                  gtag = match$1;
                }
                gtag("get", Belt_Option.getWithDefault(gaTrackingId, FirebaseConfig$Showside.config.measurementId), name, (function (result) {
                        resolve((result == null) ? undefined : Caml_option.some(result));
                      }));
              }));
}

function getClientId$1(gaTrackingId, gaVersion, checkoutLink, account) {
  var value = clientIdRef$1.contents;
  if (value !== undefined) {
    return Promise.resolve(value);
  } else {
    return init$1(gaTrackingId, checkoutLink, undefined, account).then(function (param) {
                  return Promise.all([
                              getGtagProperty(gaTrackingId, "client_id"),
                              getGtagProperty(gaTrackingId, "session_id")
                            ]);
                }).then(function (param) {
                var sessionId = param[1];
                var clientId = param[0];
                clientIdRef$1.contents = clientId !== undefined && sessionId !== undefined ? [
                    clientId,
                    sessionId
                  ] : undefined;
                return clientIdRef$1.contents;
              });
  }
}

var initAnalyticsJs = (function initAnalyticsJs() {
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      });

function getLinkerParam$1(gaTrackingId, gaVersion, checkoutLink, account, param) {
  var glBridge = Belt_Option.flatMap(Belt_Option.map((window.google_tag_data == null) ? undefined : Caml_option.some(window.google_tag_data), (function (prim) {
              return prim.glBridge;
            })), (function (prim) {
          if (prim == null) {
            return ;
          } else {
            return Caml_option.some(prim);
          }
        }));
  var retry = function (param) {
    return new Promise((function (resolve, reject) {
                  setTimeout((function (param) {
                          getLinkerParam$1(gaTrackingId, gaVersion, checkoutLink, account, undefined).then(function (result) {
                                resolve(result);
                              });
                        }), 42);
                }));
  };
  var match = window.GoogleAnalyticsObject;
  if (account === undefined) {
    return Promise.resolve(undefined);
  }
  if (glBridge === undefined) {
    if (match !== undefined) {
      return retry(undefined);
    } else {
      Curry._1(initAnalyticsJs, undefined);
      return retry(undefined);
    }
  }
  var glBridge$1 = Caml_option.valFromOption(glBridge);
  return getClientId$1(gaTrackingId, Caml_option.some(gaVersion), checkoutLink, account).then(function (result) {
              if (result === undefined) {
                return ;
              }
              var sessionId = result[1];
              var clientId = result[0];
              var value = Belt_Option.flatMap(Belt_Array.get(Belt_Array.keepMap(Belt_Array.map(Belt_Option.getWithDefault(Caml_option.nullable_to_opt(document.cookie), "").split(";"), (function (prim) {
                                  return prim.trim();
                                })), (function (value) {
                              var regex = new RegExp("_ga_(.*)=GS1.1.(" + sessionId + "(.*))");
                              var matches = Belt_Option.getWithDefault(Caml_option.null_to_opt(value.match(regex)), []);
                              var key = Belt_Array.get(matches, 1);
                              var value$1 = Belt_Array.get(matches, 2);
                              if (key === undefined) {
                                return ;
                              }
                              var key$1 = Caml_option.valFromOption(key);
                              if (key$1 === undefined) {
                                return ;
                              }
                              if (value$1 === undefined) {
                                return ;
                              }
                              var value$2 = Caml_option.valFromOption(value$1);
                              if (value$2 !== undefined) {
                                return [
                                        key$1,
                                        value$2
                                      ];
                              }
                              
                            })), 0), (function (param) {
                      return Caml_option.nullable_to_opt(glBridge$1.generate(Js_dict.fromArray([
                                          [
                                            "_ga",
                                            clientId
                                          ],
                                          [
                                            "_ga_" + param[0],
                                            param[1]
                                          ]
                                        ])));
                    }));
              if (value !== undefined) {
                return [
                        "_gl",
                        value
                      ];
              }
              
            });
}

var Linker$1 = {
  clientIdRef: clientIdRef$1,
  getGtagProperty: getGtagProperty,
  getClientId: getClientId$1,
  initAnalyticsJs: initAnalyticsJs,
  getLinkerParam: getLinkerParam$1
};

var Gtag = {
  jsonStringifyExn: jsonStringifyExn,
  initScript: initScript$1,
  sendEvent: sendEvent,
  track: track$1,
  insertScript: insertScript$1,
  setUserId: setUserId$1,
  init: init$1,
  Linker: Linker$1
};

function getLinkerParam$2(gaTrackingId, gaVersion, checkoutLink, account, param) {
  if (gaVersion) {
    if (gaTrackingId !== undefined) {
      return getLinkerParam$1(gaTrackingId, gaVersion, checkoutLink, account, undefined);
    } else {
      return Promise.resolve(undefined);
    }
  } else {
    return getLinkerParam(undefined);
  }
}

function track$2(account, gaTrackingId, $$event, category, action, label, value, param) {
  track$1(account, gaTrackingId, $$event, category, action, label, value, undefined);
  track(account, $$event, category, action, label, value, undefined);
}

function setUserId$2(account) {
  var match = window.gtag;
  var match$1 = window.ga;
  if (match !== undefined) {
    setUserId$1(account);
    return ;
  } else if (match$1 !== undefined) {
    setUserId(account);
    return ;
  } else {
    return ;
  }
}

function init$2(gaTrackingId, gaVersion, checkoutLink, sendPageView, account) {
  if (gaTrackingId !== undefined && gaVersion !== undefined) {
    if (gaVersion) {
      init$1(gaTrackingId, checkoutLink, sendPageView, account);
    } else {
      init(gaTrackingId, sendPageView, account);
    }
    return ;
  } else {
    return setUserId$2(account);
  }
}

export {
  Ga ,
  Gtag ,
  getLinkerParam$2 as getLinkerParam,
  track$2 as track,
  setUserId$2 as setUserId,
  init$2 as init,
}
/* Fingerprint-Showside Not a pure module */
