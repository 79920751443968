// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as CriticalCSS$Showside from "../utils/CriticalCSS.bs.js";

function useCriticalCSS(param) {
  var match = React.useState(function () {
        return false;
      });
  var setStyleLoaded = match[1];
  React.useEffect((function () {
          CriticalCSS$Showside.insertCSS((function (param) {
                  Curry._1(setStyleLoaded, (function (param) {
                          return true;
                        }));
                }), undefined);
        }), []);
  return match[0];
}

var context = React.createContext(false);

var provider = context.Provider;

function CriticalCSSProvider$Provider(Props) {
  var children = Props.children;
  var isStyleLoaded = useCriticalCSS(undefined);
  return React.createElement(provider, {
              value: isStyleLoaded,
              children: children
            });
}

var Provider = {
  provider: provider,
  make: CriticalCSSProvider$Provider
};

function useIsStyleLoaded(param) {
  return React.useContext(context);
}

function makeProps(prim0, prim1, prim2) {
  var tmp = {
    children: prim0
  };
  if (prim1 !== undefined) {
    tmp.key = prim1;
  }
  return tmp;
}

var make = CriticalCSSProvider$Provider;

export {
  useCriticalCSS ,
  context ,
  Provider ,
  useIsStyleLoaded ,
  make ,
  makeProps ,
}
/* context Not a pure module */
