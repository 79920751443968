// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Debounce from "@react-hook/debounce";

function useDebounce(leadingOpt, waitOpt, init) {
  var leading = leadingOpt !== undefined ? leadingOpt : false;
  var wait = waitOpt !== undefined ? waitOpt : 100;
  return Debounce.useDebounce(init, leading, wait);
}

export {
  useDebounce ,
}
/* @react-hook/debounce Not a pure module */
