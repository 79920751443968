// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../rescript/lib/es6/array.js";
import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Js_list from "../../../../../rescript/lib/es6/js_list.js";
import * as Belt_List from "../../../../../rescript/lib/es6/belt_List.js";
import * as Belt_Array from "../../../../../rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "../../../../../rescript/lib/es6/belt_Result.js";

function pure(v) {
  return {
          TAG: /* Success */0,
          _0: v
        };
}

function apply(selector, source, combine) {
  if (selector.TAG === /* Success */0) {
    if (source.TAG === /* Success */0) {
      return {
              TAG: /* Success */0,
              _0: Curry._1(selector._0, source._0)
            };
    } else {
      return {
              TAG: /* Failure */1,
              _0: source._0
            };
    }
  }
  var f1 = selector._0;
  if (source.TAG === /* Success */0) {
    return {
            TAG: /* Failure */1,
            _0: f1
          };
  } else {
    return {
            TAG: /* Failure */1,
            _0: Curry._2(combine, f1, source._0)
          };
  }
}

function traverse(arr, fn, combine) {
  var list = $$Array.to_list(arr);
  var cons = function (head, tail) {
    return {
            hd: head,
            tl: tail
          };
  };
  var initState = {
    TAG: /* Success */0,
    _0: /* [] */0
  };
  var folder = function (head, tail) {
    return apply(apply({
                    TAG: /* Success */0,
                    _0: cons
                  }, Curry._1(fn, head), combine), tail, combine);
  };
  var list$1 = Js_list.foldRight(folder, list, initState);
  if (list$1.TAG === /* Success */0) {
    return {
            TAG: /* Success */0,
            _0: Belt_List.toArray(list$1._0)
          };
  } else {
    return {
            TAG: /* Failure */1,
            _0: list$1._0
          };
  }
}

var Validation = {
  pure: pure,
  apply: apply,
  traverse: traverse
};

function map(result, mapError, mapOk) {
  if (result.TAG === /* Ok */0) {
    return Curry._1(mapOk, result._0);
  } else {
    return Curry._1(mapError, result._0);
  }
}

function mapError(result, fn) {
  if (result.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: result._0
          };
  } else {
    return Curry._1(fn, result._0);
  }
}

function invert(array) {
  return Belt_Array.reduce(array, {
              TAG: /* Ok */0,
              _0: []
            }, (function (acc, result) {
                return Belt_Result.flatMap(acc, (function (values) {
                              return Belt_Result.map(result, (function (value) {
                                            return Belt_Array.concat(values, [value]);
                                          }));
                            }));
              }));
}

function mapMany(results) {
  return Belt_Array.reduce(results, {
              TAG: /* Ok */0,
              _0: []
            }, (function (acc, result) {
                if (acc.TAG === /* Ok */0) {
                  if (result.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: Belt_Array.concat(acc._0, [result._0])
                          };
                  } else {
                    return {
                            TAG: /* Error */1,
                            _0: result._0
                          };
                  }
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: acc._0
                        };
                }
              }));
}

var Result = {
  map: map,
  mapError: mapError,
  invert: invert,
  mapMany: mapMany
};

export {
  Validation ,
  Result ,
}
/* No side effect */
