// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Product$Showside from "../types/Product.bs.js";
import * as Currency$Showside from "./Currency.bs.js";
import * as GoogleAnalytics$Showside from "./GoogleAnalytics.bs.js";

function getEventLabel($$event) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(window.trekkie, (function (prim) {
                                return prim.config;
                              })), (function (prim) {
                            return prim["Google Gtag Pixel"];
                          })), (function (prim) {
                        return prim.eventLabels;
                      })), (function (eventLabels) {
                    return Belt_Array.getBy(eventLabels, (function (item) {
                                  return Caml_obj.equal(Js_dict.get(item, "type"), $$event);
                                }));
                  })), (function (__x) {
                return Js_dict.get(__x, "action_label");
              }));
}

function getSendTo(account, $$event) {
  var sendTo = Belt_Option.flatMap(Belt_Option.flatMap(account, (function (account) {
              return account.googleAds;
            })), (function (googleAds) {
          if ($$event === "page_view") {
            return googleAds.pageViewConversionLabel;
          } else if ($$event === "view_item") {
            return googleAds.viewItemConversionLabel;
          } else if ($$event === "add_to_cart") {
            return googleAds.addToCartConversionLabel;
          } else {
            return ;
          }
        }));
  if (sendTo !== undefined) {
    return sendTo;
  } else {
    return getEventLabel($$event);
  }
}

function makePrice(product, variant) {
  var match = Product$Showside.Variant.getVariantPrices(variant);
  var price = Belt_Option.mapWithDefault(Currency$Showside.parse(Belt_Option.getWithDefault(match[1], match[0])), 0.0, (function (parsed) {
          return parsed.value;
        }));
  return [
          price,
          product.currency
        ];
}

function makeItem(account, category, product, variant, quantity, sendTo, index, param) {
  var variant$1 = Belt_Option.getWithDefault(variant, product.firstVariant);
  var match = makePrice(product, variant$1);
  var dash = function (str) {
    if (str !== "") {
      return " - " + str + "";
    } else {
      return "";
    }
  };
  var slash = function (str) {
    if (str !== "") {
      return " / " + str + "";
    } else {
      return "";
    }
  };
  var emptyToOption = function (str) {
    if (str === "") {
      return ;
    } else {
      return str;
    }
  };
  var name = "" + product.name + "" + dash(Belt_Option.getWithDefault(Product$Showside.Variant.getOptionValue(variant$1, "Color"), "")) + "" + slash(Belt_Option.getWithDefault(Product$Showside.Variant.getOptionValue(variant$1, "Size"), "")) + "";
  var variantName = "" + Belt_Option.getWithDefault(Product$Showside.Variant.getOptionValue(variant$1, "Color"), "") + "" + slash(Belt_Option.getWithDefault(Product$Showside.Variant.getOptionValue(variant$1, "Size"), "")) + "";
  var match$1 = window.trekkie;
  var match$2 = Belt_Option.flatMap(account, (function (a) {
          return a.slug;
        }));
  var id;
  var exit = 0;
  if (match$2 === "armitronwatches") {
    id = product.id;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    id = match$1 !== undefined ? "shopify_US_" + product.id + "_" + variant$1.id + "" : product.id;
  }
  var match$3 = variant$1.price;
  var discount;
  if (typeof match$3 === "number") {
    discount = 0;
  } else if (match$3.TAG === /* Regular */0) {
    discount = 0;
  } else {
    var match$4 = match$3._0;
    var regularPrice = Belt_Option.mapWithDefault(Currency$Showside.parse(match$4.regularPrice), 0.0, (function (price) {
            return price.value;
          }));
    var price = Belt_Option.mapWithDefault(Currency$Showside.parse(match$4.price), 0.0, (function (price) {
            return price.value;
          }));
    discount = regularPrice > 0 ? Math.round((regularPrice - price) * 100 / regularPrice) : 0;
  }
  return {
          id: id,
          item_id: id,
          name: emptyToOption(name),
          item_name: emptyToOption(name),
          index: Belt_Option.map(index, (function (index) {
                  return index + 1 | 0;
                })),
          category: Belt_Option.map(category, (function (category) {
                  return category.title;
                })),
          item_category: Belt_Option.map(category, (function (category) {
                  return category.title;
                })),
          item_list_id: Belt_Option.map(category, (function (category) {
                  return category.id;
                })),
          item_list_name: Belt_Option.map(category, (function (category) {
                  return category.title;
                })),
          price: match[0],
          currency: emptyToOption(match[1]),
          variant: emptyToOption(variantName),
          quantity: quantity,
          google_business_vertical: "retail",
          gender: Belt_Option.map(Belt_Array.getBy([
                    "men",
                    "women",
                    "unisex"
                  ], (function (tag) {
                      return Belt_Array.some(Belt_Array.map(product.tags, (function (prim) {
                                        return prim.toLowerCase();
                                      })), (function (t) {
                                    return t === tag;
                                  }));
                    })), (function (value) {
                  return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
                })),
          case_color: Product$Showside.Metadata.getMetadataAsText(product, variant$1, "global::case_color"),
          band_color: Product$Showside.Metadata.getMetadataAsText(product, variant$1, "global::band_color"),
          strap_material: Product$Showside.Metadata.getMetadataAsText(product, variant$1, "global::band_material"),
          case_material: Product$Showside.Metadata.getMetadataAsText(product, variant$1, "global::case_material"),
          waterproof_level: Product$Showside.Metadata.getMetadataAsText(product, variant$1, "global::water_resistance"),
          style: Belt_Array.getBy([
                "casual",
                "stainless steel",
                "sports",
                "dress",
                "leather",
                "retro"
              ], (function (tag) {
                  return Belt_Array.some(product.tags, (function (t) {
                                return t === tag;
                              }));
                })),
          movement: Product$Showside.Metadata.getMetadataAsText(product, variant$1, "global::movement"),
          sale_status: discount > 0 ? "sale" : "none",
          discount_percentage: discount
        };
}

function paramsFromProduct(account, index, category, product, variant, quantity, sendTo, param) {
  var match = makePrice(product, variant);
  return {
          send_to: sendTo,
          currency: match[1],
          value: match[0],
          non_interaction: true,
          item_list_id: Belt_Option.map(category, (function (category) {
                  return category.id;
                })),
          item_list_name: Belt_Option.map(category, (function (category) {
                  return category.title;
                })),
          items: [makeItem(account, category, product, variant, quantity, Caml_option.some(sendTo), index, undefined)]
        };
}

function trackViewItemList(account, category, products, indexOffsetOpt, param) {
  var indexOffset = indexOffsetOpt !== undefined ? indexOffsetOpt : 0;
  var gtag = window.gtag;
  if (gtag !== undefined) {
    return GoogleAnalytics$Showside.Gtag.sendEvent(account, undefined, "view_item_list", {
                item_list_id: Belt_Option.map(category, (function (category) {
                        return category.id;
                      })),
                item_list_name: Belt_Option.map(category, (function (category) {
                        return category.title;
                      })),
                non_interaction: true,
                items: Belt_Array.mapWithIndex(products, (function (index, product) {
                        return makeItem(account, category, product, undefined, 1, undefined, index + indexOffset | 0, undefined);
                      }))
              }, undefined);
  }
  
}

function sendItemEvent(account, index, category, product, variant, $$event) {
  var gtag = window.gtag;
  if (gtag !== undefined) {
    return GoogleAnalytics$Showside.Gtag.sendEvent(account, undefined, $$event, paramsFromProduct(account, index, category, product, variant, 1, getSendTo(account, $$event), undefined), undefined);
  }
  
}

function trackSelectItem(account, index, category, product, variant, param) {
  sendItemEvent(account, index, category, product, variant, "select_item");
}

function trackViewItem(account, category, product, variant, param) {
  var enabled = Belt_Option.mapWithDefault(Belt_Option.flatMap(account, (function (account) {
              return account.googleAds;
            })), true, (function (googleAds) {
          return googleAds.viewItemEnabled;
        }));
  if (enabled) {
    trackSelectItem(account, undefined, category, product, variant, undefined);
    return sendItemEvent(account, undefined, category, product, variant, "view_item");
  }
  
}

function trackAddToCart(account, category, product, variant, quantity, param) {
  var enabled = Belt_Option.mapWithDefault(Belt_Option.flatMap(account, (function (account) {
              return account.googleAds;
            })), true, (function (googleAds) {
          return googleAds.addToCartEnabled;
        }));
  if (enabled) {
    return sendItemEvent(account, undefined, category, product, variant, "add_to_cart");
  }
  
}

function trackPageView(account, param) {
  var enabled = Belt_Option.mapWithDefault(Belt_Option.flatMap(account, (function (account) {
              return account.googleAds;
            })), false, (function (googleAds) {
          return googleAds.pageViewEnabled;
        }));
  if (enabled) {
    return GoogleAnalytics$Showside.Gtag.sendEvent(account, undefined, "page_view", {
                send_to: getSendTo(account, "page_view")
              }, undefined);
  }
  
}

function init(account) {
  var tagId = Belt_Option.flatMap(account.googleAds, (function (ads) {
          return ads.tagId;
        }));
  GoogleAnalytics$Showside.Gtag.init(tagId, undefined, false, account);
  trackPageView(account, undefined);
}

export {
  getEventLabel ,
  getSendTo ,
  makePrice ,
  makeItem ,
  paramsFromProduct ,
  trackViewItemList ,
  sendItemEvent ,
  trackSelectItem ,
  trackViewItem ,
  trackAddToCart ,
  trackPageView ,
  init ,
}
/* Product-Showside Not a pure module */
