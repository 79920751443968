// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Env$Showside from "../utils/Env.bs.js";
import * as Shopify$Showside from "../utils/Shopify.bs.js";
import * as EventTypes$Showside from "../types/EventTypes.bs.js";
import * as AnalyticsOptions$Showside from "../utils/AnalyticsOptions.bs.js";
import * as FirebaseAnalytics$Showside from "../utils/FirebaseAnalytics.bs.js";
import * as ConfigurationProvider$Showside from "../context/ConfigurationProvider.bs.js";

var eventQueue = {
  contents: []
};

var analytics = {
  contents: undefined
};

function use(configId, accountId, link, canLoadFirebase, isInline, isLauncher, isNavMenu, currentStory, storyTitle, partnerId, breakpoint, param) {
  ConfigurationProvider$Showside.use(undefined);
  var trackToQueue = function ($$event, action, product, variant, categoryTitle, time, isMiniPDPVisible, mediaId, mediaParentId, mediaSrc, mediaTitle, relatedMediaCategoryId, relatedMediaProductIds) {
    var hasAlreadyEnqueuedThisEvent = Caml_option.undefined_to_opt(eventQueue.contents.find(function (tuple) {
              return Caml_obj.equal(tuple, [
                          $$event,
                          action,
                          product,
                          variant,
                          categoryTitle,
                          time,
                          isMiniPDPVisible,
                          mediaId,
                          mediaParentId,
                          mediaSrc,
                          mediaTitle,
                          relatedMediaCategoryId,
                          relatedMediaProductIds
                        ]);
            })) !== undefined;
    if (!hasAlreadyEnqueuedThisEvent) {
      eventQueue.contents.push([
            $$event,
            action,
            product,
            variant,
            categoryTitle,
            time,
            isMiniPDPVisible,
            mediaId,
            mediaParentId,
            mediaSrc,
            mediaTitle,
            relatedMediaCategoryId,
            relatedMediaProductIds
          ]);
      return ;
    }
    
  };
  var track = React.useCallback((function ($$event) {
          return function (action, product, variant, categoryTitle, time, isMiniPDPVisible, mediaId, mediaParentId, mediaSrc, mediaTitle, relatedMediaCategoryId, relatedMediaProductIds) {
            var options = AnalyticsOptions$Showside.make(configId, categoryTitle, product, variant, time, link, isInline, isLauncher, isNavMenu, "v2", currentStory, storyTitle, partnerId, breakpoint, accountId, isMiniPDPVisible, $$event, mediaId, mediaParentId, mediaSrc, mediaTitle, relatedMediaCategoryId, relatedMediaProductIds, undefined);
            var eventName = ("smart_nav_" + action).replace(/:| /g, "_").toLowerCase();
            FirebaseAnalytics$Showside.maybeLogEvent(analytics.contents, eventName, options);
            var affiliation = Belt_Option.getWithDefault(Shopify$Showside.getShop(undefined), "");
            if ($$event === "product details:add to cart") {
              if (product === undefined) {
                return ;
              }
              if (variant === undefined) {
                return ;
              }
              var itemViewed = FirebaseAnalytics$Showside.makeItemViewed(product, variant, affiliation, configId, link, isInline, isNavMenu, $$event);
              return FirebaseAnalytics$Showside.maybeLogEvent(analytics.contents, "add_to_cart", itemViewed);
            }
            if ($$event !== "product details:open") {
              if ($$event === "checkout") {
                return FirebaseAnalytics$Showside.maybeLogEvent(analytics.contents, "view_cart", {
                            affiliation: affiliation,
                            isInline: isInline,
                            isNavMenu: isNavMenu,
                            navVersion: "v2",
                            parentUrl: link,
                            sheetId: configId,
                            non_interaction: EventTypes$Showside.isNonInteractive($$event)
                          });
              } else {
                return ;
              }
            }
            if (product === undefined) {
              return ;
            }
            if (variant === undefined) {
              return ;
            }
            var itemViewed$1 = FirebaseAnalytics$Showside.makeItemViewed(product, variant, affiliation, configId, link, isInline, isNavMenu, $$event);
            return FirebaseAnalytics$Showside.maybeLogEvent(analytics.contents, "view_item", itemViewed$1);
          };
        }), [
        analytics,
        configId,
        link,
        isInline,
        isNavMenu,
        Belt_Option.getWithDefault(currentStory, 0),
        Belt_Option.getWithDefault(storyTitle, ""),
        Belt_Option.getWithDefault(partnerId, 0),
        Belt_Option.getWithDefault(breakpoint, ""),
        accountId
      ]);
  var track$1 = React.useCallback((function ($$event) {
          return function (action, product, variant, categoryTitle, time, isMiniPDPVisible, mediaId, mediaParentId, mediaSrc, mediaTitle, relatedMediaCategoryId, relatedMediaProductIds) {
            var fn = analytics.contents === undefined ? trackToQueue : track;
            return Curry.app(fn, [
                        $$event,
                        action,
                        product,
                        variant,
                        categoryTitle,
                        time,
                        isMiniPDPVisible,
                        mediaId,
                        mediaParentId,
                        mediaSrc,
                        mediaTitle,
                        relatedMediaCategoryId,
                        relatedMediaProductIds
                      ]);
          };
        }), [
        track,
        trackToQueue
      ]);
  React.useEffect((function () {
          var match = Env$Showside.isTest(undefined);
          if (canLoadFirebase && !match) {
            FirebaseAnalytics$Showside.init(undefined).then(function (firebaseAnalytics) {
                  analytics.contents = firebaseAnalytics;
                  Belt_Array.forEach(eventQueue.contents, (function (param) {
                          Curry.app(track$1, [
                                param[0],
                                param[1],
                                param[2],
                                param[3],
                                param[4],
                                param[5],
                                param[6],
                                param[7],
                                param[8],
                                param[9],
                                param[10],
                                param[11],
                                param[12]
                              ]);
                        }));
                  eventQueue.contents = [];
                });
          }
          
        }), [canLoadFirebase]);
  return track$1;
}

export {
  eventQueue ,
  analytics ,
  use ,
}
/* react Not a pure module */
