// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

var knownEmbeds = Js_dict.fromArray([
      [
        "https://www.youtube.com/embed/",
        {
          autoplayParam: "autoplay=1",
          loopParam: "loop=1"
        }
      ],
      [
        "https://player.vimeo.com/video/",
        {
          autoplayParam: "autoplay=1",
          loopParam: "loop=1"
        }
      ],
      [
        "https://play.gumlet.io/embed/",
        {
          autoplayParam: "autoplay=true",
          loopParam: "loop=true"
        }
      ],
      [
        "https://vimeo.gumlet.io/embed/",
        {
          autoplayParam: "autoplay=true",
          loopParam: "loop=true"
        }
      ]
    ]);

function IframeEmbed(Props) {
  var url = Props.url;
  var widthFullOpt = Props.widthFull;
  var heightFullOpt = Props.heightFull;
  var interactiveOpt = Props.interactive;
  var autoplayOpt = Props.autoplay;
  var loopOpt = Props.loop;
  var widthFull = widthFullOpt !== undefined ? widthFullOpt : true;
  var heightFull = heightFullOpt !== undefined ? heightFullOpt : true;
  var interactive = interactiveOpt !== undefined ? interactiveOpt : false;
  var autoplay = autoplayOpt !== undefined ? autoplayOpt : true;
  var loop = loopOpt !== undefined ? loopOpt : true;
  var config = Belt_Option.flatMap(Belt_Array.getBy(Object.keys(knownEmbeds), (function (param) {
              return url.startsWith(param);
            })), (function (param) {
          return Js_dict.get(knownEmbeds, param);
        }));
  var autoplayParam = autoplay ? Belt_Option.map(config, (function (c) {
            return c.autoplayParam;
          })) : undefined;
  var loopParam = loop ? Belt_Option.map(config, (function (c) {
            return c.loopParam;
          })) : undefined;
  var params = Belt_Array.keepMap([
          autoplayParam,
          loopParam
        ], (function (a) {
            return a;
          })).join("&");
  var url$1 = Belt_Array.keepMap([
          url,
          params
        ], (function (a) {
            return a;
          })).join("?");
  return React.createElement("iframe", {
              style: {
                border: "none",
                height: heightFull ? "100%" : "auto",
                overflow: "hidden",
                width: widthFull ? "100%" : "auto",
                pointerEvents: interactive ? "auto" : "none"
              },
              src: url$1
            });
}

var make = IframeEmbed;

export {
  knownEmbeds ,
  make ,
}
/* knownEmbeds Not a pure module */
