// Generated by ReScript, PLEASE EDIT WITH CARE


import * as React from "react";
  import loadable from "@loadable/component";
  let component = loadable(() => import('./BottombarApp.bs.js'));
;

function BottombarApp_Lazy(Props) {
  return (React.createElement(component, Props));
}

var make = BottombarApp_Lazy;

export {
  make ,
}
/*  Not a pure module */
