// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SWR from "../../../../node_modules/rescript-swr/lib/es6_global/src/SWR.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as UseEnv$Showside from "../hook/UseEnv.bs.js";
import * as Js_null_undefined from "../../../../node_modules/rescript/lib/es6/js_null_undefined.js";
import * as FetchUtils$Showside from "../utils/FetchUtils.bs.js";
import * as Breakpoint2$Showside from "../utils/Breakpoint2.bs.js";
import * as ReactContext$Showside from "../utils/ReactContext.bs.js";
import * as Configuration$Showside from "../types/Configuration.bs.js";
import * as CategoryFinder$Showside from "../types/CategoryFinder.bs.js";
import * as RouterProvider$Showside from "./RouterProvider.bs.js";
import * as ViewportContext$Showside from "./ViewportContext.bs.js";
import * as RescriptJsonUtils$Showside from "../utils/RescriptJsonUtils.bs.js";
import * as LegacyShowside_Configuration$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/LegacyShowside/LegacyShowside_Configuration.bs.js";
var context = React.createContext(undefined);
var provider = context.Provider;
var importConfigurationDecoder = function () {
  return import('../types/ConfigurationDecoder.bs.js').then(m => m.default);
};
function useFetchConfig(url) {
  Curry._1(importConfigurationDecoder, undefined);
  var partial_arg = RescriptJsonUtils$Showside.errorToString;
  var partial_arg$1 = async function (json) {
    var decoder = await Curry._1(importConfigurationDecoder, undefined);
    return Curry._1(decoder, json);
  };
  var match = SWR.useSWR(undefined, undefined, undefined, false, Js_null_undefined.fromOption(url), function (param) {
    return FetchUtils$Showside.$$fetch(partial_arg$1, undefined, undefined, undefined, partial_arg, function (json) {
      return {
        TAG: /* Error */1,
        _0: {
          TAG: /* Failure */0,
          _0: "Failed to decode configuration",
          _1: json
        }
      };
    }, param);
  });
  return [match.error, match.data];
}
function ConfigurationProvider(Props) {
  var configId = Props.configId;
  var children = Props.children;
  var config = Props.config;
  var enableTrimmingTreeByReferrer = Props.enableTrimmingTreeByReferrer;
  var match = ViewportContext$Showside.use(undefined);
  var breakpoint = match.breakpoint;
  var match$1 = UseEnv$Showside.use(undefined);
  var smartnavApiHost = match$1.smartnavApiHost;
  var match$2 = RouterProvider$Showside.useRouter(undefined);
  var previewMode = match$2.previewMode;
  var $$location = window.location;
  var trimTreeByReferrer = React.useMemo(function () {
    var previewLink = new URL(window.location.href).searchParams.get("maker_preview_contextual_nav");
    if (enableTrimmingTreeByReferrer) {
      return Belt_Option.getWithDefault(previewLink == null ? undefined : Caml_option.some(previewLink), encodeURIComponent($$location.origin + $$location.pathname));
    }
  }, []);
  var live = previewMode !== undefined ? typeof previewMode === "number" ? previewMode !== 0 : false : true;
  var backup = previewMode !== undefined && typeof previewMode !== "number" ? previewMode._0.toString() : undefined;
  var url = Belt_Option.map(configId, function (configId) {
    return Configuration$Showside.makeApiUrl(smartnavApiHost, live, backup, configId, Breakpoint2$Showside.toString(breakpoint), trimTreeByReferrer, undefined);
  });
  var match$3 = useFetchConfig(url);
  var data = match$3[1];
  var getLastModifiedNodeTimestamp = function (config, $$default) {
    var largestTimestamp = {
      contents: $$default
    };
    var maybeUpdateLargestTimestamp = function (category) {
      var current = category.lastModifiedAt;
      var comparison = Belt_Option.getWithDefault(current, 0.0) > Belt_Option.getWithDefault(largestTimestamp.contents, 0.0);
      if (comparison) {
        largestTimestamp.contents = current;
      }
      Belt_Array.forEach(CategoryFinder$Showside.getChildren(category), maybeUpdateLargestTimestamp);
    };
    Belt_Array.forEach(Belt_Option.mapWithDefault(data, [], function (config) {
      return config.categories;
    }), maybeUpdateLargestTimestamp);
    return largestTimestamp.contents;
  };
  var getLastModifiedAt = function (config) {
    var $$default = config.lastModifiedAt;
    if (previewMode === 0) {
      return Belt_Option.getWithDefault(getLastModifiedNodeTimestamp(config, $$default), $$default);
    } else {
      return $$default;
    }
  };
  var config$1 = config !== undefined ? config : data;
  var config$2 = Belt_Option.map(config$1, function (config) {
    return {
      id: config.id,
      categories: config.categories,
      settings: config.settings,
      transitiveStoreId: config.transitiveStoreId,
      transitiveAccount: config.transitiveAccount,
      transitiveStore: config.transitiveStore,
      accountId: config.accountId,
      lastModifiedAt: getLastModifiedAt(config)
    };
  });
  var render = function (param) {
    if (config$2 !== undefined) {
      return React.createElement(provider, {
        value: {
          config: config$2
        },
        children: children
      });
    } else {
      return null;
    }
  };
  var memoized = React.useMemo(function () {
    return render(undefined);
  }, [Belt_Option.mapWithDefault(config$2, 0.0, function (dto) {
    return dto.lastModifiedAt;
  }), breakpoint]);
  if (configId !== undefined) {
    return memoized;
  } else {
    return render(undefined);
  }
}
ConfigurationProvider.displayName = "ConfigurationProvider";
function use(param) {
  return ReactContext$Showside.useWithDefault("ConfigurationProvider.use", "ConfigurationProvider", {
    config: LegacyShowside_Configuration$ApiTypes.empty
  }, context);
}
var initialValue;
var make = ConfigurationProvider;
export { initialValue, context, provider, importConfigurationDecoder, useFetchConfig, make, use };
/* context Not a pure module */