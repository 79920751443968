// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "../../../../node_modules/rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Facets$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/Facets.bs.js";

function merge(facets1, facets2) {
  return {
          entries: Belt_Array.concat(facets1.entries, facets2.entries),
          minPrice: Belt_Option.orElse(facets1.minPrice, facets2.minPrice),
          maxPrice: Belt_Option.orElse(facets1.maxPrice, facets2.maxPrice)
        };
}

function make(minPrice, maxPrice, entries) {
  return {
          entries: entries,
          minPrice: minPrice,
          maxPrice: maxPrice
        };
}

function length(facets) {
  return (facets.entries.length + (
            Belt_Option.isSome(facets.maxPrice) ? 1 : 0
          ) | 0) + (
          Belt_Option.isSome(facets.minPrice) ? 1 : 0
        ) | 0;
}

function toMinPriceFacet(minPrice) {
  return "minprice=" + minPrice.toString();
}

function toMaxPriceFacet(maxPrice) {
  return "maxprice=" + maxPrice.toString();
}

function fromMinPriceFacet(facet) {
  var match = facet.split("=");
  if (match.length !== 2) {
    return ;
  }
  var match$1 = match[0];
  if (match$1 === "minprice") {
    return Belt_Float.fromString(match[1]);
  }
  
}

function fromMaxPriceFacet(facet) {
  var match = facet.split("=");
  if (match.length !== 2) {
    return ;
  }
  var match$1 = match[0];
  if (match$1 === "maxprice") {
    return Belt_Float.fromString(match[1]);
  }
  
}

function add(facets, entry) {
  var minPrice = fromMinPriceFacet(entry.facet);
  var maxPrice = fromMaxPriceFacet(entry.facet);
  var updatedFacets_entries = facets.entries;
  var updatedFacets_minPrice = Belt_Option.orElse(minPrice, facets.minPrice);
  var updatedFacets_maxPrice = Belt_Option.orElse(maxPrice, facets.maxPrice);
  var updatedFacets = {
    entries: updatedFacets_entries,
    minPrice: updatedFacets_minPrice,
    maxPrice: updatedFacets_maxPrice
  };
  return merge(updatedFacets, {
              entries: [entry],
              minPrice: undefined,
              maxPrice: undefined
            });
}

function remove(facets, facet) {
  var entries = Belt_Array.keep(facets.entries, (function (param) {
          return param.facet !== facet;
        }));
  var minPrice = Belt_Option.keep(facets.minPrice, (function (minPrice) {
          return "minprice=" + minPrice.toString() !== facet;
        }));
  var maxPrice = Belt_Option.keep(facets.maxPrice, (function (maxPrice) {
          return "maxprice=" + maxPrice.toString() !== facet;
        }));
  return {
          entries: entries,
          minPrice: minPrice,
          maxPrice: maxPrice
        };
}

var entry_encode = Facets$ApiTypes.entry_encode;

var entry_decode = Facets$ApiTypes.entry_decode;

var entry_fromJson = Facets$ApiTypes.entry_fromJson;

var entry_toJson = Facets$ApiTypes.entry_toJson;

var t_encode = Facets$ApiTypes.t_encode;

var t_decode = Facets$ApiTypes.t_decode;

var fromJson = Facets$ApiTypes.fromJson;

var toJson = Facets$ApiTypes.toJson;

var abstract_encode = Facets$ApiTypes.abstract_encode;

var abstract_decode = Facets$ApiTypes.abstract_decode;

var toStringOfIndex = Facets$ApiTypes.toStringOfIndex;

var codec = Facets$ApiTypes.codec;

var fromString = Facets$ApiTypes.fromString;

var toString = Facets$ApiTypes.toString;

var safeDecode = Facets$ApiTypes.safeDecode;

export {
  entry_encode ,
  entry_decode ,
  entry_fromJson ,
  entry_toJson ,
  t_encode ,
  t_decode ,
  fromJson ,
  toJson ,
  abstract_encode ,
  abstract_decode ,
  toStringOfIndex ,
  codec ,
  fromString ,
  toString ,
  safeDecode ,
  merge ,
  make ,
  length ,
  toMinPriceFacet ,
  toMaxPriceFacet ,
  fromMinPriceFacet ,
  fromMaxPriceFacet ,
  add ,
  remove ,
}
/* Facets-ApiTypes Not a pure module */
