// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "../../../node_modules/rescript-classnames/lib/es6_global/src/Cn.bs.js";
import * as React from "react";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Os$Showside from "./utils/Os.bs.js";
import * as CSS$Showside from "./utils/CSS.bs.js";

require("~/dist/pages.css");

function getClassName(param) {
  var match = Os$Showside.getOS(undefined);
  var osClassName;
  switch (match) {
    case /* Mac */0 :
        osClassName = "mac";
        break;
    case /* Windows */1 :
        osClassName = "windows";
        break;
    case /* Linux */2 :
        osClassName = "linux";
        break;
    case /* IOS */3 :
    case /* UnknownOs */4 :
        osClassName = "";
        break;
    
  }
  return Cn.make([
              "quickview",
              CSS$Showside.prefix("antialiased"),
              osClassName
            ]);
}

function Base(Props) {
  var classNameOpt = Props.className;
  var style = Props.style;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Cn.make([
          getClassName(undefined),
          className
        ])
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement("div", tmp, children);
}

var make = Base;

export {
  getClassName ,
  make ,
}
/*  Not a pure module */
