// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Env$Showside from "../utils/Env.bs.js";
import * as GoogleAnalytics$Showside from "../utils/GoogleAnalytics.bs.js";
import * as ConfigurationProvider$Showside from "../context/ConfigurationProvider.bs.js";

function use(canLoadFirebase) {
  var match = ConfigurationProvider$Showside.use(undefined);
  var match$1 = match.config;
  var account = match$1.transitiveAccount;
  var match$2 = match$1.settings;
  var gaVersion = match$2.gaVersion;
  var gaTrackingId = match$2.gaTrackingId;
  var checkoutLink = match$2.checkoutLink;
  var isBio = Belt_Option.isSome(match$2.mkrBio);
  React.useEffect((function () {
          var match = Env$Showside.isTest(undefined);
          if (canLoadFirebase && !(match || account === undefined)) {
            GoogleAnalytics$Showside.init(gaTrackingId, gaVersion, checkoutLink, isBio, account);
          }
          
        }), [
        canLoadFirebase,
        gaTrackingId,
        account,
        isBio
      ]);
  return function (param, param$1, param$2, param$3, param$4, param$5) {
    return GoogleAnalytics$Showside.track(account, gaTrackingId, param, param$1, param$2, param$3, param$4, param$5);
  };
}

export {
  use ,
}
/* react Not a pure module */
