// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SWR from "../../../../node_modules/rescript-swr/lib/es6_global/src/SWR.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Fetch from "../../../../node_modules/bs-fetch/lib/es6_global/src/Fetch.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../../node_modules/@ryyppy/rescript-promise/lib/es6_global/src/Promise.bs.js";
import * as Belt_Int from "../../../../node_modules/rescript/lib/es6/belt_Int.js";
import * as MakerBelt from "../../../../node_modules/@makerinc/makerbelt/lib/es6_global/src/MakerBelt.bs.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "../../../../node_modules/rescript/lib/es6/belt_Result.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json__Decode from "../../../../node_modules/rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Product$Showside from "../types/Product.bs.js";
import * as Shopify$Showside from "./Shopify.bs.js";
import * as Js_null_undefined from "../../../../node_modules/rescript/lib/es6/js_null_undefined.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as LocalStorage$Showside from "./LocalStorage.bs.js";
import * as StoreCartTypes$Showside from "./StoreCartTypes.bs.js";
import * as InnerTextExtractor$Showside from "./InnerTextExtractor.bs.js";
import * as ConfigurationProvider$Showside from "../context/ConfigurationProvider.bs.js";

function sleep(promise, ms) {
  return promise.then(function (payload) {
              return new Promise((function (resolve, reject) {
                            setTimeout((function (param) {
                                    resolve(payload);
                                  }), ms);
                          }));
            });
}

function isEqualTo(a, b) {
  if (a.variantId === b.variantId) {
    return a.productId === b.productId;
  } else {
    return false;
  }
}

function getDiffs(previous, next) {
  var added = Belt_Array.keepMap(next.items, (function (item) {
          var match = Belt_Array.getBy(previous.items, (function (param) {
                  return isEqualTo(item, param);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return item;
          }
        }));
  var removed = Belt_Array.keepMap(previous.items, (function (item) {
          var match = Belt_Array.getBy(next.items, (function (param) {
                  return isEqualTo(item, param);
                }));
          if (match !== undefined) {
            return ;
          } else {
            return item;
          }
        }));
  var updated = Belt_Array.keepMap(next.items, (function (item) {
          var existingItem = Belt_Array.getBy(previous.items, (function (param) {
                  return isEqualTo(item, param);
                }));
          if (existingItem !== undefined && existingItem.quantity !== item.quantity) {
            return item;
          }
          
        }));
  return Belt_Array.concatMany([
              Belt_Array.map(added, (function (item) {
                      return {
                              TAG: /* Add */0,
                              _0: item
                            };
                    })),
              Belt_Array.map(removed, (function (item) {
                      return {
                              TAG: /* Remove */2,
                              _0: item
                            };
                    })),
              Belt_Array.map(updated, (function (item) {
                      return {
                              TAG: /* Change */1,
                              _0: item
                            };
                    }))
            ]);
}

function noDecode(json) {
  return Promise.resolve(json);
}

function makeCartItem(product, variant, category, quantity) {
  return {
          productId: product.id,
          variantId: variant.id,
          quantity: quantity,
          title: product.name,
          image: Product$Showside.Media.getImageUrlWithPosterFallback(variant.media),
          url: variant.link,
          optionsWithValues: Belt_Array.map(Js_dict.entries(variant.options), (function (param) {
                  var match = param[1];
                  return {
                          name: match.name,
                          value: match.value
                        };
                })),
          makerCategoryId: Belt_Option.map(category, (function (category) {
                  return category.id;
                }))
        };
}

function apiCall(url, decoder, method_Opt, body, headers, param) {
  var method_ = method_Opt !== undefined ? method_Opt : /* Get */0;
  return fetch(url, Fetch.RequestInit.make(method_, headers, body, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function ($$fetch) {
              return Curry._1(decoder, Fetch.$$Response.json($$fetch));
            });
}

function fetchCart(param) {
  return apiCall("/cart.js?is_maker=true", (function (json) {
                return $$Promise.$$catch(json.then(function (json) {
                                  return Promise.resolve(Json__Decode.decodeValue(json, StoreCartTypes$Showside.t_decode));
                                }).then(function (decodeResult) {
                                if (decodeResult.TAG === /* Ok */0) {
                                  return Promise.resolve(decodeResult._0);
                                } else {
                                  return Promise.reject(Caml_js_exceptions.internalToOCamlException(decodeResult._0));
                                }
                              }), (function (param) {
                              return Promise.resolve(StoreCartTypes$Showside.empty);
                            }));
              }), undefined, undefined, undefined, undefined);
}

function updateCart(mutateFn, revalidateOpt, fn) {
  var revalidate = revalidateOpt !== undefined ? revalidateOpt : false;
  var diffs = {
    contents: []
  };
  return Curry._2(mutateFn, (function (previous) {
                    var next = Curry._1(fn, previous);
                    diffs.contents = getDiffs(previous, next);
                    return next;
                  }), revalidate).then(function (param) {
                return Promise.all(Belt_Array.map(diffs.contents, (function (diff) {
                                  var apiUrl;
                                  switch (diff.TAG | 0) {
                                    case /* Add */0 :
                                        apiUrl = "/cart/add.js?is_maker=true";
                                        break;
                                    case /* Change */1 :
                                    case /* Remove */2 :
                                        apiUrl = "/cart/change.js?is_maker=true";
                                        break;
                                    
                                  }
                                  var body;
                                  switch (diff.TAG | 0) {
                                    case /* Add */0 :
                                        var item = diff._0;
                                        body = "{ \"quantity\": " + item.quantity.toString() + ", \"id\": \"" + item.variantId + "\" }";
                                        break;
                                    case /* Change */1 :
                                        var item$1 = diff._0;
                                        body = "{ \"quantity\": " + item$1.quantity.toString() + ", \"id\": \"" + item$1.variantId + "\" }";
                                        break;
                                    case /* Remove */2 :
                                        body = "{ \"quantity\": 0, \"id\": \"" + diff._0.variantId + "\" }";
                                        break;
                                    
                                  }
                                  var headers = [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ]
                                  ];
                                  return apiCall(apiUrl, noDecode, /* Post */2, Caml_option.some(body), Caml_option.some(headers), undefined);
                                })));
              }).then(function (param) {
              return Curry._2(mutateFn, (function (a) {
                            return a;
                          }), true);
            });
}

function use(param) {
  var hook = SWR.useSWR(false, undefined, undefined, undefined, Js_null_undefined.fromOption(Shopify$Showside.getShop(undefined)), fetchCart);
  var partial_arg = hook.mutate;
  var updateCart$1 = function (param, param$1) {
    return updateCart(partial_arg, param, param$1);
  };
  var mutateCart = hook.mutate;
  return {
          hook: (function (param) {
              return hook;
            }),
          updateCart: updateCart$1,
          mutateCart: mutateCart
        };
}

var ShopifyCart = {
  apiCall: apiCall,
  fetchCart: fetchCart,
  updateCart: updateCart,
  use: use
};

function removeEmptyItems(cart) {
  return {
          itemCount: Belt_Array.reduce(cart.items, 0, (function (total, item) {
                  return total + item.quantity | 0;
                })),
          items: Belt_Array.keep(cart.items, (function (item) {
                  return item.quantity > 0;
                })),
          currency: cart.currency
        };
}

function fetchCart$1(accountId, param) {
  return Promise.resolve(Belt_Option.getWithDefault(Belt_Option.flatMap(accountId, (function (accountId) {
                        try {
                          return Belt_Option.flatMap(LocalStorage$Showside.get({
                                          NAME: "cart",
                                          VAL: accountId
                                        }), (function (cart) {
                                        return Belt_Result.getWithDefault(Belt_Result.map(MakerBelt.Result.mapError(Json__Decode.decodeValue(JSON.parse(cart), StoreCartTypes$Showside.t_decode), (function (e) {
                                                              return {
                                                                      TAG: /* Error */1,
                                                                      _0: Json__Decode.errorToString(e)
                                                                    };
                                                            })), (function (cart) {
                                                          return removeEmptyItems(cart);
                                                        })), undefined);
                                      }));
                        }
                        catch (e){
                          return ;
                        }
                      })), StoreCartTypes$Showside.empty));
}

function updateCart$1(accountId, mutateFn, revalidateOpt, fn) {
  var revalidate = revalidateOpt !== undefined ? revalidateOpt : false;
  return Curry._2(mutateFn, (function (previous) {
                var next = removeEmptyItems(Curry._1(fn, previous));
                Belt_Option.forEach(accountId, (function (accountId) {
                        LocalStorage$Showside.set({
                              NAME: "cart",
                              VAL: accountId
                            }, JSON.stringify(StoreCartTypes$Showside.t_encode(next)));
                      }));
                return next;
              }), revalidate);
}

function use$1(accountId) {
  var hook = SWR.useSWR(false, undefined, undefined, undefined, "local storage cart", (function (param) {
          return fetchCart$1(accountId, param);
        }));
  var partial_arg = hook.mutate;
  var updateCart$2 = function (param, param$1) {
    return updateCart$1(accountId, partial_arg, param, param$1);
  };
  return {
          hook: (function (param) {
              return hook;
            }),
          updateCart: updateCart$2,
          mutateCart: hook.mutate
        };
}

var LocalStorageCart = {
  removeEmptyItems: removeEmptyItems,
  fetchCart: fetchCart$1,
  updateCart: updateCart$1,
  use: use$1
};

function updateCart$2(mutateFn, revalidateOpt, fn) {
  return Promise.resolve(undefined);
}

function use$2(account) {
  var extractedCartCountFromPage = Belt_Option.flatMap(InnerTextExtractor$Showside.useInnerTextExtractor(Belt_Option.flatMap(account, (function (account) {
                  return account.cartCountCSSSelector;
                })), undefined), Belt_Int.fromString);
  var hookResult_data = {
    itemCount: Belt_Option.getWithDefault(extractedCartCountFromPage, StoreCartTypes$Showside.empty.itemCount),
    items: StoreCartTypes$Showside.empty.items,
    currency: StoreCartTypes$Showside.empty.currency
  };
  var hookResult_mutate = function (param, param$1) {
    return Promise.resolve(undefined);
  };
  var hookResult = {
    error: undefined,
    data: hookResult_data,
    isValidating: false,
    mutate: hookResult_mutate
  };
  var partial_arg = hookResult_mutate;
  var updateCart$3 = function (param, param$1) {
    return updateCart$2(partial_arg, param, param$1);
  };
  return {
          hook: (function (param) {
              return hookResult;
            }),
          updateCart: updateCart$3,
          mutateCart: hookResult_mutate
        };
}

var NoopCart = {
  updateCart: updateCart$2,
  use: use$2
};

function addOrUpdateInCart(cart, product, variant, category, quantity) {
  var itemToAdd = makeCartItem(product, variant, category, quantity);
  var itemInCart = Belt_Array.getBy(cart.items, (function (param) {
          return isEqualTo(itemToAdd, param);
        }));
  var updatedItems;
  if (itemInCart !== undefined) {
    var updatedQuantity = Math.min(variant.inventory, itemInCart.quantity + itemToAdd.quantity | 0);
    updatedItems = Belt_Array.keepMap(cart.items, (function (item) {
            if (isEqualTo(item, itemInCart)) {
              return {
                      productId: item.productId,
                      variantId: item.variantId,
                      quantity: updatedQuantity,
                      title: item.title,
                      image: item.image,
                      url: item.url,
                      optionsWithValues: item.optionsWithValues,
                      makerCategoryId: item.makerCategoryId
                    };
            } else {
              return item;
            }
          }));
  } else {
    updatedItems = Belt_Array.concat([itemToAdd], cart.items);
  }
  return {
          itemCount: Belt_Array.reduce(updatedItems, 0, (function (total, item) {
                  return total + item.quantity | 0;
                })),
          items: updatedItems,
          currency: cart.currency
        };
}

function use$3(source) {
  var match = ConfigurationProvider$Showside.use(undefined);
  var match$1 = match.config;
  var shopifyCart = use(undefined);
  var localStorageCart = use$1(match$1.accountId);
  var noopCart = use$2(match$1.transitiveAccount);
  switch (source) {
    case /* CartSourceNoop */0 :
        return noopCart;
    case /* CartSourceLocalStorage */1 :
        return localStorageCart;
    case /* CartSourceShopify */2 :
        return shopifyCart;
    
  }
}

export {
  sleep ,
  isEqualTo ,
  getDiffs ,
  noDecode ,
  makeCartItem ,
  ShopifyCart ,
  LocalStorageCart ,
  NoopCart ,
  addOrUpdateInCart ,
  use$3 as use,
}
/* SWR Not a pure module */
