// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rollbar from "../../../../node_modules/rescript-rollbar/lib/es6_global/src/Rollbar.bs.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as RollbarInstance$Showside from "./RollbarInstance.bs.js";
function state(prim) {
  return prim.state;
}
var history = window.history;
function reportError(exn) {
  var messagePrefix = "Error in History API";
  var jsExn = Rollbar.$$Error.fromRescript(exn);
  var rootCause = Rollbar.$$Error.getRootCause(jsExn);
  var match = rootCause.message;
  var match$1 = rootCause.name;
  var rootCauseMessage = match !== undefined ? match$1 !== undefined ? "RC: " + match$1 + " " + match + "" : "RC: " + match + "" : match$1 !== undefined ? "RC: " + match$1 + "" : undefined;
  var message = Belt_Array.keepMap([messagePrefix, rootCauseMessage], function (a) {
    return a;
  }).join(", ");
  console.error(messagePrefix, jsExn);
  return RollbarInstance$Showside.jsError3(RollbarInstance$Showside.fromStringWithCause(message, jsExn), message, exn);
}
function pushState(history, state, unused, url) {
  try {
    return Promise.resolve((history.pushState(state, unused, url), undefined));
  } catch (raw_e) {
    return reportError(Caml_js_exceptions.internalToOCamlException(raw_e));
  }
}
function replaceState(history, state, unused, url) {
  try {
    return Promise.resolve((history.replaceState(state, unused, url), undefined));
  } catch (raw_e) {
    return reportError(Caml_js_exceptions.internalToOCamlException(raw_e));
  }
}
function back(history) {
  try {
    return Promise.resolve((history.back(), undefined));
  } catch (raw_e) {
    return reportError(Caml_js_exceptions.internalToOCamlException(raw_e));
  }
}
export { state, history, reportError, pushState, replaceState, back };
/* history Not a pure module */