// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Throttle from "@react-hook/throttle";

function use(fpsOpt, leadingOpt, initialState) {
  var fps = fpsOpt !== undefined ? fpsOpt : 30;
  var leading = leadingOpt !== undefined ? leadingOpt : false;
  return Throttle.useThrottle(initialState, fps, leading);
}

export {
  use ,
}
/* @react-hook/throttle Not a pure module */
