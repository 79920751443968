// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_string from "../../../node_modules/rescript/lib/es6/js_string.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_format from "../../../node_modules/rescript/lib/es6/caml_format.js";
import * as Env$Showside from "./utils/Env.bs.js";
import * as Store$ApiTypes from "../../../node_modules/api-types/lib/es6_global/src/Store/Store.bs.js";
import * as Facets$Showside from "./types/Facets.bs.js";

var prefix = "category";

function toString(value) {
  return Belt_Array.keepMap([
                prefix,
                value.categoryId,
                value.sourceNodeId
              ], (function (a) {
                  return a;
                })).join(":");
}

function fromString(value) {
  var match = value.split(":");
  var len = match.length;
  if (len !== 2) {
    if (len !== 3) {
      return ;
    }
    var p = match[0];
    var categoryId = match[1];
    var sourceNodeId = match[2];
    if (p === prefix) {
      return {
              categoryId: categoryId,
              sourceNodeId: sourceNodeId
            };
    } else {
      return ;
    }
  }
  var p$1 = match[0];
  var categoryId$1 = match[1];
  if (p$1 === prefix) {
    return {
            categoryId: categoryId$1,
            sourceNodeId: undefined
          };
  }
  
}

var StoreCategoryView = {
  prefix: prefix,
  toString: toString,
  fromString: fromString
};

function getView(value) {
  switch (value) {
    case "cart" :
        return /* StoreCart */3;
    case "favorites" :
        return /* Favorites */1;
    case "history" :
        return /* History */2;
    case "plp" :
        return /* PLP */0;
    default:
      if (value.startsWith(prefix + ":")) {
        return Belt_Option.flatMap(fromString(value), (function (data) {
                      return /* StoreCategory */{
                              _0: data
                            };
                    }));
      } else {
        return ;
      }
  }
}

function make(configId, tabId, categoryId, productCategoryId, productId, relatedProductsId, variantId, photoZoom, view, filters, previewMode, devMode, sort, param) {
  return {
          configId: configId,
          tabId: tabId,
          categoryId: categoryId,
          productCategoryId: productCategoryId,
          productId: productId,
          relatedProductsId: relatedProductsId,
          variantId: variantId,
          photoZoom: photoZoom,
          previewMode: previewMode,
          devMode: devMode,
          filters: filters,
          sort: sort,
          view: view
        };
}

var empty = make("", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function noneIfEmpty(value) {
  return Belt_Option.flatMap(value, (function (value) {
                if (value === "") {
                  return ;
                } else {
                  return value;
                }
              }));
}

function fromDict(params) {
  return Belt_Option.map(Js_dict.get(params, "QNConfigId"), (function (configId) {
                return {
                        configId: configId,
                        tabId: noneIfEmpty(Js_dict.get(params, "QNTabId")),
                        categoryId: noneIfEmpty(Js_dict.get(params, "QNCategoryId")),
                        productCategoryId: noneIfEmpty(Js_dict.get(params, "QNProductCategoryId")),
                        productId: noneIfEmpty(Js_dict.get(params, "QNProductId")),
                        relatedProductsId: noneIfEmpty(Js_dict.get(params, "QNRelatedProductsId")),
                        variantId: noneIfEmpty(Js_dict.get(params, "QNVariantId")),
                        photoZoom: Belt_Option.flatMap(Js_dict.get(params, "QNPhotoZoom"), (function (value) {
                                var isInt = Belt_Option.isSome(Js_string.match_(/^([0-9]+)$/i, value));
                                if (isInt) {
                                  return Caml_format.int_of_string(value);
                                }
                                
                              })),
                        previewMode: Belt_Option.flatMap(Js_dict.get(params, "QNPreviewMode"), (function (value) {
                                switch (value) {
                                  case "HEAD" :
                                      return /* PreviewHead */0;
                                  case "LIVE" :
                                      return /* PreviewLive */1;
                                  default:
                                    var match = value.match(/^T([0-9]+)$/);
                                    if (match === null) {
                                      return ;
                                    }
                                    if (match.length !== 2) {
                                      return ;
                                    }
                                    var timestamp = match[1];
                                    if (timestamp !== undefined) {
                                      return /* PreviewBackup */{
                                              _0: Number(timestamp)
                                            };
                                    } else {
                                      return ;
                                    }
                                }
                              })),
                        devMode: Belt_Option.map(Js_dict.get(params, "QNDevMode"), Env$Showside.getApiEnv),
                        filters: Belt_Option.flatMap(Js_dict.get(params, "QNFilters"), Facets$Showside.safeDecode),
                        sort: Belt_Option.map(noneIfEmpty(Js_dict.get(params, "QNSort")), (function (value) {
                                return Store$ApiTypes.ProductSortOrder.fromString(value);
                              })),
                        view: Belt_Option.flatMap(Js_dict.get(params, "QNView"), getView)
                      };
              }));
}

function toDict(state) {
  var match = state.previewMode;
  var match$1 = state.devMode;
  var tmp;
  if (match$1 !== undefined) {
    if (typeof match$1 === "number") {
      switch (match$1) {
        case /* Staging */0 :
            tmp = "staging";
            break;
        case /* Production */1 :
            tmp = "prod";
            break;
        case /* Local */2 :
            tmp = "local";
            break;
        
      }
    } else {
      tmp = match$1._0;
    }
  } else {
    tmp = "";
  }
  var match$2 = state.view;
  var tmp$1;
  if (match$2 !== undefined) {
    if (typeof match$2 === "number") {
      switch (match$2) {
        case /* PLP */0 :
            tmp$1 = "plp";
            break;
        case /* Favorites */1 :
            tmp$1 = "favorites";
            break;
        case /* History */2 :
            tmp$1 = "history";
            break;
        case /* StoreCart */3 :
            tmp$1 = "cart";
            break;
        
      }
    } else {
      tmp$1 = toString(match$2._0);
    }
  } else {
    tmp$1 = "";
  }
  return Js_dict.fromArray([
              [
                "QNConfigId",
                state.configId
              ],
              [
                "QNTabId",
                Belt_Option.getWithDefault(state.tabId, "")
              ],
              [
                "QNCategoryId",
                Belt_Option.getWithDefault(state.categoryId, "")
              ],
              [
                "QNProductCategoryId",
                Belt_Option.getWithDefault(state.productCategoryId, "")
              ],
              [
                "QNProductId",
                Belt_Option.getWithDefault(state.productId, "")
              ],
              [
                "QNVariantId",
                Belt_Option.getWithDefault(state.variantId, "")
              ],
              [
                "QNRelatedProductsId",
                Belt_Option.getWithDefault(state.relatedProductsId, "")
              ],
              [
                "QNPhotoZoom",
                Belt_Option.mapWithDefault(state.photoZoom, "", (function (prim) {
                        return String(prim);
                      }))
              ],
              [
                "QNPreviewMode",
                match !== undefined ? (
                    typeof match === "number" ? (
                        match !== 0 ? "LIVE" : "HEAD"
                      ) : "T" + match._0.toString()
                  ) : ""
              ],
              [
                "QNDevMode",
                tmp
              ],
              [
                "QNView",
                tmp$1
              ],
              [
                "QNFilters",
                Belt_Option.getWithDefault(Belt_Option.map(state.filters, Facets$Showside.toString), "")
              ],
              [
                "QNSort",
                Belt_Option.getWithDefault(Belt_Option.map(state.sort, (function (value) {
                            return Store$ApiTypes.ProductSortOrder.toString(value);
                          })), "")
              ]
            ]);
}

function toString$1(state) {
  var match = state.previewMode;
  var match$1 = state.devMode;
  var tmp;
  if (match$1 !== undefined) {
    if (typeof match$1 === "number") {
      switch (match$1) {
        case /* Staging */0 :
            tmp = "staging";
            break;
        case /* Production */1 :
            tmp = "prod";
            break;
        case /* Local */2 :
            tmp = "local";
            break;
        
      }
    } else {
      tmp = match$1._0;
    }
  } else {
    tmp = "";
  }
  var match$2 = state.view;
  var tmp$1;
  if (match$2 !== undefined) {
    if (typeof match$2 === "number") {
      switch (match$2) {
        case /* PLP */0 :
            tmp$1 = "plp";
            break;
        case /* Favorites */1 :
            tmp$1 = "favorites";
            break;
        case /* History */2 :
            tmp$1 = "history";
            break;
        case /* StoreCart */3 :
            tmp$1 = "cart";
            break;
        
      }
    } else {
      tmp$1 = toString(match$2._0);
    }
  } else {
    tmp$1 = "";
  }
  return [
            "QNConfigId" + state.configId,
            "QNTabId" + Belt_Option.getWithDefault(state.tabId, ""),
            "QNCategoryId" + Belt_Option.getWithDefault(state.categoryId, ""),
            "QNProductCategoryId" + Belt_Option.getWithDefault(state.productCategoryId, ""),
            "QNProductId" + Belt_Option.getWithDefault(state.productId, ""),
            "QNVariantId" + Belt_Option.getWithDefault(state.variantId, ""),
            "QNRelatedProductsId" + Belt_Option.getWithDefault(state.relatedProductsId, ""),
            "QNPhotoZoom" + Belt_Option.mapWithDefault(state.photoZoom, "", (function (prim) {
                    return String(prim);
                  })),
            "QNPreviewMode" + (
              match !== undefined ? (
                  typeof match === "number" ? (
                      match !== 0 ? "LIVE" : "HEAD"
                    ) : "T" + match._0.toString()
                ) : ""
            ),
            "QNDevMode" + tmp,
            "QNView" + tmp$1,
            "QNFilters" + Belt_Option.getWithDefault(Belt_Option.map(state.filters, Facets$Showside.toString), ""),
            "QNSort" + Belt_Option.getWithDefault(Belt_Option.map(state.sort, (function (value) {
                        return Store$ApiTypes.ProductSortOrder.toString(value);
                      })), "")
          ].join("|");
}

function sanitize($$location) {
  return {
          configId: "",
          tabId: undefined,
          categoryId: $$location.categoryId,
          productCategoryId: $$location.productCategoryId,
          productId: $$location.productId,
          relatedProductsId: $$location.relatedProductsId,
          variantId: $$location.variantId,
          photoZoom: undefined,
          previewMode: $$location.previewMode,
          devMode: $$location.devMode,
          filters: $$location.filters,
          sort: $$location.sort,
          view: $$location.view
        };
}

export {
  StoreCategoryView ,
  getView ,
  make ,
  empty ,
  noneIfEmpty ,
  fromDict ,
  toDict ,
  toString$1 as toString,
  sanitize ,
}
/* empty Not a pure module */
