// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
var getAppVersion = function () {
  return navigator.appVersion;
};
var getUserAgent = function () {
  return navigator.userAgent;
};
function getBrowser(param) {
  var userAgentString = Curry._1(getUserAgent, undefined);
  var chromeAgent = userAgentString.indexOf("Chrome") > -1;
  var firefoxAgent = userAgentString.indexOf("Firefox") > -1;
  var safariAgent = userAgentString.indexOf("Safari") > -1;
  if (chromeAgent) {
    return /* Chrome */0;
  } else if (firefoxAgent) {
    return /* Firefox */2;
  } else if (safariAgent) {
    return /* Safari */1;
  } else {
    return /* UnknownBrowser */3;
  }
}
function isSafariVersion__16_3(param) {
  var userAgentString = Curry._1(getUserAgent, undefined);
  var match = getBrowser(undefined);
  if (match !== 1) {
    return false;
  } else {
    return userAgentString.includes("iPhone OS 16_3");
  }
}
function getOS(param) {
  var appVersion = Curry._1(getAppVersion, undefined);
  if (appVersion.includes("Win")) {
    return /* Windows */1;
  } else if (appVersion.includes("iPhone OS")) {
    return /* IOS */3;
  } else if (appVersion.includes("Mac")) {
    return /* Mac */0;
  } else if (appVersion.includes("Linux")) {
    return /* Linux */2;
  } else {
    return /* UnknownOs */4;
  }
}
export { getAppVersion, getUserAgent, getBrowser, isSafariVersion__16_3, getOS };
/* No side effect */