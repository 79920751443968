// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "../../../node_modules/rescript-classnames/lib/es6_global/src/Cn.bs.js";
import * as React from "react";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Os$Showside from "./utils/Os.bs.js";
import * as CSS$Showside from "./utils/CSS.bs.js";
import * as ReactPortal from "react-portal";
var Portal = {};
var isPages = Caml_option.nullable_to_opt(document.getElementById("quicknav")) !== undefined;
var version = isPages ? "v2" : "v3";
if (version === "v2") {
  import("~/dist/pages.css");
}
;
function getClassName(param) {
  var match = Os$Showside.getOS(undefined);
  var osClassName;
  switch (match) {
    case /* Mac */0:
      osClassName = "mac";
      break;
    case /* Windows */1:
      osClassName = "windows";
      break;
    case /* Linux */2:
      osClassName = "linux";
      break;
    case /* IOS */3:
    case /* UnknownOs */4:
      osClassName = "";
      break;
  }
  if (version === "v3") {
    return CSS$Showside.prefix(Cn.make(["maker-base", "antialiased", osClassName]));
  } else {
    return Cn.make(["quickview", CSS$Showside.prefix(Cn.make(["antialiased", osClassName]))]);
  }
}
function Base(Props) {
  var classNameOpt = Props.className;
  var portal = Props.portal;
  var style = Props.style;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Cn.make([getClassName(undefined), className])
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var children$1 = React.createElement("div", tmp, children);
  if (portal) {
    return React.createElement(ReactPortal.Portal, {
      children: children$1
    });
  } else {
    return children$1;
  }
}
Base.displayName = "Base";
var make = Base;
export { Portal, isPages, version, getClassName, make };
/* isPages Not a pure module */