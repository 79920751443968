// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Env$Showside from "../utils/Env.bs.js";

function noop(param) {
  
}

function noop1(param) {
  
}

function noop2(param, param$1) {
  
}

function noop3(param, param$1, param$2) {
  
}

function noop4(param, param$1, param$2, param$3) {
  
}

function initialValue_trackEvent(param, shouldTrackConfigDTOOpt, category, product, variant, toProduct, time, isMiniPDPVisible, mediaId, mediaParentId, mediaSrc, mediaTitle, relatedMediaCategoryId, relatedMediaProductIds, param$1) {
  
}

var initialValue = {
  smartnavApiHost: Env$Showside.apiHostnameProduction,
  embedType: /* Bottombar */0,
  isSidebarOpen: false,
  isMobileFiltersOpen: false,
  lastProductAddedToCart: undefined,
  setLastProductAddedToCart: noop1,
  trackEvent: initialValue_trackEvent,
  toggleSidebar: noop,
  toggleFiltersPanel: noop,
  onAddToCart: noop4,
  onCategoryClick: noop1,
  onQuickViewClick: noop2,
  onProductClick: noop2,
  onHomeClick: noop1,
  onFavoritesClick: noop,
  onHistoryClick: noop,
  onLauncherItemClick: noop1,
  onDrawerOpening: noop,
  onChangeFilters: noop1,
  isMiniPDPVisible: undefined,
  allMarkets: undefined,
  market: /* Unsupported */0,
  onMarketChange: noop1
};

var context = React.createContext(initialValue);

var provider = context.Provider;

function EntrypointContext$Provider(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Provider = {
  provider: provider,
  make: EntrypointContext$Provider
};

function use(param) {
  return React.useContext(context);
}

export {
  noop ,
  noop1 ,
  noop2 ,
  noop3 ,
  noop4 ,
  initialValue ,
  context ,
  Provider ,
  use ,
}
/* context Not a pure module */
