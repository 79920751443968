// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Debounce$Showside from "../utils/Debounce.bs.js";
import * as WindowSize$Showside from "../utils/WindowSize.bs.js";

function useWindowResize(callback) {
  React.useEffect((function () {
          window.addEventListener("resize", callback, {
                capture: false,
                passive: true,
                once: false
              });
          return (function (param) {
                    window.removeEventListener("resize", callback, {
                          capture: false,
                          passive: true
                        });
                  });
        }), [callback]);
}

function useWindowSize(leading, wait, param) {
  var getSize = function (param) {
    return [
            WindowSize$Showside.getWidth(undefined),
            WindowSize$Showside.getHeight(undefined)
          ];
  };
  var match = Debounce$Showside.useDebounce(leading, wait, (function (param) {
          return getSize(undefined);
        }));
  var setResult = match[1];
  var handleResize = React.useCallback((function (param) {
          Curry._1(setResult, (function (param) {
                  return getSize(undefined);
                }));
        }), []);
  useWindowResize(handleResize);
  return match[0];
}

function useWindowWidth(leading, wait, disabled, param) {
  var match = Debounce$Showside.useDebounce(leading, wait, (function (param) {
          return WindowSize$Showside.getWidth(undefined);
        }));
  var setResult = match[1];
  var handleResize = React.useCallback((function (param) {
          Curry._1(setResult, (function (param) {
                  return WindowSize$Showside.getWidth(undefined);
                }));
        }), []);
  useWindowResize(handleResize);
  var disabled$1 = Belt_Option.mapWithDefault(disabled, false, (function (ref) {
          return ref.current;
        }));
  React.useEffect((function () {
          Curry._1(handleResize, new Event("resize"));
        }), [disabled$1]);
  return match[0];
}

function useWindowHeight(leading, wait, disabled, param) {
  var match = Debounce$Showside.useDebounce(leading, wait, (function (param) {
          return WindowSize$Showside.getHeight(undefined);
        }));
  var setResult = match[1];
  var handleResize = React.useCallback((function (param) {
          var disabled$1 = Belt_Option.mapWithDefault(disabled, false, (function (ref) {
                  return ref.current;
                }));
          if (disabled$1) {
            return ;
          } else {
            return Curry._1(setResult, (function (param) {
                          return WindowSize$Showside.getHeight(undefined);
                        }));
          }
        }), []);
  useWindowResize(handleResize);
  var disabled$1 = Belt_Option.mapWithDefault(disabled, false, (function (ref) {
          return ref.current;
        }));
  React.useEffect((function () {
          Curry._1(handleResize, new Event("resize"));
        }), [disabled$1]);
  return match[0];
}

export {
  useWindowResize ,
  useWindowSize ,
  useWindowWidth ,
  useWindowHeight ,
}
/* react Not a pure module */
