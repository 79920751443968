// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Js_array from "../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Js_string from "../../../../node_modules/rescript/lib/es6/js_string.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";

function prefix(str) {
  var str$1 = "all " + str;
  var regex = /\s+/;
  var classNames = Belt_Array.keepMap(Js_string.splitByRe(regex, str$1), (function (item) {
          if (item === undefined || Caml_obj.equal(item, "")) {
            return ;
          } else {
            return item;
          }
        }));
  var prefixed = Belt_Array.map(classNames, (function (className) {
          var regex = /:/;
          var classParts = Js_string.splitByRe(regex, className);
          if (classParts.length !== 2) {
            return "QVTW__" + className;
          }
          var ps = classParts[0];
          if (ps === undefined) {
            return "QVTW__" + className;
          }
          var s = classParts[1];
          if (s !== undefined) {
            return ps + ":QVTW__" + s;
          } else {
            return "QVTW__" + className;
          }
        }));
  return Js_array.joinWith(" ", prefixed);
}

function lineClampOfInt(n) {
  switch (n) {
    case 1 :
        return "line-clamp-1";
    case 2 :
        return "line-clamp-2";
    case 3 :
        return "line-clamp-3";
    case 4 :
        return "line-clamp-4";
    case 5 :
        return "line-clamp-5";
    default:
      return "line-clamp-6";
  }
}

function intToPx(i) {
  if (i === 0) {
    return "0";
  } else {
    return "" + String(i) + "px";
  }
}

function floatToPx(i) {
  if (i === 0.0) {
    return "0";
  } else {
    return "" + String(i) + "px";
  }
}

function intToPercentage(i) {
  if (i === 0) {
    return "0";
  } else {
    return "" + String(i) + "%";
  }
}

function floatToPercentage(i) {
  if (i === 0.0) {
    return "0";
  } else {
    return "" + String(i) + "%";
  }
}

function intToMs(i) {
  if (i === 0) {
    return "0";
  } else {
    return "" + String(i) + "ms";
  }
}

function floatToMs(i) {
  if (i === 0.0) {
    return "0";
  } else {
    return "" + String(i) + "ms";
  }
}

function translate3d(x, y, z) {
  var x$1 = String(x) + "px";
  var y$1 = String(y) + "px";
  var z$1 = String(z) + "px";
  return "translate3d(" + x$1 + "," + y$1 + "," + z$1 + ")";
}

function translate3d_float(x, y, z) {
  var x$1 = String(x) + "px";
  var y$1 = String(y) + "px";
  var z$1 = String(z) + "px";
  return "translate3d(" + x$1 + "," + y$1 + "," + z$1 + ")";
}

function scale(x, y) {
  var x$1 = x.toString();
  var y$1 = y.toString();
  return "scale(" + x$1 + "," + y$1 + ")";
}

function translateAndScale(tx, ty, sx, sy) {
  return translate3d(tx, ty, 0) + " " + scale(sx, sy);
}

function opacity(ratio) {
  var quantized = Belt_Option.getWithDefault(Belt_Array.getBy([
            0,
            25,
            50,
            75,
            100
          ], (function (opacity) {
              return (ratio * 100.0 | 0) <= opacity;
            })), 100);
  if (quantized >= 50) {
    if (quantized !== 75) {
      if (quantized !== 100) {
        if (quantized >= 51) {
          return "";
        } else {
          return "opacity-50";
        }
      } else {
        return "opacity-100";
      }
    } else {
      return "opacity-75";
    }
  } else if (quantized !== 0) {
    if (quantized !== 25) {
      return "";
    } else {
      return "opacity-25";
    }
  } else {
    return "opacity-0";
  }
}

function duration(n) {
  var quantized = Belt_Option.getWithDefault(Belt_Array.getBy([
            0,
            75,
            100,
            150,
            200,
            300,
            500,
            700,
            1000
          ], (function (available) {
              return n <= available;
            })), 1000);
  if (quantized >= 200) {
    if (quantized < 501) {
      if (quantized > 499 || quantized < 201) {
        if (quantized >= 500) {
          return "duration-500";
        } else {
          return "duration-200";
        }
      } else if (quantized !== 300) {
        return "";
      } else {
        return "duration-300";
      }
    } else if (quantized !== 700) {
      if (quantized !== 1000) {
        return "";
      } else {
        return "duration-1000";
      }
    } else {
      return "duration-700";
    }
  } else if (quantized >= 76) {
    if (quantized !== 100) {
      if (quantized !== 150) {
        return "";
      } else {
        return "duration-150";
      }
    } else {
      return "duration-100";
    }
  } else if (quantized !== 0) {
    if (quantized >= 75) {
      return "duration-75";
    } else {
      return "";
    }
  } else {
    return "duration-0";
  }
}

export {
  prefix ,
  lineClampOfInt ,
  intToPx ,
  floatToPx ,
  intToPercentage ,
  floatToPercentage ,
  intToMs ,
  floatToMs ,
  translate3d ,
  translate3d_float ,
  scale ,
  translateAndScale ,
  opacity ,
  duration ,
}
/* No side effect */
