// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Js_array from "../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Belt_List from "../../../../node_modules/rescript/lib/es6/belt_List.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Pervasives from "../../../../node_modules/rescript/lib/es6/pervasives.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Mem$Showside from "../utils/Mem.bs.js";
import * as Belt_SortArray from "../../../../node_modules/rescript/lib/es6/belt_SortArray.js";
import * as StoreId$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/Store/StoreId.bs.js";
import * as Currency$Showside from "../utils/Currency.bs.js";
import * as URLUtils$Showside from "../utils/URLUtils.bs.js";
import * as IframeEmbed$Showside from "../utils/IframeEmbed.bs.js";
import * as UniqueArray$Showside from "../utils/UniqueArray.bs.js";
import * as NaturalCompare$Showside from "../utils/NaturalCompare.bs.js";
import * as CustomerBrighton$Showside from "../customers/CustomerBrighton.bs.js";
function emptyStr(__x) {
  return Belt_Option.getWithDefault(__x, "");
}
function noneIfEmpty(str) {
  if (str === "") {
    return;
  } else {
    return str;
  }
}
function isNotNoneOrEmpty(str) {
  if (Caml_obj.notequal(str, "")) {
    return str !== undefined;
  } else {
    return false;
  }
}
function newProductById(products, id) {
  return Belt_Array.getBy(products, function (product) {
    return Curry._1(StoreId$ApiTypes.ProductId.toString, product.id) === id;
  });
}
var DTOHelpers = {
  newProductById: newProductById
};
function getImageUrl(media) {
  switch (media.TAG | 0) {
    case /* Image */0:
      return media._0.url;
    case /* Video */1:
    case /* IframeEmbed */2:
      return;
  }
}
function getImageUrlWithPosterFallback(media) {
  switch (media.TAG | 0) {
    case /* Image */0:
      return media._0.url;
    case /* Video */1:
      return media._0.posterUrl;
    case /* IframeEmbed */2:
      return;
  }
}
function getVideoSource(video) {
  var src = Belt_Array.getBy(video.sources, function (source) {
    return source.mimeType === "application/x-mpegURL";
  });
  var src$1 = src !== undefined ? src : Belt_Array.getBy(video.sources, function (source) {
    return source.mimeType === "video/mp4";
  });
  if (src$1 !== undefined) {
    return src$1;
  } else {
    return Belt_Array.get(video.sources, 0);
  }
}
var resolveVideoUrls = Mem$Showside.mem(function (media) {
  return Belt_Array.reduceWithIndex(Belt_Array.map(media, function (mediaItem) {
    if (mediaItem.TAG === /* TypeImage */0) {
      return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(mediaItem._0.url, function (url) {
        return Belt_Option.map(URLUtils$Showside.getEstimatedVideoMimeFromUrl(url), function (mimeType) {
          return [url, mimeType];
        });
      }), function (param) {
        return {
          TAG: /* TypeVideo */1,
          _0: {
            poster: undefined,
            sources: [{
              mimeType: param[1],
              size: [0, 0],
              url: param[0]
            }]
          }
        };
      }), mediaItem);
    } else {
      return mediaItem;
    }
  }), [], function (acc, curr, i) {
    var next;
    if (curr.TAG === /* TypeImage */0) {
      next = curr;
    } else {
      var data = curr._0;
      var poster = data.poster;
      var poster$1 = poster !== undefined ? poster : Belt_Option.map(Belt_Array.get(Belt_Array.keepMap(acc, function (media) {
        if (media.TAG === /* TypeImage */0) {
          return media._0;
        }
      }), 0), function (source) {
        return source.url;
      });
      next = {
        TAG: /* TypeVideo */1,
        _0: {
          poster: poster$1,
          sources: data.sources
        }
      };
    }
    return Belt_Array.concat(acc, [next]);
  });
});
function getVideoUrl(media) {
  switch (media.TAG | 0) {
    case /* Video */1:
      return Belt_Option.map(getVideoSource(media._0), function (videoSource) {
        return videoSource.url;
      });
    case /* Image */0:
    case /* IframeEmbed */2:
      return;
  }
}
function getImage(media) {
  switch (media.TAG | 0) {
    case /* Image */0:
      return media._0;
    case /* Video */1:
      var match = media._0;
      var posterUrl = match.posterUrl;
      return Belt_Option.flatMap(Belt_Array.get(match.sources, 0), function (source) {
        return Belt_Option.map(posterUrl, function (url) {
          return {
            url: url,
            width: source.width,
            height: source.height
          };
        });
      });
    case /* IframeEmbed */2:
      return;
  }
}
function fromDTO(dto) {
  if (dto.TAG === /* TypeImage */0) {
    var data = dto._0;
    var match = Belt_Option.getWithDefault(data.size, [0, 0]);
    return {
      TAG: /* Image */0,
      _0: {
        url: data.url,
        width: match[0],
        height: match[1]
      }
    };
  }
  var data$1 = dto._0;
  return {
    TAG: /* Video */1,
    _0: {
      posterUrl: data$1.poster,
      sources: data$1.sources.map(function (source) {
        var match = source.size;
        return {
          url: source.url,
          width: match[0],
          height: match[1],
          mimeType: source.mimeType
        };
      })
    }
  };
}
var resolveIframeEmbedUrls = Mem$Showside.mem(function (media) {
  return Belt_Array.map(media, function (mediaItem) {
    switch (mediaItem.TAG | 0) {
      case /* Image */0:
        var url = mediaItem._0.url;
        if (Belt_Array.some(Object.keys(IframeEmbed$Showside.knownEmbeds), function (known) {
          return url.startsWith(known);
        })) {
          return {
            TAG: /* IframeEmbed */2,
            _0: {
              url: url
            }
          };
        } else {
          return mediaItem;
        }
      case /* Video */1:
      case /* IframeEmbed */2:
        return mediaItem;
    }
  });
});
function fromDTOArray(dto) {
  return Curry._1(resolveIframeEmbedUrls, Belt_Array.map(Curry._1(resolveVideoUrls, dto), fromDTO));
}
var Media = {
  getImageUrl: getImageUrl,
  getImageUrlWithPosterFallback: getImageUrlWithPosterFallback,
  getVideoSource: getVideoSource,
  resolveVideoUrls: resolveVideoUrls,
  getVideoUrl: getVideoUrl,
  getImage: getImage,
  fromDTO: fromDTO,
  resolveIframeEmbedUrls: resolveIframeEmbedUrls,
  fromDTOArray: fromDTOArray
};
function getAllOptionValues(variant) {
  return Belt_Array.map(Js_dict.values(variant.options), function (option) {
    return option.value;
  });
}
function getOptionValue(variant, optionName) {
  return Belt_Option.map(Js_dict.get(variant.options, optionName), function (option) {
    return option.value;
  });
}
function getQuantity(variant) {
  var quantity = variant.availability;
  if (quantity) {
    return quantity._0;
  } else {
    return 0;
  }
}
function findAtPosition(array, position) {
  return Caml_option.undefined_to_opt(array.find(function (param, index) {
    return index === position;
  }));
}
function getVariantPrices(variant) {
  var price = variant.price;
  if (typeof price === "number") {
    return ["", undefined];
  }
  if (price.TAG === /* Regular */0) {
    return [price._0, undefined];
  }
  var match = price._0;
  return [match.regularPrice, match.price];
}
function getRealProductPurchasePrice(variant) {
  var match = getVariantPrices(variant);
  var price = Belt_Option.mapWithDefault(Currency$Showside.parse(match[0]), 0.0, function (price) {
    return price.value;
  });
  var salePrice = Belt_Option.mapWithDefault(Currency$Showside.parse(Belt_Option.getWithDefault(match[1], "")), price, function (price) {
    return price.value;
  });
  if (price < salePrice) {
    return price;
  } else {
    return salePrice;
  }
}
function tryParseMedia(dto, mediaList) {
  var mediaIndex = Belt_Option.getWithDefault(dto.mediaIndex, 0);
  var media = findAtPosition(fromDTOArray(mediaList), mediaIndex);
  if (media !== undefined) {
    return media;
  } else {
    return {
      TAG: /* Image */0,
      _0: {
        url: "",
        width: 0,
        height: 0
      }
    };
  }
}
function tryParseOtherMedia(dto, mediaList) {
  return fromDTOArray(Belt_Array.keepMap(Belt_Array.map(Belt_Option.getWithDefault(dto.mediaIndexesSourcedFromVariant, []), function (param) {
    return Belt_Array.get(mediaList, param);
  }), function (a) {
    return a;
  }));
}
function tryParsePrice(dto, currency) {
  var withCurrency = function (s) {
    return "" + s + "" + Belt_Option.mapWithDefault(currency, "", function (currency) {
      return " " + currency + "";
    }) + "";
  };
  var price = dto.price;
  var compareAtPrice = dto.compareAtPrice;
  if (compareAtPrice !== undefined) {
    if (price !== undefined) {
      return {
        TAG: /* Promotional */1,
        _0: {
          price: withCurrency(price),
          regularPrice: withCurrency(compareAtPrice)
        }
      };
    } else {
      return /* NoPrice */0;
    }
  } else if (price !== undefined) {
    return {
      TAG: /* Regular */0,
      _0: withCurrency(price)
    };
  } else {
    return /* NoPrice */0;
  }
}
function getVariantOptions(dto, options) {
  return Js_dict.fromArray(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Array.mapWithIndex([dto.option1, dto.option2, dto.option3], function (index, value) {
    return Belt_Option.map(value, function (value) {
      return [index, value];
    });
  }), function (a) {
    return a;
  }), function (param) {
    var findByOptionValue = param[1];
    var findByOptionIndex = param[0];
    var matchingOption = Belt_Array.get(Belt_Array.keepWithIndex(options, function (option, index) {
      return Belt_Array.some(option.values, function (value) {
        if (value === findByOptionValue) {
          return index === findByOptionIndex;
        } else {
          return false;
        }
      });
    }), 0);
    if (matchingOption === undefined) {
      return;
    }
    var name = matchingOption.name;
    return [name, {
      id: matchingOption.id,
      name: name,
      value: findByOptionValue
    }];
  }));
}
function tryParseFromDTO(dto, currency, mediaList, options) {
  var quantity = dto.inventory.quantity;
  var quantity$1 = Belt_Option.getWithDefault(quantity, 0);
  var availability = quantity$1 > 0 ? (/* InStock */{
    _0: quantity$1
  }) : /* OutOfStock */0;
  return {
    name: dto.name,
    description: dto.description,
    id: dto.id,
    price: tryParsePrice(dto, currency),
    availability: availability,
    inventory: quantity$1,
    media: tryParseMedia(dto, mediaList),
    otherMedia: tryParseOtherMedia(dto, mediaList),
    link: dto.link,
    promotionalText: dto.promoText,
    mpn: dto.mpn,
    sku: dto.sku,
    brand: dto.brand,
    customLabels: dto.customLabels.map(emptyStr),
    options: getVariantOptions(dto, options),
    metadata: dto.metadata
  };
}
var empty_media = {
  TAG: /* Image */0,
  _0: {
    url: "",
    width: 0,
    height: 0
  }
};
var empty_otherMedia = [];
var empty_customLabels = [];
var empty_options = {};
var empty_metadata = {};
var empty = {
  name: undefined,
  description: undefined,
  id: "",
  price: /* NoPrice */0,
  availability: /* OutOfStock */0,
  inventory: 0,
  media: empty_media,
  otherMedia: empty_otherMedia,
  link: undefined,
  promotionalText: undefined,
  mpn: undefined,
  sku: undefined,
  brand: undefined,
  customLabels: empty_customLabels,
  options: empty_options,
  metadata: empty_metadata
};
var Variant = {
  getAllOptionValues: getAllOptionValues,
  getOptionValue: getOptionValue,
  getQuantity: getQuantity,
  findAtPosition: findAtPosition,
  getVariantPrices: getVariantPrices,
  getRealProductPurchasePrice: getRealProductPurchasePrice,
  tryParseMedia: tryParseMedia,
  tryParseOtherMedia: tryParseOtherMedia,
  tryParsePrice: tryParsePrice,
  getVariantOptions: getVariantOptions,
  tryParseFromDTO: tryParseFromDTO,
  empty: empty
};
function isEmpty(text) {
  if (text.trim() === "") {
    return true;
  } else {
    return /^[^\w]$/.test(text);
  }
}
function isEmptyList(list) {
  if (Belt_Array.every(list, isEmpty)) {
    return true;
  } else {
    return list.length === 0;
  }
}
function toString(metadata) {
  var exit = 0;
  if (typeof metadata === "number") {
    return;
  }
  switch (metadata.TAG | 0) {
    case /* DecimalField */2:
    case /* IntegerField */3:
      return metadata._0.toString();
    case /* Boolean */8:
      return Pervasives.string_of_bool(metadata._0);
    case /* Json */9:
      return JSON.stringify(metadata._0);
    case /* Money */10:
      var money = metadata._0;
      return Belt_Option.mapWithDefault(Currency$Showside.parse(money.currencyCode + money.amount), "", Currency$Showside.format);
    case /* Dimension */4:
    case /* Volume */11:
    case /* Weight */12:
      exit = 2;
      break;
    case /* MultiLineTextField */0:
    case /* SingleLineTextField */1:
    case /* Date */5:
    case /* DateTime */6:
    case /* Color */7:
    case /* Url */13:
    case /* Rating */14:
      exit = 1;
      break;
    case /* FileReference */15:
      var fileType = metadata._0;
      var tmp;
      tmp = fileType.TAG === /* TypeImage */0 ? fileType._0.url : Belt_Option.getWithDefault(fileType._0.poster, "");
      return tmp;
    case /* Audio */16:
      return metadata._0.contentUrl;
    default:
      return;
  }
  switch (exit) {
    case 1:
      var string = metadata._0;
      if (!isEmpty(string)) {
        return string;
      } else {
        return;
      }
    case 2:
      var physicalQuantity = metadata._0;
      return physicalQuantity.value.toString() + " " + physicalQuantity.unitName;
  }
}
function getFieldWithPreprocess(metadata, field) {
  var preprocess = function (value) {
    var brightonParsed = CustomerBrighton$Showside.parseMetadata(field, value);
    if (brightonParsed !== undefined) {
      return brightonParsed;
    } else {
      return value;
    }
  };
  return Belt_Option.map(Js_dict.get(metadata, field), preprocess);
}
function getMetadata(product, variant) {
  var variantMetadata = variant.metadata;
  var productMetadata = product.metadata;
  if (Object.keys(variantMetadata).length === 0) {
    return productMetadata;
  } else {
    return variantMetadata;
  }
}
function getMetadataValue(product, variant, field) {
  return getFieldWithPreprocess(getMetadata(product, variant), field);
}
function getMetadataAsText(product, variant, field) {
  return Belt_Option.flatMap(getMetadataValue(product, variant, field), toString);
}
var Metadata = {
  isEmpty: isEmpty,
  isEmptyList: isEmptyList,
  toString: toString,
  getFieldWithPreprocess: getFieldWithPreprocess,
  getMetadata: getMetadata,
  getMetadataValue: getMetadataValue,
  getMetadataAsText: getMetadataAsText
};
function sortProductVariants(variants) {
  var orderedSets = [["XXS", "XXS-XS", "2XS-XS", "XXS/XS", "2XS/XS", "XS", "XS-S", "XS/S", "S", "S-M", "S/M", "M", "M-L", "M/L", "L", "L-XL", "L/XL", "XL", "XL-XXL", "XL-2XL", "XL/XXL", "XL/2XL", "XXL", "2XL", "XXL-XXXL", "2XL-3XL", "XXL/XXXL", "2XL/3XL", "XXXL", "3XL", "XXXL-XXXXL", "3XL-4XL", "XXXL/XXXXL", "3XL/4XL", "XXXXL", "4XL", "XXXXL-XXXXXL", "4XL-5XL", "XXXXL/XXXXXL", "4XL/5XL", "XXXXXL", "5XL", "6XL", "7XL", "Free Size", "One Size"], ["xxs", "xs", "s", "m", "l", "xl", "xxl", "2xl", "xxxl", "3xl", "xxxxl", "4xl", "xxxxxl", "5xl", "6xl", "7xl"], ["xxs", "xs", "s", "m", "l", "xl", "xxl", "xxxl", "xxxxl", "xxxxxl"], ["small", "medium", "large", "extra large", "extra extra large"], ["Small", "Medium", "Large", "X-Large", "2X-Large", "3X-Large", "4X-Large"], ["P", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14"], ["Single", "Queen", "King"], ["XXS / .", "XS / .", "S / .", "M / .", "L / .", "XL / .", "XXL / ."]];
  return Js_array.sortInPlaceWith(function (a, b) {
    var set = Belt_Array.getBy(orderedSets, function (set) {
      var foundA = Belt_Array.some(set, function (value) {
        return value === a;
      });
      var foundB = Belt_Array.some(set, function (value) {
        return value === b;
      });
      if (foundA) {
        return foundB;
      } else {
        return false;
      }
    });
    if (set === undefined) {
      return NaturalCompare$Showside.compare(a, b);
    }
    var indexA = Belt_Option.getWithDefault(Belt_Array.getIndexBy(set, function (value) {
      return value === a;
    }), 0);
    var indexB = Belt_Option.getWithDefault(Belt_Array.getIndexBy(set, function (value) {
      return value === b;
    }), 0);
    return indexA - indexB | 0;
  }, variants);
}
function getProductById(products, id) {
  return Caml_option.undefined_to_opt(products.find(function (product) {
    return product.id === id;
  }));
}
function getVideoSource$1(video) {
  var src = Belt_Array.getBy(video.sources, function (source) {
    return source.mimeType === "application/x-mpegURL";
  });
  var src$1 = src !== undefined ? src : Belt_Array.getBy(video.sources, function (source) {
    return source.mimeType === "video/mp4";
  });
  if (src$1 !== undefined) {
    return src$1;
  } else {
    return Belt_Array.get(video.sources, 0);
  }
}
function getVariantById(product, variantId) {
  return Caml_option.undefined_to_opt([product.firstVariant].concat(product.otherVariants).find(function (v) {
    return v.id === variantId;
  }));
}
function getAllVariants(prod) {
  return [prod.firstVariant].concat(prod.otherVariants);
}
function getVariantBySelector(product, selector) {
  var variants = Belt_Array.keep(getAllVariants(product), function (variants) {
    return variants.availability !== /* OutOfStock */0;
  });
  var tmp;
  switch (selector) {
    case /* FirstVariant */0:
      tmp = Belt_Array.get(variants, 0);
      break;
    case /* HighestPriceVariant */1:
      tmp = Belt_Array.get(variants.sort(function (a, b) {
        var priceA = getRealProductPurchasePrice(a);
        var priceB = getRealProductPurchasePrice(b);
        if (priceA === priceB) {
          return 0;
        } else if (priceA > priceB) {
          return -1;
        } else {
          return 1;
        }
      }), 0);
      break;
    case /* LowestPriceVariant */2:
      tmp = Belt_Array.get(variants.sort(function (a, b) {
        var priceA = getRealProductPurchasePrice(a);
        var priceB = getRealProductPurchasePrice(b);
        if (priceA === priceB) {
          return 0;
        } else if (priceA > priceB) {
          return 1;
        } else {
          return -1;
        }
      }), 0);
      break;
  }
  return Belt_Option.getWithDefault(tmp, product.firstVariant);
}
function getValidProductImages(product) {
  var isImageBigEnough = function (image) {
    var height = image.height;
    var width = image.width;
    if (width === 0 && height === 0) {
      return true;
    } else if (width > 40) {
      return height > 40;
    } else {
      return false;
    }
  };
  return Belt_Array.keepMap(product.allMedia, function (item) {
    switch (item.TAG | 0) {
      case /* Image */0:
        var image = item._0;
        if (isImageBigEnough(image)) {
          return image.url;
        } else {
          return;
        }
      case /* Video */1:
        return item._0.posterUrl;
      case /* IframeEmbed */2:
        return;
    }
  });
}
function getDisplaySwatchImage(product, variant) {
  var brightonCustomSwatch = CustomerBrighton$Showside.getDisplaySwatchImage(getMetadata(product, variant), variant.sku);
  if (brightonCustomSwatch !== undefined) {
    return brightonCustomSwatch;
  } else {
    return Belt_Option.getWithDefault(getImageUrlWithPosterFallback(variant.media), "");
  }
}
function zipTaggedProducts(products, taggedBy) {
  return Belt_Array.keepMap(Belt_Array.map(taggedBy, function (tag) {
    var pairWithProduct = Belt_Array.getBy(products, function (product) {
      return product.id === tag.productId;
    });
    if (pairWithProduct !== undefined) {
      return [tag, pairWithProduct];
    }
  }), function (p) {
    return p;
  });
}
function getVariantsByOptionName(product, optionName) {
  var allVariants = [product.firstVariant].concat(product.otherVariants);
  return Belt_Array.keep(allVariants, function (variant) {
    return Belt_Array.some(Belt_Array.map(Js_dict.entries(variant.options), function (param) {
      return param[1].value;
    }), function (vo) {
      return optionName === vo;
    });
  });
}
function keepVariantsByValues(inStockOpt, variants, optionValues) {
  var inStock = inStockOpt !== undefined ? inStockOpt : true;
  return Belt_Array.keep(variants, function (v) {
    if (v.availability !== /* OutOfStock */0 || !inStock) {
      return Belt_Array.some(getAllOptionValues(v), function (value) {
        return optionValues.includes(value);
      });
    } else {
      return false;
    }
  });
}
function getVariantsByOptionValue(inStockOpt, product, optionValue) {
  var inStock = inStockOpt !== undefined ? inStockOpt : true;
  return keepVariantsByValues(inStock, getAllVariants(product), [optionValue]);
}
function getVariantsByOptionNames(strictOpt, product, options) {
  var strict = strictOpt !== undefined ? strictOpt : false;
  var findBy = function (fn) {
    return Belt_Array.keep(getAllVariants(product), function (variant) {
      return Curry._1(fn, Js_dict.values(variant.options));
    });
  };
  var match = function (param) {
    var vo = param.value;
    var vn = param.name;
    return Belt_Array.some(options, function (param) {
      if (param.value === vo) {
        return param.name === vn;
      } else {
        return false;
      }
    });
  };
  if (strict) {
    return findBy(function (__x) {
      return Belt_Array.every(__x, match);
    });
  } else {
    return Belt_Array.concat(findBy(function (__x) {
      return Belt_Array.every(__x, match);
    }), findBy(function (__x) {
      return Belt_Array.some(__x, match);
    }));
  }
}
function getOptionsByName(product, optionName) {
  return Belt_Option.getWithDefault(Js_dict.get(product.options, optionName), []);
}
function getOptionsValue(product, optionName) {
  return Belt_Array.map(getOptionsByName(product, optionName), function (param) {
    return param.value;
  });
}
function getAllVariantMedia(hideMediaFromOtherVariants, product, variant) {
  var match = variant.otherMedia;
  if (hideMediaFromOtherVariants && match.length !== 0) {
    return match;
  }
  var nextIndex = Belt_Option.getWithDefault(Belt_Array.getIndexBy(product.allMedia, function (media) {
    return Caml_obj.equal(media, variant.media);
  }), 0);
  return Belt_Array.map(Belt_SortArray.stableSortBy(Belt_Array.mapWithIndex(product.allMedia, function (currIndex, media) {
    var indexDiff = currIndex - nextIndex | 0;
    var nextIndex$1 = indexDiff + (indexDiff < 0 ? product.allMedia.length : 0) | 0;
    return [nextIndex$1, media];
  }), function (param, param$1) {
    var nextIndexB = param$1[0];
    var nextIndexA = param[0];
    if (nextIndexA === nextIndexB) {
      return 0;
    } else if (nextIndexA < nextIndexB) {
      return -1;
    } else {
      return 1;
    }
  }), function (param) {
    return param[1];
  });
}
function getDisplayProductCardMedia(hideMediaFromOtherVariants, product, variant) {
  var allVariantMedia = variant !== undefined ? getAllVariantMedia(hideMediaFromOtherVariants, product, variant) : [];
  var mediaIndex;
  if (variant !== undefined) {
    var partial_arg = variant.media;
    mediaIndex = Belt_Option.getWithDefault(Belt_Array.getIndexBy(allVariantMedia, function (param) {
      if (Caml_obj.equal(getImageUrlWithPosterFallback(partial_arg), getImageUrlWithPosterFallback(param))) {
        return Caml_obj.equal(getVideoUrl(partial_arg), getVideoUrl(param));
      } else {
        return false;
      }
    }), 0);
  } else {
    mediaIndex = 0;
  }
  var firstMedia = Belt_Option.getWithDefault(Belt_Array.get(allVariantMedia, mediaIndex), {
    TAG: /* Image */0,
    _0: {
      url: "",
      width: 0,
      height: 0
    }
  });
  var secondMedia = Belt_Array.get(allVariantMedia, mediaIndex + 1 | 0);
  return [firstMedia, secondMedia];
}
function getDisplayName(store, variant, product) {
  if (variant !== undefined && !(typeof store === "number" || store.TAG !== /* Maker */0)) {
    return Belt_Option.getWithDefault(variant.name, product.name);
  } else {
    return product.name;
  }
}
function getInitialDisplayVariant(hideOutOfStock, activeVariantId, displayVariantBy, product) {
  var defaultVariant = getVariantBySelector(product, displayVariantBy);
  var selectedVariant = activeVariantId !== undefined ? Belt_Option.keep(getVariantById(product, activeVariantId), function (variant) {
    if (variant.availability !== /* OutOfStock */0) {
      return true;
    } else {
      return !hideOutOfStock;
    }
  }) : undefined;
  return Belt_Option.getWithDefault(selectedVariant, defaultVariant);
}
function dedupProducts(__x) {
  return UniqueArray$Showside.getUnique(__x, function (product) {
    return product.id;
  });
}
function expandVariantsByOptionNames(hideOutOfStock, appliedFilters, productsWithTag, optionNames) {
  return Belt_Array.concatMany(Belt_Array.map(productsWithTag, function (productWithTag) {
    var product = productWithTag[1];
    var existingTag = productWithTag[0];
    var existingTagId = Belt_Option.map(existingTag, function (tag) {
      return tag.id;
    });
    var existingVariantId = Belt_Option.flatMap(existingTag, function (tag) {
      return tag.variantId;
    });
    if (existingVariantId !== undefined) {
      return [productWithTag];
    }
    var options = Belt_Array.keepMap(optionNames, function (optionName) {
      return Js_dict.get(product.options, optionName);
    });
    if (options.length !== 0) {
      return Belt_Array.concatMany(Belt_Array.map(options, function (options) {
        return Belt_Array.keepMap(options, function (param) {
          var value = param.value;
          return Belt_Option.map(Belt_Option.map(Belt_Option.map(Belt_Array.getBy(Belt_Array.keep(getAllVariants(product), function (variant) {
            if (variant.availability !== /* OutOfStock */0) {
              return true;
            } else {
              return !hideOutOfStock;
            }
          }), function (variant) {
            return Belt_Array.some(Js_dict.values(variant.options), function (option) {
              return option.value === value;
            });
          }), function (variant) {
            return variant.id;
          }), function (variantId) {
            return {
              id: Belt_Option.getWithDefault(existingTagId, product.id + variantId),
              productId: product.id,
              variantId: variantId
            };
          }), function (tag) {
            return [tag, product];
          });
        });
      }));
    } else {
      return [productWithTag];
    }
  }));
}
function getPromoText(product, selectedVariant) {
  var variantPromoText = Belt_Option.flatMap(selectedVariant, function (v) {
    return v.promotionalText;
  });
  var everyVariantMissingPromoText = Belt_Array.every(getAllVariants(product), function (variant) {
    return Belt_Option.isNone(variant.promotionalText);
  });
  var noVariantIsSelected = selectedVariant === undefined;
  var fallbackToProductPromoText = everyVariantMissingPromoText || noVariantIsSelected;
  var productPromoText = fallbackToProductPromoText ? product.promotionalText : undefined;
  if (variantPromoText !== undefined) {
    return variantPromoText;
  } else if (productPromoText !== undefined) {
    return productPromoText;
  } else {
    return;
  }
}
function isOutOfStock(product) {
  return getAllVariants(product).every(function (v) {
    return v.availability === /* OutOfStock */0;
  });
}
function isInStock(product) {
  return Belt_Array.some(getAllVariants(product), function (v) {
    var match = v.availability;
    if (match) {
      return true;
    } else {
      return false;
    }
  });
}
function keepInStock(product, hideOutOfStock) {
  return Belt_Option.keep(product, function (product) {
    if (isInStock(product)) {
      return true;
    } else {
      return !hideOutOfStock;
    }
  });
}
function toLegacyDTO(product, variant) {
  var price = variant.price;
  var tmp;
  tmp = typeof price === "number" ? "" : price.TAG === /* Regular */0 ? price._0 : price._0.regularPrice;
  var match = variant.price;
  var tmp$1;
  tmp$1 = typeof match === "number" || match.TAG === /* Regular */0 ? "" : match._0.price;
  var match$1 = variant.media;
  var tmp$2;
  switch (match$1.TAG | 0) {
    case /* Image */0:
      var match$2 = match$1._0;
      tmp$2 = [match$2.width, match$2.height];
      break;
    case /* Video */1:
    case /* IframeEmbed */2:
      tmp$2 = undefined;
      break;
  }
  var match$3 = variant.media;
  var tmp$3;
  switch (match$3.TAG | 0) {
    case /* Image */0:
      tmp$3 = match$3._0.url;
      break;
    case /* Video */1:
    case /* IframeEmbed */2:
      tmp$3 = "";
      break;
  }
  var match$4 = variant.link;
  var match$5 = product.link;
  return {
    title: product.name,
    size: Belt_Option.getWithDefault(getOptionValue(variant, "Size"), ""),
    color: Belt_Option.getWithDefault(getOptionValue(variant, "Color"), ""),
    pattern: Belt_Option.getWithDefault(getOptionValue(variant, "Pattern"), ""),
    material: Belt_Option.getWithDefault(getOptionValue(variant, "Material"), ""),
    price: tmp,
    style: Belt_Option.getWithDefault(getOptionValue(variant, "Style"), ""),
    salePrice: tmp$1,
    description: Belt_Option.getWithDefault(Belt_Option.orElse(variant.description, product.description), ""),
    groupId: product.id,
    id: "" + product.id + "_" + variant.id + "",
    imageSize: tmp$2,
    additionalImageSizes: product.allMedia.length > 0 ? product.allMedia.filter(function (media) {
      switch (media.TAG | 0) {
        case /* Image */0:
          return true;
        case /* Video */1:
        case /* IframeEmbed */2:
          return false;
      }
    }).map(function (media) {
      switch (media.TAG | 0) {
        case /* Image */0:
          var match = media._0;
          return [match.width, match.height];
        case /* Video */1:
        case /* IframeEmbed */2:
          return;
      }
    }) : undefined,
    imageLink: tmp$3,
    moreImageLinks: getAllVariants(product).filter(function (v) {
      return Caml_obj.notequal(v, variant);
    }).map(function (v) {
      return v.link;
    }).join(","),
    customLabel0: Belt_Option.flatMap(Belt_Array.get(variant.customLabels, 0), noneIfEmpty),
    customLabel1: Belt_Option.flatMap(Belt_Array.get(variant.customLabels, 1), noneIfEmpty),
    customLabel2: Belt_Option.flatMap(Belt_Array.get(variant.customLabels, 2), noneIfEmpty),
    customLabel3: Belt_Option.flatMap(Belt_Array.get(variant.customLabels, 3), noneIfEmpty),
    customLabel4: Belt_Option.flatMap(Belt_Array.get(variant.customLabels, 4), noneIfEmpty),
    customLabel5: Belt_Option.flatMap(Belt_Array.get(variant.customLabels, 5), noneIfEmpty),
    customLabel6: Belt_Option.flatMap(Belt_Array.get(variant.customLabels, 6), noneIfEmpty),
    customLabel7: Belt_Option.flatMap(Belt_Array.get(variant.customLabels, 7), noneIfEmpty),
    customLabel8: Belt_Option.flatMap(Belt_Array.get(variant.customLabels, 8), noneIfEmpty),
    customLabel9: Belt_Option.flatMap(Belt_Array.get(variant.customLabels, 9), noneIfEmpty),
    category: undefined,
    productType: undefined,
    availability: variant.availability === /* OutOfStock */0 ? "out_of_stock" : "in_stock",
    link: match$4 !== undefined ? match$4 : match$5 !== undefined ? match$5 : undefined,
    cursor: undefined,
    originalData: undefined,
    promoText: undefined,
    mediaIndex: undefined,
    brand: variant.brand,
    mpn: variant.mpn,
    sku: variant.sku,
    tags: product.tags.length !== 0 ? product.tags : undefined,
    metadata: Caml_option.some(getMetadata(product, variant)),
    currency: product.currency
  };
}
function parseOptions(options, variants) {
  return Js_dict.fromArray(Belt_Array.map(options, function (option) {
    return [option.name, Belt_Array.map(sortProductVariants(option.values), function (value) {
      return {
        id: option.id,
        name: option.name,
        value: value
      };
    })];
  }));
}
function matchesFilter(variant, options, filters) {
  var variantFacets = Belt_Array.concat(Belt_Array.map(Js_dict.entries(getVariantOptions(variant, options)), function (param) {
    return param[0].toLowerCase() + "=" + param[1].value;
  }), Belt_Array.concatMany(Belt_Array.map(Js_dict.entries(variant.metadata), function (param) {
    var key = param[0];
    return Belt_Array.map(Belt_Array.map(Belt_Option.mapWithDefault(toString(param[1]), [], function (__x) {
      return __x.split(",");
    }), function (prim) {
      return prim.trim();
    }), function (value) {
      return "metadata::" + key + "=" + value;
    });
  })));
  var filterFacets = Belt_Option.keep(Belt_Option.map(filters, function (f) {
    return Belt_Array.keepMap(f.entries, function (f) {
      var match = f.facet.split("=");
      if (match.length !== 2) {
        return;
      }
      var facet = match[0];
      if (facet === "color" || facet === "size" || facet === "material" || facet === "pattern" || facet === "style" || facet.startsWith("metadata::gfeed::")) {
        return f.facet;
      }
    });
  }), function (f) {
    return f.length !== 0;
  });
  if (filterFacets !== undefined) {
    return variantFacets.some(function (facet) {
      return filterFacets.includes(facet);
    });
  } else {
    return true;
  }
}
function tryParseFromDTO$1(appliedFilters, hideOutOfStockOpt, categoryId, dto) {
  var hideOutOfStock = hideOutOfStockOpt !== undefined ? hideOutOfStockOpt : true;
  var currency = Belt_Option.map(dto.currency, function (prim) {
    return prim.toUpperCase();
  });
  var filterBadVariants = function (variants) {
    var match = variants.length;
    if (match === 0 || match === 1) {
      return variants;
    } else {
      return Belt_Array.keep(variants, function (variant) {
        if (isNotNoneOrEmpty(variant.option1) || isNotNoneOrEmpty(variant.option2)) {
          return true;
        } else {
          return isNotNoneOrEmpty(variant.option3);
        }
      });
    }
  };
  var filteredVariants = filterBadVariants(Belt_Array.keep(dto.variants, function (v) {
    if (!hideOutOfStock) {
      return true;
    }
    var n = v.inventory.quantity;
    if (n !== undefined) {
      return n > 0;
    } else {
      return false;
    }
  }));
  var filteredVariants$1 = Belt_Array.keep(filteredVariants, function (v) {
    return matchesFilter(v, dto.options, appliedFilters);
  });
  var match = Belt_List.fromArray(filteredVariants$1);
  if (!match) {
    return {
      id: Curry._1(StoreId$ApiTypes.ProductId.toString, dto.id),
      productType: Belt_Option.getWithDefault(dto.productType, ""),
      categoryId: Belt_Option.getWithDefault(Belt_Option.orElse(dto.categoryId, categoryId), ""),
      name: Belt_Option.getWithDefault(dto.name, ""),
      description: dto.description,
      currency: Belt_Option.getWithDefault(currency, ""),
      link: dto.link,
      reviewSummary: Belt_Option.map(dto.reviewSummary, function (summary) {
        return {
          count: summary.count,
          average: summary.average
        };
      }),
      promotionalText: dto.promoText,
      allMedia: fromDTOArray(dto.media),
      firstVariant: empty,
      otherVariants: [],
      tags: dto.tags,
      metadata: dto.metadata,
      options: {}
    };
  }
  var firstVariant = tryParseFromDTO(match.hd, currency, dto.media, dto.options);
  var arg = dto.media;
  var arg$1 = dto.options;
  var otherVariants = Belt_Array.map(Belt_List.toArray(match.tl), function (param) {
    return tryParseFromDTO(param, currency, arg, arg$1);
  });
  var allVariants = [firstVariant].concat(otherVariants);
  var options = parseOptions(dto.options, allVariants);
  return {
    id: Curry._1(StoreId$ApiTypes.ProductId.toString, dto.id),
    productType: Belt_Option.getWithDefault(dto.productType, ""),
    categoryId: Belt_Option.getWithDefault(Belt_Option.orElse(dto.categoryId, categoryId), ""),
    name: Belt_Option.getWithDefault(dto.name, ""),
    description: dto.description,
    currency: Belt_Option.getWithDefault(currency, ""),
    link: dto.link,
    reviewSummary: Belt_Option.map(dto.reviewSummary, function (summary) {
      return {
        count: summary.count,
        average: summary.average
      };
    }),
    promotionalText: dto.promoText,
    allMedia: fromDTOArray(dto.media),
    firstVariant: firstVariant,
    otherVariants: otherVariants,
    tags: dto.tags,
    metadata: dto.metadata,
    options: options
  };
}
function getKey(variant, product) {
  return Belt_Array.keepMap([product.id, product.currency, product.name, Object.keys(product.options).join("-"), product.firstVariant.id, Belt_Option.map(variant, function (variant) {
    return variant.id;
  })], function (a) {
    return a;
  }).join(":");
}
export { emptyStr, noneIfEmpty, isNotNoneOrEmpty, DTOHelpers, Media, Variant, Metadata, sortProductVariants, getProductById, getVideoSource$1 as getVideoSource, getVariantById, getAllVariants, getVariantBySelector, getValidProductImages, getDisplaySwatchImage, zipTaggedProducts, getVariantsByOptionName, keepVariantsByValues, getVariantsByOptionValue, getVariantsByOptionNames, getOptionsByName, getOptionsValue, getAllVariantMedia, getDisplayProductCardMedia, getDisplayName, getInitialDisplayVariant, dedupProducts, expandVariantsByOptionNames, getPromoText, isOutOfStock, isInStock, keepInStock, toLegacyDTO, parseOptions, matchesFilter, tryParseFromDTO$1 as tryParseFromDTO, getKey };
/* resolveVideoUrls Not a pure module */