// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Env$Showside from "../utils/Env.bs.js";
import * as Klaviyo$Showside from "../utils/Klaviyo.bs.js";
import * as Product$Showside from "../types/Product.bs.js";
import * as Category$Showside from "../types/Category.bs.js";
import * as URLUtils$Showside from "../utils/URLUtils.bs.js";
import * as CleverTap$Showside from "../utils/CleverTap.bs.js";
import * as GoogleAds$Showside from "../utils/GoogleAds.bs.js";
import * as WebEngage$Showside from "../utils/WebEngage.bs.js";
import * as EventTypes$Showside from "../types/EventTypes.bs.js";
import * as LCPContext$Showside from "../context/LCPContext.bs.js";
import * as PinterestTag$Showside from "../utils/PinterestTag.bs.js";
import * as FacebookPixel$Showside from "../utils/FacebookPixel.bs.js";
import * as RouterProvider$Showside from "../context/RouterProvider.bs.js";
import * as UseGoogleAnalytics$Showside from "./UseGoogleAnalytics.bs.js";
import * as UseFirebaseAnalytics$Showside from "./UseFirebaseAnalytics.bs.js";
import * as ConfigurationProvider$Showside from "../context/ConfigurationProvider.bs.js";
import * as UseAnalyticsTimeTracking$Showside from "./UseAnalyticsTimeTracking.bs.js";
import * as ExternalIntegrationProvider$Showside from "../context/ExternalIntegrationProvider.bs.js";
function getEventData(category, parentCategory, product, toProduct, link, $$event) {
  var categoryTitle = parentCategory !== undefined ? category !== undefined ? parentCategory.title + ":" + category.title : undefined : category !== undefined ? category.title : undefined;
  var label = product !== undefined ? product.id : "";
  var label$1 = toProduct !== undefined ? label + "|" + toProduct.id : label;
  var label$2 = categoryTitle !== undefined ? categoryTitle + ":" + label$1 : label$1;
  var prefix = "maker smart nav";
  var category$1 = prefix + ":" + link;
  var action = prefix + ":" + $$event;
  var label$3 = label$2 === "" ? action : action + ":" + label$2;
  return {
    category: category$1,
    action: action,
    label: label$3,
    categoryTitle: categoryTitle
  };
}
function use(isLauncherOpt, isNavMenuOpt, param) {
  var isLauncher = isLauncherOpt !== undefined ? isLauncherOpt : false;
  var isNavMenu = isNavMenuOpt !== undefined ? isNavMenuOpt : false;
  var match = RouterProvider$Showside.useRouter(undefined);
  var match$1 = ConfigurationProvider$Showside.use(undefined);
  var config = match$1.config;
  var match$2 = LCPContext$Showside.use(undefined);
  var isMainImageRendered = match$2.isMainImageRendered;
  var link = URLUtils$Showside.getCanonical(undefined);
  var match$3 = ExternalIntegrationProvider$Showside.use(undefined);
  var trackers = match$3.trackers;
  var isOpen = typeof match.route === "number" ? false : true;
  var trackGa = UseGoogleAnalytics$Showside.use(isMainImageRendered);
  var trackFirebase = UseFirebaseAnalytics$Showside.use(config.id, Belt_Option.getWithDefault(config.accountId, ""), link, isMainImageRendered, match$3.isInline, isLauncher, isNavMenu, undefined, undefined, undefined, undefined, undefined);
  var track = React.useCallback(function ($$event) {
    return function (shouldTrackConfigDTOOpt, category, product, variant, toProduct, time, isMiniPDPVisibleOpt, mediaId, mediaParentId, mediaSrc, mediaTitle, relatedMediaCategoryId, relatedMediaProductIds, param) {
      var shouldTrackConfigDTO = shouldTrackConfigDTOOpt !== undefined ? shouldTrackConfigDTOOpt : false;
      var isMiniPDPVisible = isMiniPDPVisibleOpt !== undefined ? isMiniPDPVisibleOpt : false;
      var eventString = EventTypes$Showside.toString($$event);
      var parentCategory = shouldTrackConfigDTO ? Belt_Option.flatMap(category, function (category) {
        return Category$Showside.getCategoryParentById(config.categories, category.id);
      }) : undefined;
      var match = getEventData(category, parentCategory, product, toProduct, link, eventString);
      var categoryTitle = match.categoryTitle;
      var label = match.label;
      var action = match.action;
      var label$1;
      if (typeof $$event === "object") {
        var variant$1 = $$event.NAME;
        label$1 = variant$1 === "sort applied" || variant$1 === "filter applied" ? $$event.VAL : label;
      } else {
        label$1 = label;
      }
      if (Env$Showside.isTest(undefined)) {
        return;
      }
      var whitelist = Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(config.transitiveAccount, function (a) {
        return a.eventsWhitelist;
      }), "").split(","), function (item) {
        var value = item.trim();
        if (value === "") {
          return;
        } else {
          return value;
        }
      });
      var shouldTrack = whitelist.length === 0 || Belt_Array.some(whitelist, function (item) {
        return item === action;
      });
      if (shouldTrack) {
        Curry._6(trackGa, $$event, match.category, action, label$1, time, undefined);
      }
      Curry.app(trackFirebase, [$$event, eventString, product, variant, categoryTitle, time, isMiniPDPVisible, mediaId, mediaParentId, mediaSrc, mediaTitle, relatedMediaCategoryId, relatedMediaProductIds]);
      return Belt_Array.forEach(Belt_Option.getWithDefault(trackers, []), function (tracker) {
        Curry._4(tracker, eventString, Belt_Option.map(product, function (product) {
          return Product$Showside.toLegacyDTO(product, Belt_Option.getWithDefault(variant, product.firstVariant));
        }), categoryTitle, time);
      });
    };
  }, [link, trackGa, trackFirebase, trackers]);
  React.useEffect(function () {
    if (isMainImageRendered) {
      Curry.app(track, ["page view", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined]);
    }
  }, [isMainImageRendered]);
  React.useEffect(function () {
    var account = config.transitiveAccount;
    if (account !== undefined) {
      FacebookPixel$Showside.init(account, config.id);
      GoogleAds$Showside.init(account);
      PinterestTag$Showside.init(account);
    }
  }, []);
  UseAnalyticsTimeTracking$Showside.use(config, isOpen, track);
  return track;
}
function trackRetargeting(account, configId, category, product, variant, isMobile, index, indexOffsetOpt, quantityOpt, productsOpt, productCount, $$event) {
  var indexOffset = indexOffsetOpt !== undefined ? indexOffsetOpt : 0;
  var quantity = quantityOpt !== undefined ? quantityOpt : 1;
  var products = productsOpt !== undefined ? productsOpt : [];
  if ($$event === "view_item_list") {
    return GoogleAds$Showside.trackViewItemList(account, category, products, indexOffset, undefined);
  } else if ($$event === "view_item") {
    if (product !== undefined && variant !== undefined) {
      FacebookPixel$Showside.trackViewContent(account, configId, product, variant, category, undefined);
      GoogleAds$Showside.trackViewItem(account, category, product, variant, undefined);
      Klaviyo$Showside.trackViewContent(product, variant, category, undefined);
      PinterestTag$Showside.trackViewContent(category, product, variant, undefined);
      WebEngage$Showside.track("Product Viewed", category, product, variant, products, undefined, undefined);
      return CleverTap$Showside.track("product_view", category, product, variant, isMobile, products, undefined, undefined);
    } else {
      return;
    }
  } else if ($$event === "select_item") {
    if (product !== undefined && variant !== undefined) {
      return GoogleAds$Showside.trackSelectItem(account, index, category, product, variant, undefined);
    } else {
      return;
    }
  } else if ($$event === "add_to_cart") {
    if (product !== undefined && variant !== undefined) {
      FacebookPixel$Showside.trackAddToCart(account, configId, product, variant, category, quantity, undefined);
      GoogleAds$Showside.trackAddToCart(account, category, product, variant, quantity, undefined);
      Klaviyo$Showside.trackAddToCart(product, variant, category, quantity, undefined);
      return PinterestTag$Showside.trackAddToCart(category, product, variant, quantity, undefined);
    } else {
      return;
    }
  } else {
    WebEngage$Showside.track("Category Viewed", category, undefined, undefined, products, productCount, undefined);
    return CleverTap$Showside.track("category_view", category, undefined, undefined, isMobile, products, productCount, undefined);
  }
}
export { getEventData, use, trackRetargeting };
/* react Not a pure module */