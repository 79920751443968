// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Int from "../../../../node_modules/rescript/lib/es6/belt_Int.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "../../../../node_modules/rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Tap$Showside from "./Tap.bs.js";
import * as TransformParser from "transform-parser";

function fromCssStyleDeclatation(cssStyleDeclaration) {
  return {
          top: Belt_Option.getWithDefault(Belt_Int.fromString(cssStyleDeclaration.top), 0),
          marginTop: Belt_Option.getWithDefault(Belt_Int.fromString(cssStyleDeclaration.marginTop), 0),
          transform: TransformParser.parse(cssStyleDeclaration.transform),
          transitionDuration: Belt_Option.getWithDefault(Belt_Float.fromString(cssStyleDeclaration.transitionDuration), 0.0),
          transitionDelay: Belt_Option.getWithDefault(Belt_Float.fromString(cssStyleDeclaration.transitionDelay), 0.0)
        };
}

function fromString(style) {
  return Js_dict.fromArray(Belt_Array.keepMap(Belt_Array.map(style.split(";"), (function (property) {
                        var keyValue = property.split(":");
                        var key = Belt_Array.get(keyValue, 0);
                        var value = Belt_Array.get(keyValue, 1);
                        if (key !== undefined && value !== undefined) {
                          return [
                                  key,
                                  value
                                ];
                        }
                        
                      })), (function (a) {
                    return a;
                  })));
}

function toString(dict) {
  return Belt_Array.map(Object.keys(dict), (function (key) {
                  return Belt_Option.mapWithDefault(Js_dict.get(dict, key), "", (function (value) {
                                return [
                                          key,
                                          value
                                        ].join(":");
                              }));
                })).join(";");
}

var StyleDeclaration = {
  fromCssStyleDeclatation: fromCssStyleDeclatation,
  fromString: fromString,
  toString: toString
};

function setStyle(element, property, value) {
  var currentStyle = element.getAttribute("style");
  if (currentStyle == null) {
    element.setAttribute("style", toString(Js_dict.fromArray([[
                    property,
                    value
                  ]])));
  } else {
    var modifiedStyle = toString(Tap$Showside.tap(fromString(currentStyle), (function (a) {
                a[property] = value;
              })));
    element.setAttribute("style", modifiedStyle);
  }
  return element;
}

function bind(captureOpt, passiveOpt, onceOpt, $$window, events, callback) {
  var capture = captureOpt !== undefined ? captureOpt : false;
  var passive = passiveOpt !== undefined ? passiveOpt : true;
  var once = onceOpt !== undefined ? onceOpt : false;
  Belt_Array.forEach(events, (function ($$event) {
          $$window.addEventListener($$event, callback, {
                capture: capture,
                passive: passive,
                once: once
              });
        }));
}

function unbind(captureOpt, passiveOpt, $$window, events, callback) {
  var capture = captureOpt !== undefined ? captureOpt : false;
  var passive = passiveOpt !== undefined ? passiveOpt : true;
  Belt_Array.forEach(events, (function ($$event) {
          $$window.removeEventListener($$event, callback, {
                capture: capture,
                passive: passive
              });
        }));
}

function getScrollYWithATerribleHack () {
      switch (typeof(window.scrollY)) {
        case "number":
          // this where 99% of the calls to this function would end.
          return window.scrollY
          break;
        case "function":
          // this the other 1% of calls where window.scrollY was allegedly replaced with a function
          return window.scrollY()
          break;
        default:
          // otherwise, we don't know what to do at this point.
          return 0.0;
      }
    }
;

var scrollY = getScrollYWithATerribleHack;

var $$Window = {
  bind: bind,
  unbind: unbind,
  scrollY: scrollY
};

function setAttribute(element, name, value) {
  if (value !== undefined) {
    element.setAttribute(name, value);
  }
  return element;
}

var $$Element = {
  setAttribute: setAttribute
};

export {
  StyleDeclaration ,
  setStyle ,
  $$Window ,
  $$Element ,
}
/*  Not a pure module */
