// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Mem$Showside from "./Mem.bs.js";
function fromDict(dict) {
  return "?" + Belt_Array.keepMap(Belt_Array.map(dict, function (entry) {
    var key = entry[0];
    return Belt_Option.map(entry[1], function (value) {
      return key + "=" + value;
    });
  }), function (a) {
    return a;
  }).join("&");
}
var toURLSearchParams = Mem$Showside.mem(function (query) {
  return new URLSearchParams(query);
});
export { fromDict, toURLSearchParams };
/* toURLSearchParams Not a pure module */