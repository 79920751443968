// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Product$Showside from "../types/Product.bs.js";
import * as Shopify$Showside from "./Shopify.bs.js";
import * as EventTypes$Showside from "../types/EventTypes.bs.js";

function make(configId, categoryTitle, product, variant, time, link, isInline, isLauncher, isNavMenu, navVersionOpt, currentStory, storyTitle, partnerId, breakpoint, accountId, isMiniPDPVisible, $$event, mediaId, mediaParentId, mediaSrc, mediaTitle, relatedMediaCategoryId, relatedMediaProductIds, param) {
  var navVersion = navVersionOpt !== undefined ? navVersionOpt : "v1";
  return {
          sheetId: configId,
          toProductCategory: categoryTitle,
          toProductColor: Belt_Option.flatMap(variant, (function (variant) {
                  return Product$Showside.Variant.getOptionValue(variant, "Color");
                })),
          toProductGroupId: undefined,
          toProductId: Belt_Option.map(product, (function (product) {
                  return product.id;
                })),
          toProductMaterial: Belt_Option.flatMap(variant, (function (variant) {
                  return Product$Showside.Variant.getOptionValue(variant, "Material");
                })),
          toProductPattern: Belt_Option.flatMap(variant, (function (variant) {
                  return Product$Showside.Variant.getOptionValue(variant, "Pattern");
                })),
          toProductPrice: Belt_Option.flatMap(variant, (function (variant) {
                  var price = variant.price;
                  if (typeof price === "number") {
                    return ;
                  } else if (price.TAG === /* Regular */0) {
                    return price._0;
                  } else {
                    return price._0.price;
                  }
                })),
          toProductSize: Belt_Option.flatMap(variant, (function (variant) {
                  return Product$Showside.Variant.getOptionValue(variant, "Size");
                })),
          toProductTitle: Belt_Option.map(product, (function (product) {
                  return product.name;
                })),
          timeOnQuickNav: time,
          parentUrl: link,
          affiliation: Shopify$Showside.getShop(undefined),
          current_story: currentStory,
          story_title: storyTitle,
          partner_id: partnerId,
          account_id: accountId,
          breakpoint: breakpoint,
          isInline: isInline,
          isLauncher: isLauncher,
          isNavMenu: isNavMenu,
          navVersion: navVersion,
          isMiniPDPVisible: isMiniPDPVisible,
          non_interaction: Belt_Option.map($$event, EventTypes$Showside.isNonInteractive),
          mediaId: mediaId,
          mediaParentId: mediaParentId,
          mediaSrc: mediaSrc,
          mediaTitle: mediaTitle,
          relatedMediaCategoryId: relatedMediaCategoryId,
          relatedMediaProductIds: relatedMediaProductIds
        };
}

export {
  make ,
}
/* Product-Showside Not a pure module */
