// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../rescript/lib/es6/curry.js";
import * as Caml_exceptions from "../../../../../rescript/lib/es6/caml_exceptions.js";

var JsError = /* @__PURE__ */Caml_exceptions.create("Promise.JsError");

function $$catch(promise, callback) {
  return promise.catch(function (err) {
              return Curry._1(callback, Caml_exceptions.is_extension(err) ? err : ({
                              RE_EXN_ID: JsError,
                              _1: err
                            }));
            });
}

function allSettled(arr) {
  return Promise.allSettled(arr).then(function (values) {
              return values.map(function (val) {
                          var match = val.status;
                          if (match === "rejected") {
                            return {
                                    TAG: /* Rejected */1,
                                    _0: val.reason
                                  };
                          } else {
                            return {
                                    TAG: /* Fulfilled */0,
                                    _0: val.value
                                  };
                          }
                        });
            });
}

export {
  JsError ,
  $$catch ,
  allSettled ,
}
/* No side effect */
