// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Float from "../../../../node_modules/rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";

function waitForWebEngage(retryOpt, param) {
  var retry = retryOpt !== undefined ? retryOpt : 0;
  var onReady = Belt_Option.map(window.webengage, (function (prim0, prim1) {
          prim0.onReady(prim1);
        }));
  return new Promise((function (resolve, param) {
                var match = window.webengage;
                var match$1 = retry < 50;
                if (match !== undefined && onReady !== undefined) {
                  var webengage = Caml_option.valFromOption(match);
                  return Curry._1(onReady, (function (param) {
                                resolve(webengage);
                              }));
                }
                if (match$1) {
                  setTimeout((function (param) {
                          waitForWebEngage(retry + 1 | 0, undefined).then(function (webengage) {
                                resolve(webengage);
                              });
                        }), 100);
                  return ;
                }
                
              }));
}

function getEventName(eventName) {
  return eventName + " Nav";
}

function track(eventName, category, product, variant, productsOpt, productCount, param) {
  var products = productsOpt !== undefined ? productsOpt : [];
  var $$event;
  if (eventName === "Product Viewed") {
    if (product !== undefined && variant !== undefined) {
      var match = variant.media;
      var tmp;
      switch (match.TAG | 0) {
        case /* Image */0 :
            tmp = match._0.url;
            break;
        case /* Video */1 :
            tmp = match._0.posterUrl;
            break;
        case /* IframeEmbed */2 :
            tmp = undefined;
            break;
        
      }
      var price = variant.price;
      var tmp$1;
      tmp$1 = typeof price === "number" ? 0.0 : (
          price.TAG === /* Regular */0 ? Belt_Option.getWithDefault(Belt_Float.fromString(price._0), 0.0) : Belt_Option.getWithDefault(Belt_Float.fromString(price._0.price), 0.0)
        );
      $$event = {
        NAME: "Product Viewed",
        VAL: {
          Available: variant.availability !== /* OutOfStock */0,
          Currency: product.currency,
          ID: variant.id,
          Image: tmp,
          Price: tmp$1,
          Source: "",
          Title: product.name,
          "Total Variants": product.otherVariants.length + 1 | 0
        }
      };
    } else {
      $$event = undefined;
    }
  } else if (category !== undefined) {
    var match$1 = category.card;
    var tmp$2;
    switch (match$1.TAG | 0) {
      case /* ImageLinkCard */0 :
          tmp$2 = match$1._0.imageUrl;
          break;
      case /* VideoLinkCard */1 :
          tmp$2 = match$1._0.fallbackImageUrl;
          break;
      case /* ImageCard */2 :
          tmp$2 = match$1._0.imageUrl;
          break;
      case /* VideoCard */3 :
          tmp$2 = match$1._0.fallbackImageUrl;
          break;
      case /* MedialessCard */4 :
      case /* MedialessLinkCard */5 :
          tmp$2 = undefined;
          break;
      case /* ImageDisplayCard */6 :
          tmp$2 = match$1._0.imageUrl;
          break;
      case /* VideoDisplayCard */7 :
          tmp$2 = match$1._0.fallbackImageUrl;
          break;
      
    }
    $$event = {
      NAME: "Category Viewed",
      VAL: {
        Id: category.id,
        Image: tmp$2,
        "Product Count": Belt_Option.getWithDefault(productCount, products.length),
        Source: "",
        Title: category.title
      }
    };
  } else {
    $$event = undefined;
  }
  waitForWebEngage(undefined, undefined).then(function (webengage) {
        if ($$event !== undefined) {
          if ($$event.NAME === "Product Viewed") {
            webengage.track("Product Viewed" + " Nav", $$event.VAL);
          } else {
            webengage.track("Category Viewed" + " Nav", $$event.VAL);
          }
          return ;
        }
        
      });
}

export {
  waitForWebEngage ,
  getEventName ,
  track ,
}
/* No side effect */
