// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "../../../node_modules/rescript-classnames/lib/es6_global/src/Cn.bs.js";
import * as React from "react";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Base$Showside from "./Base.bs.js";
import * as Theme$Showside from "./types/Theme.bs.js";

function BaseWithTheme(Props) {
  var style = Props.style;
  var theme = Props.theme;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Cn.make([
          Base$Showside.getClassName(undefined),
          className
        ]),
    children: children
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (theme !== undefined) {
    tmp.theme = Caml_option.valFromOption(theme);
  }
  return React.createElement(Theme$Showside.make, tmp);
}

var make = BaseWithTheme;

export {
  make ,
}
/* react Not a pure module */
