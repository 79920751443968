// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "../../../../../rescript/lib/es6/caml_obj.js";

var PanelPosition = {};

var PanelSize = {};

var PDPCTAConfig = {};

var emptyNode_children = [];

var emptyNode_googleFeedIds = [];

var emptyNode = {
  kind: /* Manual */0,
  collectionId: undefined,
  connection: undefined,
  title: "",
  id: "",
  socialNetworkSource: undefined,
  children: emptyNode_children,
  loadMoreChildren: undefined,
  totalChildren: 0,
  link: /* NotLinked */0,
  image: "",
  imageWidth: undefined,
  imageHeight: undefined,
  imageHotspots: undefined,
  icon: undefined,
  description: undefined,
  shortDescription: undefined,
  longDescription: undefined,
  video: "",
  googleFeedIds: emptyNode_googleFeedIds,
  taggedMedia: undefined,
  visible: true,
  visibleInMenuBar: true,
  visibleInSectionList: true,
  visibleInParent: undefined,
  visibleInHeaderSlider: true,
  layout: /* Default */0,
  internalLayout: /* Default */0,
  cardShape: /* Default */0,
  cardSize: "default",
  showTitle: true,
  showDescription: true,
  sliderRows: 1,
  gridMaxRows: undefined,
  gridPattern: undefined,
  firstCardMedia: "",
  firstCardLink: "",
  firstCardVisible: undefined,
  breakpoints: undefined,
  tabbedSubCategories: undefined,
  createdAt: undefined,
  quickViewStories: false,
  templateType: undefined,
  templateOrigin: undefined,
  lastModifiedAt: 0.0,
  displayPriority: /* ChildrenFirst */0,
  expandVariantsInProductsListing: undefined
};

var newrecord = Caml_obj.obj_dup(emptyNode);

newrecord.visibleInMenuBar = false;

newrecord.image = "https://smartnav-image-uploads.s3-us-west-1.amazonaws.com/wTlmzrGjB.png";

newrecord.id = "index";

newrecord.title = "All";

var defaults_pdpCtaButtons = [
  "open_link",
  undefined,
  undefined
];

var defaults_filtersLabelOrder = [
  "price",
  "tag",
  "size",
  "color",
  "pattern",
  "material"
];

var defaults = {
  panelPosition: "right",
  panelSize: "small",
  backdrop: true,
  backdropColor: "rgba(0,0,0,0.2)",
  backdropBlur: true,
  primaryColor: "#000000",
  secondaryColor: "#374151",
  tertiaryColor: "#6B7280",
  accentColor: "#EA526F",
  backgroundColor: "#ffffff",
  productPromoBackgroundColor: "#F3F4F6",
  productPromoLabelColor: "#111827",
  productPriceDiscountBackgroundColor: "#dc2626",
  productPriceDiscountLabelColor: "#ffffff",
  smartbarEnabled: true,
  smartbarDesktopEnabled: true,
  smartbarLabelColor: "#222222",
  smartbarBackgroundColor: "#ffffff",
  smartbarTopCategoryLayout: "icons_with_label",
  smartbarNestedCategoryLayout: "pills_with_icon_rounded",
  smartbarTopCategorySize: "medium",
  smartbarNestedCategorySize: "medium",
  smartbarLabelLineTruncate: 2,
  roundedEdges: "4px",
  columnGutter: "12px",
  rowGutter: "45px",
  mainLayout: /* Slider */1,
  subcategoryLayout: /* Slider */1,
  productLayout: "grid",
  tabsLayout: "top",
  pillsLayout: "top",
  productCardTitle: true,
  productCardPrice: true,
  productCardSalePrice: true,
  productCardAdditionalImagesSlider: true,
  productCardVariantColors: true,
  productCardTitleLineTruncate: 1,
  productCardShape: /* Square */6,
  productCardFitToContainer: false,
  productCardSize: "medium",
  productCardPlaceholderColor: "#F3F4F6",
  productCardGreyPlaceholder: false,
  productCardEnableAddToCart: false,
  categoryCardTitle: true,
  categoryCardTitleLineTruncate: 1,
  categoryCardShape: /* Square */6,
  categoryCardSize: "medium",
  categoryCardFitToContainer: false,
  categoryCardPlaceholderColor: "#F3F4F6",
  openProductLink: false,
  openLinkNewTab: false,
  openLinkLabel: "See All",
  checkoutLink: undefined,
  linksAsSidebar: false,
  sidebarMobileDefault: false,
  sidebarDesktopDefault: false,
  backButtonMobile: true,
  backButtonDesktop: true,
  pdpCtaButtons: defaults_pdpCtaButtons,
  pdpOpenLinkLabel: "View Details",
  pdpAddToCartLabel: "Add to Cart",
  pdpAddToCartConfirmation: "Added to cart!",
  pdpPrimaryCustomAction: undefined,
  pdpSecondaryCustomAction: undefined,
  pdpTertiaryCustomAction: undefined,
  pdpGalleryCustomAction: undefined,
  pdpCtaButtonBgColor: "#000000",
  pdpCtaButtonTextColor: "#ffffff",
  pdpGalleryBGBlur: true,
  pdpGalleryBGColor: "#ffffff",
  pdpGalleryPadding: "0px",
  pdpSizeGuide: undefined,
  pdpPriceSuffixText: undefined,
  pdpPricePrefixText: undefined,
  pdpPromoText: undefined,
  shipping: undefined,
  shippingShort: undefined,
  arrival: undefined,
  hideOutOfStock: true,
  outOfStockLabel: "Out of Stock",
  siteHeaderCSSSelector: "",
  siteHeaderOffsetDrawer: false,
  siteHeaderOffsetDrawerMobile: undefined,
  videoLoop: true,
  videoAutoPlay: true,
  videoMute: true,
  videoControls: false,
  zIndex: 1000002,
  fontFamily: undefined,
  primaryDataSource: /* ImportedProductFeed */1,
  currencyPickerEnabled: true,
  gridDensityZoomEnabled: false,
  filtersEnabled: false,
  filtersLabelOrder: defaults_filtersLabelOrder,
  indexNode: newrecord,
  displayVariantBy: /* FirstVariant */0,
  quickviewCarousel: true,
  quickviewAutoplay: true,
  quickviewPictureInPicture: true,
  newMobilePDP: false,
  newDesktopPDP: false,
  desktopPDPLayout: undefined,
  newMobilePDPCube: false,
  newMobilePDPCover: false,
  quantityPicker: false,
  mkrBio: undefined,
  cart: false,
  gaTrackingId: undefined,
  gaVersion: /* Gtag */1,
  contextualL1s: false,
  contextualInline: true,
  autoHideBottombar: false,
  expandVariantsInProductsListing: undefined,
  favoritesEnabled: false,
  historyEnabled: false,
  plpTitleDescriptionEnabled: false,
  plpTitleDescriptionPosition: /* Left */0,
  plpMediaSize: undefined,
  seoDynamicLinks: false
};

var empty_filtersLabelOrder = [];

var empty_pdpCTAConfig = {
  primary: {
    TAG: /* OpenLink */1,
    _0: "test"
  },
  secondary: undefined,
  tertiary: undefined
};

var empty_pdpCTAButtons = [
  "open_link",
  undefined,
  undefined
];

var empty = {
  panelSize: /* Small */2,
  panelPosition: /* Right */1,
  backdrop: true,
  backdropBlur: true,
  backdropColor: "",
  categoryLayout: /* Labels */0,
  categorySize: /* Small */0,
  nestedCategoryLayout: /* Labels */0,
  nestedCategorySize: /* Small */0,
  checkoutLink: undefined,
  currencyPickerEnabled: true,
  gridDensityZoomEnabled: true,
  filtersEnabled: true,
  filtersLabelOrder: empty_filtersLabelOrder,
  linksAsSidebar: true,
  sidebarDesktopDefault: true,
  sidebarMobileDefault: true,
  backButton: true,
  smartbarEnabled: true,
  zIndexInt: 0,
  zIndex: "",
  categoryCardShape: /* Square */1,
  categoryCardFitToContainer: true,
  categoryCardTitleLineTruncate: 0,
  productCardTitleLineTruncate: 0,
  productCardFitToContainer: true,
  productCardShape: /* Square */1,
  productCardSize: /* Md */4,
  productCardAddToCart: true,
  pdpCTAConfig: empty_pdpCTAConfig,
  pdpAddToCartLabel: "Add to cart",
  pdpAddToCartConfirmation: "",
  pdpPrimaryCustomAction: undefined,
  pdpSecondaryCustomAction: undefined,
  pdpTertiaryCustomAction: undefined,
  pdpGalleryCustomAction: undefined,
  pdpSizeGuide: undefined,
  pdpCTAButtons: empty_pdpCTAButtons,
  pdpOpenLinkLabel: "",
  pdpCTAButtonBgColor: "",
  pdpCTAButtonTextColor: "",
  pdpGalleryBGBlur: true,
  pdpGalleryBGColor: "",
  pdpPricePrefixText: undefined,
  pdpPriceSuffixText: undefined,
  pdpPromoText: undefined,
  shipping: undefined,
  shippingShort: undefined,
  arrival: undefined,
  hideOutOfStock: true,
  videoLoop: true,
  videoAutoPlay: true,
  videoMute: true,
  videoControls: true,
  openLinkLabel: "",
  openLinkInNewTab: true,
  openProductLink: true,
  outOfStockLabel: "",
  indexNode: undefined,
  quickviewCarousel: true,
  quickviewAutoplay: true,
  quickviewPictureInPicture: true,
  siteHeaderCSSSelector: undefined,
  siteHeaderRespect: false,
  displayVariantBy: /* FirstVariant */0,
  newMobilePDP: false,
  desktopPDPLayout: /* Old */0,
  newMobilePDPCube: false,
  newMobilePDPCover: false,
  quantityPicker: false,
  styling: undefined,
  mkrBio: undefined,
  cart: false,
  gaTrackingId: undefined,
  gaVersion: /* Gtag */1,
  contextualL1s: false,
  contextualInline: false,
  autoHideBottombar: false,
  expandVariantsInProductsListing: undefined,
  favoritesEnabled: false,
  historyEnabled: false,
  plpTitleDescriptionEnabled: false,
  plpTitleDescriptionPosition: /* Left */0,
  seoDynamicLinks: false
};

var Breakpoint2;

var emptyIndexNode = newrecord;

export {
  Breakpoint2 ,
  PanelPosition ,
  PanelSize ,
  PDPCTAConfig ,
  emptyNode ,
  emptyIndexNode ,
  defaults ,
  empty ,
}
/*  Not a pure module */
