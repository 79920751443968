// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Product$Showside from "../types/Product.bs.js";
import * as FacebookPixel$Showside from "./FacebookPixel.bs.js";

function itemToViewedItem(item) {
  return {
          Title: item.Name,
          ItemId: item.ProductID,
          Categories: item.Categories,
          ImageUrl: item.ImageURL,
          Url: item.URL,
          Metadata: {
            Brand: item.Brand,
            Price: item.Price,
            CompareAtPrice: item.CompareAtPrice
          }
        };
}

var getLearnq = (function getLearnq() {
    window._learnq = window._learnq || [];
    return window._learnq;
  });

function productToItem(product, variant, category) {
  var price = variant.price;
  var match;
  if (typeof price === "number") {
    match = [
      undefined,
      undefined
    ];
  } else if (price.TAG === /* Regular */0) {
    match = [
      price._0,
      undefined
    ];
  } else {
    var match$1 = price._0;
    match = [
      match$1.price,
      match$1.regularPrice
    ];
  }
  return {
          Name: FacebookPixel$Showside.getProductName(product, variant),
          ProductID: product.id,
          Categories: Belt_Option.map(category, (function (category) {
                  return category.title;
                })),
          ImageURL: Belt_Array.get(Product$Showside.getValidProductImages(product), 0),
          URL: Belt_Option.mapWithDefault(variant.link, product.link, (function (l) {
                  return l;
                })),
          Brand: variant.brand,
          Price: match[0],
          CompareAtPrice: match[1]
        };
}

function trackViewContent(product, variant, category, param) {
  var item = productToItem(product, variant, category);
  var viewedItem = itemToViewedItem(item);
  Curry._1(getLearnq, undefined).push([
        "track",
        "Viewed Product",
        item
      ]);
  Curry._1(getLearnq, undefined).push([
        "trackViewedItem",
        viewedItem
      ]);
}

function trackAddToCart(product, variant, category, quantity, param) {
  var item = productToItem(product, variant, category);
  Curry._1(getLearnq, undefined).push([
        "track",
        "Added to Cart",
        item
      ]);
}

export {
  itemToViewedItem ,
  getLearnq ,
  productToItem ,
  trackViewContent ,
  trackAddToCart ,
}
/* Product-Showside Not a pure module */
