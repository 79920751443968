// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ReactDOMStyle from "../../../../node_modules/@rescript/react/lib/es6_global/src/ReactDOMStyle.bs.js";
import * as LegacyShowside_Styling$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/LegacyShowside/LegacyShowside_Styling.bs.js";

function addMaybe(style, maybe) {
  return Belt_Option.mapWithDefault(maybe, style, (function (param) {
                return ReactDOMStyle.unsafeAddProp(style, param[0], param[1]);
              }));
}

function combineMaybe(style, maybe) {
  return Belt_Option.mapWithDefault(maybe, style, (function (toCombine) {
                return Object.assign({}, style, toCombine);
              }));
}

function Theme(Props) {
  var style = Props.style;
  var theme = Props.theme;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var style$1 = theme !== undefined ? Caml_option.some(combineMaybe(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(ReactDOMStyle.unsafeAddProp(addMaybe({}, Belt_Option.map(Belt_Option.flatMap(theme.fontFamily, LegacyShowside_Styling$ApiTypes.FontFamily.value), (function (fontFamily) {
                                                                                        return [
                                                                                                "--maker-font-family",
                                                                                                fontFamily
                                                                                              ];
                                                                                      }))), "--smartnav-color-primary", LegacyShowside_Styling$ApiTypes.Color.value(theme.primaryColor)), "--smartnav-color-secondary", LegacyShowside_Styling$ApiTypes.Color.value(theme.secondaryColor)), "--smartnav-color-tertiary", LegacyShowside_Styling$ApiTypes.Color.value(theme.tertiaryColor)), "--smartnav-color-accent", LegacyShowside_Styling$ApiTypes.Color.value(theme.accentColor)), "--smartnav-color-drawer-main", LegacyShowside_Styling$ApiTypes.Color.value(theme.drawerMainColor)), "--smartnav-color-drawer-label", LegacyShowside_Styling$ApiTypes.Color.value(theme.drawerLabelColor)), "--smartnav-color-background", LegacyShowside_Styling$ApiTypes.Color.value(theme.backgroundColor)), "--smartnav-border-radius", LegacyShowside_Styling$ApiTypes.Px.value(theme.roundedEdges)), "--smartnav-col-gutter", LegacyShowside_Styling$ApiTypes.Px.value(theme.columnGutter)), "--smartnav-row-gutter", LegacyShowside_Styling$ApiTypes.Px.value(theme.rowGutter)), "--smartnav-product-card-placeholder", LegacyShowside_Styling$ApiTypes.Color.value(theme.productCardPlaceholderColor)), "--smartnav-category-card-placeholder", LegacyShowside_Styling$ApiTypes.Color.value(theme.categoryCardPlaceholderColor)), "--smartnav-product-discount-main", LegacyShowside_Styling$ApiTypes.Color.value(theme.productPriceDiscountBackgroundColor)), "--smartnav-product-discount-label", LegacyShowside_Styling$ApiTypes.Color.value(theme.productPriceDiscountLabelColor)), "--smartnav-product-promo-main", LegacyShowside_Styling$ApiTypes.Color.value(theme.productPromoBackgroundColor)), "--smartnav-product-promo-label", LegacyShowside_Styling$ApiTypes.Color.value(theme.productPromoLabelColor)), style)) : style;
  var tmp = {
    className: className
  };
  if (style$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(style$1);
  }
  return React.createElement("div", tmp, children);
}

var FontFamily;

var Color;

var Px;

var make = Theme;

export {
  FontFamily ,
  Color ,
  Px ,
  addMaybe ,
  combineMaybe ,
  make ,
}
/* react Not a pure module */
