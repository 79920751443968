// Generated by ReScript, PLEASE EDIT WITH CARE


let findAMeaningfulMessageInArgs  = (args) => {
  for (let k in args) {
    if (typeof args[k] == "object" && args[k] instanceof Error) {
      return args[k].message
    }
    if (typeof args[k] == "string") {
      return args[k]
    }
  }
}

// RE_EXN_ID irritatingly has the variant index /1 on it
// this can only confuse error grouping and seems to make
// rollbar not treat it as a valid error.name
let clean_REX_ID  = (id) => {
  let split = id.split("/")
  if (split.length == 2) {
    return split[0]
  }
  return id
}
;

var fromString = (function(str) {
    return new Error(str)
  });

var fromStringWithCause = (function(str, cause) {
    return new Error(str, {cause: cause})
  });

var getRootCause = (function(err) {
    while (typeof err.cause == "object" && err.cause instanceof Error) {
      err = err.cause
    }
    return err
  });

var fromRescript = (function(err) {
      // It already is a js exn, not rescript exception, perhaps a type issue
      // could lead to this so we just want to keep it
      if (err instanceof Error) {
        return err
      }

      // Looks in every way like a real rescript exception
      if (err !== null && typeof err === "object" && typeof err.RE_EXN_ID == "string") {
        let args = [err._0, err._1, err._2, err._3, err._4, err._5, err._6].filter(arg => typeof arg !== "undefined")
        let newError =  new Error()
        newError.name = clean_REX_ID(err.RE_EXN_ID)
        newError.message = findAMeaningfulMessageInArgs(args)
        newError.RE_EXN_ID = err.RE_EXN_ID
        for (let i = 0; i < args.length; i++) {
          if (typeof args[i] !== "undefined") {
            newError["RE_EXN_ARG_"+i] = args[i]
          }
        }

        if (err.Error instanceof Error) {
          newError.stack = err.Error.stack
        }

        return newError
      }

      // Not clear how this could be possible but keeping because no harm
      // Probably never was possible and just exists for no reason
      if (err !== null && typeof err === "object" && err._1 instanceof Error) {
        return err._1
      }

      return new Error("Rescript (uninterpretable) " + JSON.stringify(err))
    });

function fromRescriptWithNewMessage(err, newMessage) {
  return fromStringWithCause(newMessage, fromRescript(err));
}

var $$Error = {
  fromString: fromString,
  fromStringWithCause: fromStringWithCause,
  getRootCause: getRootCause,
  fromRescript: fromRescript,
  fromRescriptWithNewMessage: fromRescriptWithNewMessage
};

function critical1(client, e) {
  return new Promise((function (resolve, reject) {
                client.critical(e, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function critical2(client, e, a) {
  return new Promise((function (resolve, reject) {
                client.critical(e, a, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function critical3(client, e, a, b) {
  return new Promise((function (resolve, reject) {
                client.critical(e, a, b, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function error1(client, e) {
  return new Promise((function (resolve, reject) {
                client.error(e, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function error2(client, e, a) {
  return new Promise((function (resolve, reject) {
                client.error(e, a, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function error3(client, e, a, b) {
  return new Promise((function (resolve, reject) {
                client.error(e, a, b, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function info1(client, msg) {
  return new Promise((function (resolve, reject) {
                client.info(msg, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function info2(client, msg, extraInfo) {
  return new Promise((function (resolve, reject) {
                client.info(msg, extraInfo, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function warning1(client, msg) {
  return new Promise((function (resolve, reject) {
                client.warning(msg, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

function warning2(client, msg, extraInfo) {
  return new Promise((function (resolve, reject) {
                client.warning(msg, extraInfo, (function (err, res) {
                        if (err == null) {
                          return resolve(undefined);
                        } else {
                          return reject(err);
                        }
                      }));
              }));
}

export {
  $$Error ,
  critical1 ,
  critical2 ,
  critical3 ,
  error1 ,
  error2 ,
  error3 ,
  info1 ,
  info2 ,
  warning1 ,
  warning2 ,
}
/*  Not a pure module */
