// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Generic$ApiTypes from "../utils/Generic.bs.js";

var fromJson = Generic$ApiTypes.Decode.simpleVariant([
      [
        "Default",
        /* Default */0
      ],
      [
        "Featured",
        /* Featured */1
      ],
      [
        "BestSellers",
        /* BestSellers */2
      ],
      [
        "Newest",
        /* Newest */3
      ],
      [
        "HighestPrice",
        /* HighestPrice */4
      ],
      [
        "LowestPrice",
        /* LowestPrice */5
      ]
    ]);

var all = [
  /* Default */0,
  /* Featured */1,
  /* BestSellers */2,
  /* Newest */3,
  /* HighestPrice */4,
  /* LowestPrice */5
];

function fromString(str) {
  switch (str) {
    case "best_sellers" :
        return /* BestSellers */2;
    case "default" :
        return /* Default */0;
    case "featured" :
        return /* Featured */1;
    case "highest_price" :
        return /* HighestPrice */4;
    case "lowest_price" :
        return /* LowestPrice */5;
    case "newest" :
        return /* Newest */3;
    default:
      return /* Default */0;
  }
}

var ProductSortOrder = {
  fromJson: fromJson,
  all: all,
  fromString: fromString
};

export {
  ProductSortOrder ,
}
/* fromJson Not a pure module */
