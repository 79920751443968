// Generated by ReScript, PLEASE EDIT WITH CARE

function errorToString(_error) {
  while (true) {
    var error = _error;
    switch (error.TAG | 0) {
      case /* Failure */0:
        return error._0;
      case /* Index */1:
      case /* Field */2:
        _error = error._1;
        continue;
      case /* OneOf */3:
        _error = error._0;
        continue;
    }
  }
  ;
}
export { errorToString };
/* No side effect */