// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Js_array from "../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json__Decode from "../../../../node_modules/rescript-json/lib/es6_global/src/Json__Decode.bs.js";
import * as Store$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/Store/Store.bs.js";
import * as Facets$Showside from "../types/Facets.bs.js";
import * as Generic$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/utils/Generic.bs.js";
import * as Location$Showside from "../utils/Location.bs.js";
import * as Nav_Location$Showside from "../Nav_Location.bs.js";
import * as CategoryFinder$Showside from "../types/CategoryFinder.bs.js";
import * as LocationConfig$Showside from "../utils/LocationConfig.bs.js";
import * as QuickView_Location$Showside from "../QuickView_Location.bs.js";
import * as Store_ProductSortOrderCodecs$ApiTypes from "../../../../node_modules/api-types/lib/es6_global/src/Store/Store_ProductSortOrderCodecs.bs.js";
var storeCategoryType_decode = Json__Decode.map2(Json__Decode.field("categoryId", Json__Decode.string), Json__Decode.field("sourceNodeId", Json__Decode.nullable(Json__Decode.string)), function (categoryId, sourceNodeId) {
  return {
    categoryId: categoryId,
    sourceNodeId: sourceNodeId
  };
});
function storeCategoryType_encode(storeCategoryType) {
  return Curry._1(Generic$ApiTypes.Encode.object, [["categoryId", Curry._1(Generic$ApiTypes.Encode.string, storeCategoryType.categoryId)], ["sourceNodeId", Generic$ApiTypes.Encode.nullable(storeCategoryType.sourceNodeId, Generic$ApiTypes.Encode.string)]]);
}
function homeType_encode(homeType) {
  if (typeof homeType !== "number") {
    return Generic$ApiTypes.Encode.deccoVariantWithPayload(homeType._0, "StoreCategory", storeCategoryType_encode);
  }
  switch (homeType) {
    case /* Index */0:
      return Generic$ApiTypes.Encode.simpleVariant([["Index", /* Index */0]], /* Index */0);
    case /* Favorites */1:
      return Generic$ApiTypes.Encode.simpleVariant([["Favorites", /* Favorites */1]], /* Favorites */1);
    case /* History */2:
      return Generic$ApiTypes.Encode.simpleVariant([["History", /* History */2]], /* History */2);
    case /* StoreCart */3:
      return Generic$ApiTypes.Encode.simpleVariant([["StoreCart", /* StoreCart */3]], /* StoreCart */3);
  }
}
var index = Generic$ApiTypes.Decode.simpleVariant([["Index", /* Index */0]]);
var favorites = Generic$ApiTypes.Decode.simpleVariant([["Favorites", /* Favorites */1]]);
var history = Generic$ApiTypes.Decode.simpleVariant([["History", /* History */2]]);
var storeCart = Generic$ApiTypes.Decode.simpleVariant([["StoreCart", /* StoreCart */3]]);
var storeCategory = Generic$ApiTypes.Decode.deccoVariantWithPayload("StoreCategory", storeCategoryType_decode, function (v) {
  return /* StoreCategory */{
    _0: v
  };
});
var homeType_decode = Curry._2(Generic$ApiTypes.Decode.oneOf, index, [favorites, history, storeCart, storeCategory]);
function home_encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [["homeType", homeType_encode(param.homeType)], ["filters", Generic$ApiTypes.Encode.nullable(param.filters, Facets$Showside.t_encode)], ["sort", Generic$ApiTypes.Encode.nullable(param.sort, Store$ApiTypes.ProductSortOrder.t_encode)]]);
}
var home_decode = Json__Decode.map3(Json__Decode.field("homeType", homeType_decode), Json__Decode.field("filters", Json__Decode.option(Facets$Showside.fromJson)), Json__Decode.field("sort", Json__Decode.option(Store_ProductSortOrderCodecs$ApiTypes.ProductSortOrder.fromJson)), function (homeType, filters, sort) {
  return {
    homeType: homeType,
    filters: filters,
    sort: sort
  };
});
function listPageType_encode(listPageType) {
  if (typeof listPageType === "number") {
    switch (listPageType) {
      case /* Favorites */0:
        return Generic$ApiTypes.Encode.simpleVariant([["Favorites", /* Favorites */0]], /* Favorites */0);
      case /* History */1:
        return Generic$ApiTypes.Encode.simpleVariant([["History", /* History */1]], /* History */1);
      case /* StoreCart */2:
        return Generic$ApiTypes.Encode.simpleVariant([["StoreCart", /* StoreCart */2]], /* StoreCart */2);
    }
  } else {
    switch (listPageType.TAG | 0) {
      case /* Category */0:
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(listPageType._0, "Category", Generic$ApiTypes.Encode.string);
      case /* Product */1:
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(listPageType._0, "Product", Generic$ApiTypes.Encode.string);
      case /* StoreCategory */2:
        return Generic$ApiTypes.Encode.deccoVariantWithPayload(listPageType._0, "StoreCategory", storeCategoryType_encode);
    }
  }
}
var category = Generic$ApiTypes.Decode.deccoVariantWithPayload("Category", Generic$ApiTypes.Decode.string, function (v) {
  return {
    TAG: /* Category */0,
    _0: v
  };
});
var product = Generic$ApiTypes.Decode.deccoVariantWithPayload("Product", Generic$ApiTypes.Decode.string, function (v) {
  return {
    TAG: /* Product */1,
    _0: v
  };
});
var favorites$1 = Generic$ApiTypes.Decode.simpleVariant([["Favorites", /* Favorites */0]]);
var history$1 = Generic$ApiTypes.Decode.simpleVariant([["History", /* History */1]]);
var storeCart$1 = Generic$ApiTypes.Decode.simpleVariant([["StoreCart", /* StoreCart */2]]);
var storeCategory$1 = Generic$ApiTypes.Decode.deccoVariantWithPayload("StoreCategory", storeCategoryType_decode, function (v) {
  return {
    TAG: /* StoreCategory */2,
    _0: v
  };
});
var listPageType_decode = Curry._2(Generic$ApiTypes.Decode.oneOf, category, [product, favorites$1, history$1, storeCart$1, storeCategory$1]);
function listPage_encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [["listPageType", listPageType_encode(param.listPageType)], ["viewPLP", Curry._1(Generic$ApiTypes.Encode.bool, param.viewPLP)], ["filters", Generic$ApiTypes.Encode.nullable(param.filters, Facets$Showside.t_encode)], ["sort", Generic$ApiTypes.Encode.nullable(param.sort, Store_ProductSortOrderCodecs$ApiTypes.ProductSortOrder.toJson)]]);
}
var listPage_decode = Json__Decode.map4(Json__Decode.field("listPageType", listPageType_decode), Json__Decode.field("viewPLP", Json__Decode.bool), Json__Decode.field("filters", Json__Decode.option(Facets$Showside.fromJson)), Json__Decode.field("sort", Json__Decode.option(Store_ProductSortOrderCodecs$ApiTypes.ProductSortOrder.fromJson)), function (listPageType, viewPLP, filters, sort) {
  return {
    listPageType: listPageType,
    viewPLP: viewPLP,
    filters: filters,
    sort: sort
  };
});
function pdp_encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [["categoryId", Generic$ApiTypes.Encode.nullable(param.categoryId, Generic$ApiTypes.Encode.string)], ["productCategoryId", Generic$ApiTypes.Encode.nullable(param.productCategoryId, Generic$ApiTypes.Encode.string)], ["productId", Curry._1(Generic$ApiTypes.Encode.string, param.productId)], ["variantId", Generic$ApiTypes.Encode.nullable(param.variantId, Generic$ApiTypes.Encode.string)], ["zoomedPhotoIndex", Generic$ApiTypes.Encode.nullable(param.zoomedPhotoIndex, Generic$ApiTypes.Encode.$$int)]]);
}
var pdp_decode = Json__Decode.map5(Json__Decode.field("categoryId", Json__Decode.option(Json__Decode.string)), Json__Decode.field("productCategoryId", Json__Decode.option(Json__Decode.string)), Json__Decode.field("productId", Json__Decode.string), Json__Decode.field("variantId", Json__Decode.option(Json__Decode.string)), Json__Decode.field("zoomedPhotoIndex", Json__Decode.option(Json__Decode.$$int)), function (categoryId, productCategoryId, productId, variantId, zoomedPhotoIndex) {
  return {
    categoryId: categoryId,
    productCategoryId: productCategoryId,
    productId: productId,
    variantId: variantId,
    zoomedPhotoIndex: zoomedPhotoIndex
  };
});
function quickView_encode(param) {
  return Curry._1(Generic$ApiTypes.Encode.object, [["mediaId", Curry._1(Generic$ApiTypes.Encode.string, param.mediaId)], ["mediaParentId", Generic$ApiTypes.Encode.nullable(param.mediaParentId, Generic$ApiTypes.Encode.string)], ["albumId", Generic$ApiTypes.Encode.nullable(param.albumId, Generic$ApiTypes.Encode.string)], ["productId", Generic$ApiTypes.Encode.nullable(param.productId, Generic$ApiTypes.Encode.string)], ["variantId", Generic$ApiTypes.Encode.nullable(param.variantId, Generic$ApiTypes.Encode.string)], ["panelClosed", Generic$ApiTypes.Encode.nullable(param.panelClosed, Generic$ApiTypes.Encode.bool)], ["zoomedPhotoIndex", Generic$ApiTypes.Encode.nullable(param.zoomedPhotoIndex, Generic$ApiTypes.Encode.$$int)], ["time", Generic$ApiTypes.Encode.nullable(param.time, Generic$ApiTypes.Encode.$$float)]]);
}
var quickView_decode = Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.andMap(Json__Decode.succeed(function (mediaId, mediaParentId, albumId, productId, relatedMediaProductIds, relatedMediaCategoryId, variantId, panelClosed, zoomedPhotoIndex, time) {
  return {
    mediaId: mediaId,
    mediaParentId: mediaParentId,
    albumId: albumId,
    productId: productId,
    relatedMediaProductIds: relatedMediaProductIds,
    relatedMediaCategoryId: relatedMediaCategoryId,
    variantId: variantId,
    panelClosed: panelClosed,
    zoomedPhotoIndex: zoomedPhotoIndex,
    time: time
  };
}), Json__Decode.field("mediaId", Json__Decode.string)), Json__Decode.field("mediaParentId", Json__Decode.option(Json__Decode.string))), Json__Decode.field("albumId", Json__Decode.option(Json__Decode.string))), Json__Decode.field("productId", Json__Decode.option(Json__Decode.string))), Json__Decode.field("relatedMediaProductIds", Json__Decode.option(Json__Decode.array(Json__Decode.string)))), Json__Decode.field("relatedMediaCategoryId", Json__Decode.option(Json__Decode.string))), Json__Decode.field("variantId", Json__Decode.option(Json__Decode.string))), Json__Decode.field("panelClosed", Json__Decode.option(Json__Decode.bool))), Json__Decode.field("zoomedPhotoIndex", Json__Decode.option(Json__Decode.$$int))), Json__Decode.field("time", Json__Decode.option(Json__Decode.$$float)));
function route_encode(route) {
  if (typeof route === "number") {
    return Curry._2(Generic$ApiTypes.Encode.array, ["NotRendered"], Generic$ApiTypes.Encode.string);
  }
  switch (route.TAG | 0) {
    case /* Home */0:
      return Generic$ApiTypes.Encode.deccoVariantWithPayload(route._0, "Home", home_encode);
    case /* ListPage */1:
      return Generic$ApiTypes.Encode.deccoVariantWithPayload(route._0, "ListPage", listPage_encode);
    case /* PDP */2:
      return Generic$ApiTypes.Encode.deccoVariantWithPayload(route._0, "PDP", pdp_encode);
    case /* QuickView */3:
      return Generic$ApiTypes.Encode.deccoVariantWithPayload(route._0, "QuickView", quickView_encode);
  }
}
var route_decode = Curry._2(Generic$ApiTypes.Decode.oneOf, Generic$ApiTypes.Decode.exactStringVariant("NotRendered", /* NotRendered */0), [Generic$ApiTypes.Decode.deccoVariantWithPayload("Home", home_decode, function (v) {
  return {
    TAG: /* Home */0,
    _0: v
  };
}), Generic$ApiTypes.Decode.deccoVariantWithPayload("ListPage", listPage_decode, function (v) {
  return {
    TAG: /* ListPage */1,
    _0: v
  };
}), Generic$ApiTypes.Decode.deccoVariantWithPayload("PDP", pdp_decode, function (v) {
  return {
    TAG: /* PDP */2,
    _0: v
  };
}), Generic$ApiTypes.Decode.deccoVariantWithPayload("QuickView", quickView_decode, function (v) {
  return {
    TAG: /* QuickView */3,
    _0: v
  };
})]);
function getListPageCategoryId(route) {
  if (typeof route === "number") {
    return;
  }
  if (route.TAG !== /* ListPage */1) {
    return;
  }
  var listPageType = route._0.listPageType;
  if (typeof listPageType === "number" || listPageType.TAG !== /* Category */0) {
    return;
  } else {
    return listPageType._0;
  }
}
function fromLocation($$location) {
  var match = $$location.quickNav;
  var match$1 = $$location.quickView;
  if (match !== undefined) {
    var match$2 = match.tabId;
    var match$3 = match.categoryId;
    var match$4 = match.productId;
    var match$5 = match.productCategoryId;
    var match$6 = match$1.mediaId;
    var match$7 = match.relatedProductsId;
    var match$8 = match.view;
    var view;
    if (match$6 !== undefined) {
      return {
        TAG: /* QuickView */3,
        _0: {
          mediaId: match$6,
          mediaParentId: match$1.mediaParentId,
          albumId: match$1.albumId,
          productId: match$1.productId,
          relatedMediaProductIds: match$1.relatedMediaProductIds,
          relatedMediaCategoryId: match$1.relatedMediaCategoryId,
          variantId: match$1.variantId,
          panelClosed: match$1.panelClosed,
          zoomedPhotoIndex: match$1.photoZoom,
          time: match$1.time
        }
      };
    }
    if (match$2 === undefined) {
      return /* NotRendered */0;
    }
    if (match$4 !== undefined) {
      return {
        TAG: /* PDP */2,
        _0: {
          categoryId: match.categoryId,
          productCategoryId: match.productCategoryId,
          productId: match$4,
          variantId: match.variantId,
          zoomedPhotoIndex: match.photoZoom
        }
      };
    }
    if (match$3 !== undefined) {
      if (match$5 !== undefined) {
        return /* NotRendered */0;
      }
      if (match$3 !== "") {
        var tmp;
        if (match$8 !== undefined) {
          if (typeof match$8 === "number") {
            switch (match$8) {
              case /* PLP */0:
                tmp = {
                  TAG: /* Category */0,
                  _0: match$3
                };
                break;
              case /* Favorites */1:
                tmp = /* Favorites */0;
                break;
              case /* History */2:
                tmp = /* History */1;
                break;
              case /* StoreCart */3:
                tmp = /* StoreCart */2;
                break;
            }
          } else {
            tmp = {
              TAG: /* StoreCategory */2,
              _0: match$8._0
            };
          }
        } else {
          tmp = {
            TAG: /* Category */0,
            _0: match$3
          };
        }
        return {
          TAG: /* ListPage */1,
          _0: {
            listPageType: tmp,
            viewPLP: Belt_Option.mapWithDefault(match$8, false, function (view) {
              return view === /* PLP */0;
            }),
            filters: match.filters,
            sort: match.sort
          }
        };
      }
      if (match$3 !== "") {
        return /* NotRendered */0;
      }
      view = match$8;
    } else {
      if (match$5 !== undefined) {
        return /* NotRendered */0;
      }
      if (match$7 !== undefined) {
        if (match$7 !== "") {
          var tmp$1;
          if (match$8 !== undefined) {
            if (typeof match$8 === "number") {
              switch (match$8) {
                case /* PLP */0:
                  tmp$1 = {
                    TAG: /* Product */1,
                    _0: match$7
                  };
                  break;
                case /* Favorites */1:
                  tmp$1 = /* Favorites */0;
                  break;
                case /* History */2:
                  tmp$1 = /* History */1;
                  break;
                case /* StoreCart */3:
                  tmp$1 = /* StoreCart */2;
                  break;
              }
            } else {
              tmp$1 = {
                TAG: /* StoreCategory */2,
                _0: match$8._0
              };
            }
          } else {
            tmp$1 = {
              TAG: /* Product */1,
              _0: match$7
            };
          }
          return {
            TAG: /* ListPage */1,
            _0: {
              listPageType: tmp$1,
              viewPLP: Belt_Option.mapWithDefault(match$8, false, function (view) {
                return view === /* PLP */0;
              }),
              filters: match.filters,
              sort: match.sort
            }
          };
        }
        view = match$8;
      } else {
        view = match$8;
      }
    }
    var tmp$2;
    if (view !== undefined) {
      if (typeof view === "number") {
        switch (view) {
          case /* PLP */0:
            tmp$2 = /* Index */0;
            break;
          case /* Favorites */1:
            tmp$2 = /* Favorites */1;
            break;
          case /* History */2:
            tmp$2 = /* History */2;
            break;
          case /* StoreCart */3:
            tmp$2 = /* StoreCart */3;
            break;
        }
      } else {
        tmp$2 = /* StoreCategory */{
          _0: view._0
        };
      }
    } else {
      tmp$2 = /* Index */0;
    }
    return {
      TAG: /* Home */0,
      _0: {
        homeType: tmp$2,
        filters: match.filters,
        sort: match.sort
      }
    };
  }
  var mediaId = match$1.mediaId;
  if (mediaId !== undefined) {
    return {
      TAG: /* QuickView */3,
      _0: {
        mediaId: mediaId,
        mediaParentId: match$1.mediaParentId,
        albumId: match$1.albumId,
        productId: match$1.productId,
        relatedMediaProductIds: match$1.relatedMediaProductIds,
        relatedMediaCategoryId: match$1.relatedMediaCategoryId,
        variantId: match$1.variantId,
        panelClosed: match$1.panelClosed,
        zoomedPhotoIndex: match$1.photoZoom,
        time: match$1.time
      }
    };
  } else {
    return /* NotRendered */0;
  }
}
function toString(route) {
  if (typeof route === "number") {
    return "NotRendered";
  }
  switch (route.TAG | 0) {
    case /* Home */0:
      var match = route._0;
      return Belt_Array.joinWith(["Home", Belt_Option.mapWithDefault(match.filters, "", Facets$Showside.toString), Belt_Option.mapWithDefault(match.sort, "", Store_ProductSortOrderCodecs$ApiTypes.ProductSortOrder.toString)], "|", function (a) {
        return a;
      });
    case /* ListPage */1:
      var match$1 = route._0;
      var listPageType = match$1.listPageType;
      var tmp;
      if (typeof listPageType === "number") {
        switch (listPageType) {
          case /* Favorites */0:
            tmp = "Favorites";
            break;
          case /* History */1:
            tmp = "History";
            break;
          case /* StoreCart */2:
            tmp = "StoreCart";
            break;
        }
      } else {
        switch (listPageType.TAG | 0) {
          case /* Category */0:
            tmp = "Category-" + listPageType._0;
            break;
          case /* Product */1:
            tmp = "Product-" + listPageType._0;
            break;
          case /* StoreCategory */2:
            var match$2 = listPageType._0;
            tmp = Belt_Array.keepMap(["StoreCategory", match$2.categoryId, match$2.sourceNodeId], function (a) {
              return a;
            }).join("-");
            break;
        }
      }
      return Belt_Array.joinWith(["ListPage", tmp, match$1.viewPLP ? "true" : "false", Belt_Option.mapWithDefault(match$1.filters, "", Facets$Showside.toString), Belt_Option.mapWithDefault(match$1.sort, "", Store_ProductSortOrderCodecs$ApiTypes.ProductSortOrder.toString)], "|", function (a) {
        return a;
      });
    case /* PDP */2:
      var match$3 = route._0;
      return Belt_Array.joinWith(["PDP", Belt_Option.getWithDefault(match$3.categoryId, ""), Belt_Option.getWithDefault(match$3.productCategoryId, ""), match$3.productId, Belt_Option.getWithDefault(match$3.variantId, ""), Belt_Option.mapWithDefault(match$3.zoomedPhotoIndex, "", function (prim) {
        return String(prim);
      })], "|", function (a) {
        return a;
      });
    case /* QuickView */3:
      var match$4 = route._0;
      return Belt_Array.joinWith(["QuickView", match$4.mediaId, Belt_Option.getWithDefault(match$4.albumId, ""), Belt_Option.getWithDefault(match$4.mediaParentId, ""), Belt_Option.getWithDefault(match$4.productId, ""), Belt_Option.getWithDefault(match$4.variantId, ""), Belt_Option.mapWithDefault(match$4.panelClosed, "", function (a) {
        if (a) {
          return "true";
        } else {
          return "false";
        }
      }), Belt_Option.mapWithDefault(match$4.zoomedPhotoIndex, "", function (prim) {
        return String(prim);
      }), Belt_Option.mapWithDefault(match$4.time, "", function (prim) {
        return String(prim);
      }), Belt_Option.getWithDefault(match$4.relatedMediaCategoryId, ""), Belt_Option.mapWithDefault(match$4.relatedMediaProductIds, "", function (param) {
        return Js_array.joinWith(",", param);
      })], "|", function (a) {
        return a;
      });
  }
}
var defaultRoutePerEntrypointRef = {
  contents: {
    inline: undefined,
    drawer: undefined,
    launcher: undefined,
    section: undefined,
    menu: undefined
  }
};
var context = React.createContext({
  route: /* NotRendered */0,
  quickViewUnderlyingRoute: undefined,
  back: function (param) {},
  goTo: function (replaceState, route) {},
  reset: function (param) {},
  isForcingPLP: false,
  isQuickView: false,
  listPageType: undefined,
  anyProductId: undefined,
  anyVariantId: undefined,
  relatedProductId: undefined,
  productCategoryId: undefined,
  productSort: undefined,
  productFilters: undefined,
  previewMode: undefined,
  devMode: undefined,
  raw: Location$Showside.empty
});
var provider = context.Provider;
function RouterProvider(Props) {
  var historyEnabledOpt = Props.historyEnabled;
  var configId = Props.configId;
  var defaultInitialRoute = Props.defaultInitialRoute;
  var embedType = Props.embedType;
  var children = Props.children;
  var historyEnabled = historyEnabledOpt !== undefined ? historyEnabledOpt : function (previewMode, route) {
    return true;
  };
  var match = LocationConfig$Showside.use(undefined);
  var namespace = match.namespace;
  var $$location = Location$Showside.use(undefined);
  var quickNav = $$location.quickNav;
  var quickView = $$location.quickView;
  var qnRelatedProductId = Belt_Option.flatMap(quickNav, function (qn) {
    return qn.relatedProductsId;
  });
  var qnView = Belt_Option.flatMap(quickNav, function (qn) {
    return Belt_Option.map(qn.view, function (view) {
      return view;
    });
  });
  if (defaultInitialRoute !== undefined) {
    switch (embedType) {
      case /* Bottombar */0:
        var init = defaultRoutePerEntrypointRef.contents;
        defaultRoutePerEntrypointRef.contents = {
          inline: init.inline,
          drawer: defaultInitialRoute,
          launcher: init.launcher,
          section: init.section,
          menu: init.menu
        };
        break;
      case /* Inline */1:
        var init$1 = defaultRoutePerEntrypointRef.contents;
        defaultRoutePerEntrypointRef.contents = {
          inline: defaultInitialRoute,
          drawer: init$1.drawer,
          launcher: init$1.launcher,
          section: init$1.section,
          menu: init$1.menu
        };
        break;
      case /* Launcher */2:
        var init$2 = defaultRoutePerEntrypointRef.contents;
        defaultRoutePerEntrypointRef.contents = {
          inline: init$2.inline,
          drawer: init$2.drawer,
          launcher: defaultInitialRoute,
          section: init$2.section,
          menu: init$2.menu
        };
        break;
      case /* Section */3:
        var init$3 = defaultRoutePerEntrypointRef.contents;
        defaultRoutePerEntrypointRef.contents = {
          inline: init$3.inline,
          drawer: init$3.drawer,
          launcher: init$3.launcher,
          section: defaultInitialRoute,
          menu: init$3.menu
        };
        break;
      case /* Menu */4:
        var init$4 = defaultRoutePerEntrypointRef.contents;
        defaultRoutePerEntrypointRef.contents = {
          inline: init$4.inline,
          drawer: init$4.drawer,
          launcher: init$4.launcher,
          section: init$4.section,
          menu: defaultInitialRoute
        };
        break;
    }
  }
  var value = React.useMemo(function () {
    var defaultRoute;
    switch (embedType) {
      case /* Bottombar */0:
        defaultRoute = defaultRoutePerEntrypointRef.contents.drawer;
        break;
      case /* Inline */1:
        defaultRoute = defaultRoutePerEntrypointRef.contents.inline;
        break;
      case /* Launcher */2:
        defaultRoute = defaultRoutePerEntrypointRef.contents.launcher;
        break;
      case /* Section */3:
        defaultRoute = defaultRoutePerEntrypointRef.contents.section;
        break;
      case /* Menu */4:
        defaultRoute = defaultRoutePerEntrypointRef.contents.menu;
        break;
    }
    var route = fromLocation({
      quickView: quickView,
      quickNav: quickNav
    });
    var route$1 = fromLocation({
      quickView: QuickView_Location$Showside.empty,
      quickNav: quickNav
    });
    var quickViewUnderlyingRoute = typeof route$1 === "number" ? defaultRoute !== undefined ? defaultRoute : /* NotRendered */0 : route$1;
    var route$2 = defaultRoute !== undefined && route === /* NotRendered */0 ? defaultRoute : route;
    var previewMode = Belt_Option.flatMap(quickNav, function (qn) {
      return qn.previewMode;
    });
    var devMode = Belt_Option.flatMap(quickNav, function (qn) {
      return qn.devMode;
    });
    var match;
    if (typeof route$2 === "number") {
      match = [undefined, undefined, undefined, undefined, undefined, undefined];
    } else {
      switch (route$2.TAG | 0) {
        case /* Home */0:
          var match$1 = route$2._0;
          var homeType = match$1.homeType;
          var tmp;
          if (typeof homeType === "number") {
            switch (homeType) {
              case /* Index */0:
                tmp = undefined;
                break;
              case /* Favorites */1:
                tmp = /* Favorites */0;
                break;
              case /* History */2:
                tmp = /* History */1;
                break;
              case /* StoreCart */3:
                tmp = /* StoreCart */2;
                break;
            }
          } else {
            tmp = {
              TAG: /* StoreCategory */2,
              _0: homeType._0
            };
          }
          match = [tmp, undefined, undefined, undefined, match$1.sort, match$1.filters];
          break;
        case /* ListPage */1:
          var match$2 = route$2._0;
          match = [match$2.listPageType, undefined, undefined, undefined, match$2.sort, match$2.filters];
          break;
        case /* PDP */2:
          var match$3 = route$2._0;
          var tmp$1;
          var exit = 0;
          if (qnView !== undefined) {
            if (typeof qnView === "number") {
              switch (qnView) {
                case /* PLP */0:
                  exit = 1;
                  break;
                case /* Favorites */1:
                  tmp$1 = /* Favorites */0;
                  break;
                case /* History */2:
                  tmp$1 = /* History */1;
                  break;
                case /* StoreCart */3:
                  tmp$1 = /* StoreCart */2;
                  break;
              }
            } else {
              tmp$1 = {
                TAG: /* StoreCategory */2,
                _0: qnView._0
              };
            }
          } else {
            exit = 1;
          }
          if (exit === 1) {
            tmp$1 = Belt_Option.map(match$3.categoryId, function (id) {
              return {
                TAG: /* Category */0,
                _0: id
              };
            });
          }
          match = [tmp$1, match$3.productId, match$3.variantId, match$3.productCategoryId, Belt_Option.flatMap(quickNav, function (qn) {
            return qn.sort;
          }), Belt_Option.flatMap(quickNav, function (qn) {
            return qn.filters;
          })];
          break;
        case /* QuickView */3:
          var match$4 = route$2._0;
          var mediaId = match$4.mediaId;
          var exit$1 = 0;
          if (quickViewUnderlyingRoute !== undefined && !(typeof quickViewUnderlyingRoute === "number" || quickViewUnderlyingRoute.TAG !== /* PDP */2)) {
            var match$5 = quickViewUnderlyingRoute._0;
            match = [{
              TAG: /* Category */0,
              _0: mediaId
            }, match$5.productId, match$5.variantId, undefined, undefined, undefined];
          } else {
            exit$1 = 1;
          }
          if (exit$1 === 1) {
            match = [{
              TAG: /* Category */0,
              _0: mediaId
            }, match$4.productId, match$4.variantId, undefined, undefined, undefined];
          }
          break;
      }
    }
    var productFilters = match[5];
    var productSort = match[4];
    var listPageType = match[0];
    var isQuickView;
    isQuickView = typeof route$2 === "number" || route$2.TAG !== /* QuickView */3 ? false : true;
    var reset = function (param) {
      Location$Showside.goTo(namespace, Curry._2(historyEnabled, previewMode, route$2), QuickView_Location$Showside.empty, {
        configId: previewMode !== undefined || devMode !== undefined ? configId : Nav_Location$Showside.empty.configId,
        tabId: Nav_Location$Showside.empty.tabId,
        categoryId: Nav_Location$Showside.empty.categoryId,
        productCategoryId: Nav_Location$Showside.empty.productCategoryId,
        productId: Nav_Location$Showside.empty.productId,
        relatedProductsId: Nav_Location$Showside.empty.relatedProductsId,
        variantId: Nav_Location$Showside.empty.variantId,
        photoZoom: Nav_Location$Showside.empty.photoZoom,
        previewMode: previewMode,
        devMode: devMode,
        filters: Nav_Location$Showside.empty.filters,
        sort: Nav_Location$Showside.empty.sort,
        view: Nav_Location$Showside.empty.view
      }, undefined, undefined, undefined);
    };
    var goTo = function (replaceStateOpt, route) {
      var replaceState = replaceStateOpt !== undefined ? replaceStateOpt : false;
      if (typeof route === "number") {
        return reset(undefined);
      }
      switch (route.TAG | 0) {
        case /* Home */0:
          var match = route._0;
          var homeType = match.homeType;
          var tmp;
          if (typeof homeType === "number") {
            switch (homeType) {
              case /* Index */0:
                tmp = undefined;
                break;
              case /* Favorites */1:
                tmp = /* Favorites */1;
                break;
              case /* History */2:
                tmp = /* History */2;
                break;
              case /* StoreCart */3:
                tmp = /* StoreCart */3;
                break;
            }
          } else {
            tmp = /* StoreCategory */{
              _0: homeType._0
            };
          }
          return Location$Showside.goTo(namespace, Curry._2(historyEnabled, previewMode, route), undefined, {
            configId: configId,
            tabId: "navigation",
            categoryId: undefined,
            productCategoryId: Nav_Location$Showside.empty.productCategoryId,
            productId: undefined,
            relatedProductsId: Nav_Location$Showside.empty.relatedProductsId,
            variantId: undefined,
            photoZoom: Nav_Location$Showside.empty.photoZoom,
            previewMode: previewMode,
            devMode: devMode,
            filters: match.filters,
            sort: match.sort,
            view: tmp
          }, replaceState, undefined, undefined);
        case /* ListPage */1:
          var match$1 = route._0;
          var viewPLP = match$1.viewPLP;
          var listPageType = match$1.listPageType;
          var init = Belt_Option.getWithDefault(quickNav, Nav_Location$Showside.empty);
          var tmp$1;
          var exit = 0;
          if (typeof listPageType === "number") {
            exit = 1;
          } else {
            switch (listPageType.TAG | 0) {
              case /* Category */0:
                tmp$1 = listPageType._0;
                break;
              case /* Product */1:
                tmp$1 = undefined;
                break;
              default:
                exit = 1;
            }
          }
          if (exit === 1) {
            tmp$1 = Belt_Option.flatMap(quickNav, function (qn) {
              return qn.categoryId;
            });
          }
          var tmp$2;
          tmp$2 = typeof listPageType === "number" || listPageType.TAG !== /* Product */1 ? undefined : listPageType._0;
          var tmp$3;
          if (typeof listPageType === "number") {
            switch (listPageType) {
              case /* Favorites */0:
                tmp$3 = /* Favorites */1;
                break;
              case /* History */1:
                tmp$3 = /* History */2;
                break;
              case /* StoreCart */2:
                tmp$3 = /* StoreCart */3;
                break;
            }
          } else {
            switch (listPageType.TAG | 0) {
              case /* Category */0:
              case /* Product */1:
                tmp$3 = viewPLP ? /* PLP */0 : undefined;
                break;
              case /* StoreCategory */2:
                tmp$3 = /* StoreCategory */{
                  _0: listPageType._0
                };
                break;
            }
          }
          return Location$Showside.goTo(namespace, Curry._2(historyEnabled, previewMode, route), undefined, {
            configId: configId,
            tabId: "navigation",
            categoryId: tmp$1,
            productCategoryId: undefined,
            productId: undefined,
            relatedProductsId: tmp$2,
            variantId: undefined,
            photoZoom: init.photoZoom,
            previewMode: previewMode,
            devMode: devMode,
            filters: match$1.filters,
            sort: match$1.sort,
            view: tmp$3
          }, replaceState, undefined, undefined);
        case /* PDP */2:
          var match$2 = route._0;
          var init$1 = Belt_Option.getWithDefault(quickNav, Nav_Location$Showside.empty);
          return Location$Showside.goTo(namespace, Curry._2(historyEnabled, previewMode, route), undefined, {
            configId: configId,
            tabId: "navigation",
            categoryId: match$2.categoryId,
            productCategoryId: match$2.productCategoryId,
            productId: match$2.productId,
            relatedProductsId: init$1.relatedProductsId,
            variantId: match$2.variantId,
            photoZoom: match$2.zoomedPhotoIndex,
            previewMode: previewMode,
            devMode: devMode,
            filters: init$1.filters,
            sort: init$1.sort,
            view: qnView
          }, replaceState, undefined, undefined);
        case /* QuickView */3:
          var match$3 = route._0;
          var init$2 = Belt_Option.getWithDefault(quickNav, Nav_Location$Showside.empty);
          var quickNavLocation_tabId = init$2.tabId;
          var quickNavLocation_categoryId = init$2.categoryId;
          var quickNavLocation_productCategoryId = init$2.productCategoryId;
          var quickNavLocation_productId = init$2.productId;
          var quickNavLocation_relatedProductsId = init$2.relatedProductsId;
          var quickNavLocation_variantId = init$2.variantId;
          var quickNavLocation_photoZoom = init$2.photoZoom;
          var quickNavLocation_filters = init$2.filters;
          var quickNavLocation_sort = init$2.sort;
          var quickNavLocation_view = init$2.view;
          var quickNavLocation = {
            configId: configId,
            tabId: quickNavLocation_tabId,
            categoryId: quickNavLocation_categoryId,
            productCategoryId: quickNavLocation_productCategoryId,
            productId: quickNavLocation_productId,
            relatedProductsId: quickNavLocation_relatedProductsId,
            variantId: quickNavLocation_variantId,
            photoZoom: quickNavLocation_photoZoom,
            previewMode: previewMode,
            devMode: devMode,
            filters: quickNavLocation_filters,
            sort: quickNavLocation_sort,
            view: quickNavLocation_view
          };
          var quickNavLocation$1;
          if (quickViewUnderlyingRoute !== undefined && typeof quickViewUnderlyingRoute !== "number") {
            switch (quickViewUnderlyingRoute.TAG | 0) {
              case /* ListPage */1:
                var listPageType$1 = quickViewUnderlyingRoute._0.listPageType;
                if (typeof listPageType$1 === "number") {
                  switch (listPageType$1) {
                    case /* Favorites */0:
                      quickNavLocation$1 = {
                        configId: configId,
                        tabId: quickNavLocation_tabId,
                        categoryId: quickNavLocation_categoryId,
                        productCategoryId: quickNavLocation_productCategoryId,
                        productId: quickNavLocation_productId,
                        relatedProductsId: quickNavLocation_relatedProductsId,
                        variantId: quickNavLocation_variantId,
                        photoZoom: quickNavLocation_photoZoom,
                        previewMode: previewMode,
                        devMode: devMode,
                        filters: quickNavLocation_filters,
                        sort: quickNavLocation_sort,
                        view: /* Favorites */1
                      };
                      break;
                    case /* History */1:
                      quickNavLocation$1 = {
                        configId: configId,
                        tabId: quickNavLocation_tabId,
                        categoryId: quickNavLocation_categoryId,
                        productCategoryId: quickNavLocation_productCategoryId,
                        productId: quickNavLocation_productId,
                        relatedProductsId: quickNavLocation_relatedProductsId,
                        variantId: quickNavLocation_variantId,
                        photoZoom: quickNavLocation_photoZoom,
                        previewMode: previewMode,
                        devMode: devMode,
                        filters: quickNavLocation_filters,
                        sort: quickNavLocation_sort,
                        view: /* History */2
                      };
                      break;
                    case /* StoreCart */2:
                      quickNavLocation$1 = {
                        configId: configId,
                        tabId: quickNavLocation_tabId,
                        categoryId: quickNavLocation_categoryId,
                        productCategoryId: quickNavLocation_productCategoryId,
                        productId: quickNavLocation_productId,
                        relatedProductsId: quickNavLocation_relatedProductsId,
                        variantId: quickNavLocation_variantId,
                        photoZoom: quickNavLocation_photoZoom,
                        previewMode: previewMode,
                        devMode: devMode,
                        filters: quickNavLocation_filters,
                        sort: quickNavLocation_sort,
                        view: /* StoreCart */3
                      };
                      break;
                  }
                } else {
                  switch (listPageType$1.TAG | 0) {
                    case /* Category */0:
                      quickNavLocation$1 = {
                        configId: configId,
                        tabId: quickNavLocation_tabId,
                        categoryId: listPageType$1._0,
                        productCategoryId: quickNavLocation_productCategoryId,
                        productId: quickNavLocation_productId,
                        relatedProductsId: quickNavLocation_relatedProductsId,
                        variantId: quickNavLocation_variantId,
                        photoZoom: quickNavLocation_photoZoom,
                        previewMode: previewMode,
                        devMode: devMode,
                        filters: quickNavLocation_filters,
                        sort: quickNavLocation_sort,
                        view: quickNavLocation_view
                      };
                      break;
                    case /* Product */1:
                      quickNavLocation$1 = {
                        configId: configId,
                        tabId: quickNavLocation_tabId,
                        categoryId: quickNavLocation_categoryId,
                        productCategoryId: quickNavLocation_productCategoryId,
                        productId: quickNavLocation_productId,
                        relatedProductsId: listPageType$1._0,
                        variantId: quickNavLocation_variantId,
                        photoZoom: quickNavLocation_photoZoom,
                        previewMode: previewMode,
                        devMode: devMode,
                        filters: quickNavLocation_filters,
                        sort: quickNavLocation_sort,
                        view: quickNavLocation_view
                      };
                      break;
                    case /* StoreCategory */2:
                      quickNavLocation$1 = {
                        configId: configId,
                        tabId: quickNavLocation_tabId,
                        categoryId: quickNavLocation_categoryId,
                        productCategoryId: quickNavLocation_productCategoryId,
                        productId: quickNavLocation_productId,
                        relatedProductsId: quickNavLocation_relatedProductsId,
                        variantId: quickNavLocation_variantId,
                        photoZoom: quickNavLocation_photoZoom,
                        previewMode: previewMode,
                        devMode: devMode,
                        filters: quickNavLocation_filters,
                        sort: quickNavLocation_sort,
                        view: /* StoreCategory */{
                          _0: listPageType$1._0
                        }
                      };
                      break;
                  }
                }
                break;
              case /* PDP */2:
                var match$4 = quickViewUnderlyingRoute._0;
                quickNavLocation$1 = {
                  configId: configId,
                  tabId: quickNavLocation_tabId,
                  categoryId: quickNavLocation_categoryId,
                  productCategoryId: match$4.productCategoryId,
                  productId: match$4.productId,
                  relatedProductsId: quickNavLocation_relatedProductsId,
                  variantId: match$4.variantId,
                  photoZoom: quickNavLocation_photoZoom,
                  previewMode: previewMode,
                  devMode: devMode,
                  filters: quickNavLocation_filters,
                  sort: quickNavLocation_sort,
                  view: quickNavLocation_view
                };
                break;
              default:
                quickNavLocation$1 = quickNavLocation;
            }
          } else {
            quickNavLocation$1 = quickNavLocation;
          }
          return Location$Showside.goTo(namespace, Curry._2(historyEnabled, previewMode, route), QuickView_Location$Showside.make(match$3.mediaId, match$3.mediaParentId, match$3.albumId, match$3.panelClosed, undefined, match$3.productId, match$3.relatedMediaProductIds, match$3.relatedMediaCategoryId, match$3.variantId, match$3.zoomedPhotoIndex, undefined, undefined), quickNavLocation$1, replaceState, undefined, undefined);
      }
    };
    var back = function (param) {
      if (typeof route$2 === "number") {
        return;
      }
      switch (route$2.TAG | 0) {
        case /* Home */0:
          var homeType = route$2._0.homeType;
          if (homeType === 0) {
            return reset(undefined);
          } else {
            return goTo(undefined, {
              TAG: /* Home */0,
              _0: {
                homeType: /* Index */0,
                filters: undefined,
                sort: undefined
              }
            });
          }
        case /* ListPage */1:
          var payload = route$2._0;
          var match = payload.listPageType;
          if (typeof match !== "number") {
            switch (match.TAG | 0) {
              case /* Category */0:
              case /* Product */1:
                return goTo(undefined, {
                  TAG: /* Home */0,
                  _0: {
                    homeType: /* Index */0,
                    filters: undefined,
                    sort: undefined
                  }
                });
              default:
            }
          }
          var categoryId = Belt_Option.flatMap(quickNav, function (qn) {
            return qn.categoryId;
          });
          if (categoryId !== undefined) {
            return goTo(undefined, {
              TAG: /* ListPage */1,
              _0: {
                listPageType: {
                  TAG: /* Category */0,
                  _0: categoryId
                },
                viewPLP: payload.viewPLP,
                filters: payload.filters,
                sort: payload.sort
              }
            });
          } else {
            return goTo(undefined, {
              TAG: /* Home */0,
              _0: {
                homeType: /* Index */0,
                filters: undefined,
                sort: undefined
              }
            });
          }
        case /* PDP */2:
          var data = route$2._0;
          if (data.zoomedPhotoIndex !== undefined) {
            return goTo(undefined, {
              TAG: /* PDP */2,
              _0: {
                categoryId: data.categoryId,
                productCategoryId: data.productCategoryId,
                productId: data.productId,
                variantId: data.variantId,
                zoomedPhotoIndex: undefined
              }
            });
          }
          var tmp;
          if (listPageType !== undefined) {
            tmp = listPageType;
          } else if (quickNav !== undefined) {
            var match$1 = quickNav.relatedProductsId;
            var match$2 = quickNav.categoryId;
            tmp = match$1 !== undefined ? {
              TAG: /* Product */1,
              _0: match$1
            } : match$2 !== undefined ? {
              TAG: /* Category */0,
              _0: match$2
            } : {
              TAG: /* Category */0,
              _0: ""
            };
          } else {
            tmp = {
              TAG: /* Category */0,
              _0: ""
            };
          }
          return goTo(undefined, {
            TAG: /* ListPage */1,
            _0: {
              listPageType: tmp,
              viewPLP: Caml_obj.equal(qnView, /* PLP */0),
              filters: productFilters,
              sort: productSort
            }
          });
        case /* QuickView */3:
          var payload$1 = route$2._0;
          if (payload$1.zoomedPhotoIndex !== undefined) {
            return goTo(undefined, {
              TAG: /* QuickView */3,
              _0: {
                mediaId: payload$1.mediaId,
                mediaParentId: payload$1.mediaParentId,
                albumId: payload$1.albumId,
                productId: payload$1.productId,
                relatedMediaProductIds: payload$1.relatedMediaProductIds,
                relatedMediaCategoryId: payload$1.relatedMediaCategoryId,
                variantId: payload$1.variantId,
                panelClosed: payload$1.panelClosed,
                zoomedPhotoIndex: undefined,
                time: payload$1.time
              }
            });
          } else if (payload$1.productId !== undefined) {
            return goTo(undefined, {
              TAG: /* QuickView */3,
              _0: {
                mediaId: payload$1.mediaId,
                mediaParentId: payload$1.mediaParentId,
                albumId: payload$1.albumId,
                productId: undefined,
                relatedMediaProductIds: payload$1.relatedMediaProductIds,
                relatedMediaCategoryId: payload$1.relatedMediaCategoryId,
                variantId: payload$1.variantId,
                panelClosed: payload$1.panelClosed,
                zoomedPhotoIndex: payload$1.zoomedPhotoIndex,
                time: payload$1.time
              }
            });
          } else if (quickViewUnderlyingRoute !== undefined) {
            return goTo(undefined, quickViewUnderlyingRoute);
          } else {
            return;
          }
      }
    };
    return {
      route: route$2,
      quickViewUnderlyingRoute: quickViewUnderlyingRoute,
      back: back,
      goTo: goTo,
      reset: reset,
      isForcingPLP: Caml_obj.equal(qnView, /* PLP */0),
      isQuickView: isQuickView,
      listPageType: listPageType,
      anyProductId: match[1],
      anyVariantId: match[2],
      relatedProductId: qnRelatedProductId,
      productCategoryId: match[3],
      productSort: productSort,
      productFilters: productFilters,
      previewMode: previewMode,
      devMode: devMode,
      raw: $$location
    };
  }, [Belt_Option.mapWithDefault(quickNav, "", Nav_Location$Showside.toString), QuickView_Location$Showside.toString(quickView), configId, embedType, namespace]);
  return React.createElement(provider, {
    value: value,
    children: children
  });
}
RouterProvider.displayName = "RouterProvider";
function __resetRoute(param) {
  defaultRoutePerEntrypointRef.contents = {
    inline: undefined,
    drawer: undefined,
    launcher: undefined,
    section: undefined,
    menu: undefined
  };
}
function useRouter(param) {
  return React.useContext(context);
}
function useActiveCategory(includeQuickViewOpt, includePDPOpt, categories) {
  var includeQuickView = includeQuickViewOpt !== undefined ? includeQuickViewOpt : false;
  var includePDP = includePDPOpt !== undefined ? includePDPOpt : true;
  var match = React.useContext(context);
  var listPageType = match.listPageType;
  var quickViewUnderlyingRoute = match.quickViewUnderlyingRoute;
  var route = match.route;
  var exit = 0;
  var listPageType$1;
  var homeType;
  var categoryId;
  var productCategoryId;
  var exit$1 = 0;
  var exit$2 = 0;
  var exit$3 = 0;
  var exit$4 = 0;
  var exit$5 = 0;
  var exit$6 = 0;
  if (typeof route === "number" || route.TAG !== /* QuickView */3) {
    exit$6 = 9;
  } else {
    if (includeQuickView) {
      return CategoryFinder$Showside.getCategoryById(categories, route._0.mediaId);
    }
    exit$6 = 9;
  }
  if (exit$6 === 9) {
    if (quickViewUnderlyingRoute !== undefined) {
      if (typeof quickViewUnderlyingRoute === "number") {
        if (listPageType === undefined) {
          return;
        }
        if (typeof listPageType === "number") {
          return;
        }
        switch (listPageType.TAG | 0) {
          case /* Category */0:
            return CategoryFinder$Showside.getCategoryById(categories, listPageType._0);
          case /* StoreCategory */2:
            var categoryId$1 = listPageType._0.sourceNodeId;
            if (categoryId$1 !== undefined) {
              return CategoryFinder$Showside.getCategoryById(categories, categoryId$1);
            } else {
              return;
            }
          default:
            return;
        }
      } else if (quickViewUnderlyingRoute.TAG === /* ListPage */1) {
        listPageType$1 = quickViewUnderlyingRoute._0.listPageType;
        exit = 1;
      } else {
        exit$5 = 8;
      }
    } else {
      exit$5 = 8;
    }
  }
  if (exit$5 === 8) {
    if (typeof route === "number" || route.TAG !== /* ListPage */1) {
      exit$4 = 7;
    } else {
      listPageType$1 = route._0.listPageType;
      exit = 1;
    }
  }
  if (exit$4 === 7) {
    if (quickViewUnderlyingRoute !== undefined) {
      if (typeof quickViewUnderlyingRoute !== "number") {
        switch (quickViewUnderlyingRoute.TAG | 0) {
          case /* Home */0:
            homeType = quickViewUnderlyingRoute._0.homeType;
            exit = 2;
            break;
          case /* PDP */2:
          case /* QuickView */3:
            exit$3 = 6;
            break;
        }
      }
    } else {
      exit$3 = 6;
    }
  }
  if (exit$3 === 6) {
    if (typeof route === "number" || route.TAG !== /* Home */0) {
      exit$2 = 5;
    } else {
      homeType = route._0.homeType;
      exit = 2;
    }
  }
  if (exit$2 === 5) {
    if (quickViewUnderlyingRoute !== undefined) {
      if (typeof quickViewUnderlyingRoute !== "number") {
        if (quickViewUnderlyingRoute.TAG === /* PDP */2) {
          var match$1 = quickViewUnderlyingRoute._0;
          categoryId = match$1.categoryId;
          productCategoryId = match$1.productCategoryId;
          exit = 3;
        } else {
          exit$1 = 4;
        }
      }
    } else {
      exit$1 = 4;
    }
  }
  if (exit$1 === 4) {
    if (typeof route === "number") {
      return;
    }
    if (route.TAG !== /* PDP */2) {
      return;
    }
    var match$2 = route._0;
    categoryId = match$2.categoryId;
    productCategoryId = match$2.productCategoryId;
    exit = 3;
  }
  switch (exit) {
    case 1:
      if (typeof listPageType$1 === "number") {
        return;
      }
      switch (listPageType$1.TAG | 0) {
        case /* Category */0:
          return CategoryFinder$Showside.getCategoryById(categories, listPageType$1._0);
        case /* StoreCategory */2:
          var categoryId$2 = listPageType$1._0.sourceNodeId;
          if (categoryId$2 !== undefined) {
            return CategoryFinder$Showside.getCategoryById(categories, categoryId$2);
          } else {
            return;
          }
        default:
          return;
      }
    case 2:
      if (typeof homeType === "number") {
        return;
      }
      var categoryId$3 = homeType._0.sourceNodeId;
      if (categoryId$3 !== undefined) {
        return CategoryFinder$Showside.getCategoryById(categories, categoryId$3);
      } else {
        return;
      }
    case 3:
      var categoryId$4 = includePDP ? productCategoryId : categoryId;
      return Belt_Option.flatMap(categoryId$4, function (id) {
        return CategoryFinder$Showside.getCategoryById(categories, id);
      });
  }
}
function isPreview(routerData) {
  return Caml_obj.equal(routerData.previewMode, /* PreviewHead */0);
}
var make = RouterProvider;
export { storeCategoryType_decode, storeCategoryType_encode, homeType_encode, homeType_decode, home_encode, home_decode, listPageType_encode, listPageType_decode, listPage_encode, listPage_decode, pdp_encode, pdp_decode, quickView_encode, quickView_decode, route_encode, route_decode, getListPageCategoryId, fromLocation, toString, defaultRoutePerEntrypointRef, context, provider, make, __resetRoute, useRouter, useActiveCategory, isPreview };
/* storeCategoryType_decode Not a pure module */