// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "../../../node_modules/@ryyppy/rescript-promise/lib/es6_global/src/Promise.bs.js";
import * as UseEnv$Showside from "./hook/UseEnv.bs.js";
import * as Shopify$Showside from "./utils/Shopify.bs.js";

function EntrypointConfigIdProvider(Props) {
  var configId = Props.configId;
  var render = Props.render;
  var match = React.useState(function () {
        return configId;
      });
  var setCurrentConfigId = match[1];
  var currentConfigId = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setCurrentAccountId = match$1[1];
  var match$2 = UseEnv$Showside.use(undefined);
  var smartnavApiHost = match$2.smartnavApiHost;
  React.useEffect((function () {
          if (configId !== undefined) {
            
          } else {
            $$Promise.$$catch(Shopify$Showside.Config.load(smartnavApiHost).then(function (config) {
                      Curry._1(setCurrentConfigId, (function (param) {
                              return config.id;
                            }));
                      Curry._1(setCurrentAccountId, (function (param) {
                              return config.accountId;
                            }));
                    }), (function (param) {
                    console.error("[Smart Nav] Failed to load Smart Nav config");
                    return Promise.resolve(undefined);
                  }));
          }
        }), [
        configId,
        smartnavApiHost
      ]);
  if (currentConfigId !== undefined) {
    return Curry._2(render, currentConfigId, match$1[0]);
  } else {
    return null;
  }
}

var make = EntrypointConfigIdProvider;

export {
  make ,
}
/* react Not a pure module */
