// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Fingerprintjs from "@fingerprintjs/fingerprintjs";
function getVisitorId(param) {
  return Fingerprintjs.load({
    monitoring: false
  }).then(function (prim) {
    return prim.get();
  }).then(function (result) {
    return result.visitorId;
  });
}
export { getVisitorId };
/* @fingerprintjs/fingerprintjs Not a pure module */