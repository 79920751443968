// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function use(optionsOpt, ref, callback) {
  var options = optionsOpt !== undefined ? optionsOpt : ({
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true
      });
  var observerRef = React.useRef(undefined);
  React.useEffect((function () {
          var match = ref.current;
          if (!(match == null)) {
            var match$1 = observerRef.current;
            if (match$1 === undefined) {
              var observer = new MutationObserver(callback);
              observer.observe(ref.current, options);
              observerRef.current = observer;
            }
            
          }
          
        }), [
        ref,
        callback,
        options
      ]);
  React.useEffect((function () {
          return (function (param) {
                    var observer = observerRef.current;
                    if (observer !== undefined) {
                      return observer.disconnect();
                    }
                    
                  });
        }), []);
}

export {
  use ,
}
/* react Not a pure module */
